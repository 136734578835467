import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'reactstrap';
import {required} from '../../../../../utils/general'



// const required = value => value ? undefined : 'This field is required.';


const renderField = ({ input, label, type, placeholder, className, disabled, meta: { touched, error, warning } }) => (
    <div className="form-group row">
        <label  className="col-sm-3 col-form-label">{label}</label>
        <div className="col-sm-9">
            <input className={className} {...input} placeholder={placeholder} type={type} disabled={disabled}/>
            {touched && ((error && <div className="invalid-feedback-error pl12">{error}</div>) || (warning && <div className="invalid-feedback-error pl12">{warning}</div>))}
        </div>
    </div>
)

const NotePadModalBody = (props) => {
  const {  handleSubmit , toggle} = props;
  return (
    <form onSubmit={handleSubmit}>
            <Field
                className="form-control"
                name="notes"
                id="notes"
                component={renderField}
                type="text"
                placeholder="Notes"
                label="Notes:"
                validate={[ required ]}
            />
            <Field
                className="form-control"
                name="txtExtraCharges"
                id="txtExtraCharges"
                component={renderField}
                type="number"
                placeholder="extra charge"
                label="extra charge:"
            />
            <div className="text-right">
                <Button type="submit" color="warning" className="btnNotPadModalButton" 
                onClick={handleSubmit(values => 
                    props.onSubmit({ 
                    ...values,
                    }))}
                >Done</Button>
                <Button color="warning" className="btnNotPadModalButton" onClick={toggle}>Cancel</Button>
            </div>
            
      
    </form>
  )
}

export default reduxForm({
  form: 'NotePadModalBodyForm',  // a unique identifier for this form
  /* initialValues: {
    'drpCity': 'Windsor Ontario',
  }, */
  destroyOnUnmount: true,
  // destroyOnUnmount: false,
  // validate,                // <--- validation function given to redux-form
// })(NotePadModalBody)
})((NotePadModalBody))
// })(injectStripe(NotePadModalBody))
//   warn                     // <--- warning function given to redux-form