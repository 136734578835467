import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../../../utils/LoadingSpinner';

class MenuItem extends Component {

    state = {
        loading: false,
    }


    render() {
        if(this.state.loading){
            return (
                <LoadingSpinner />
            )
        }
        const props = this.props;
        return (
            <button className={`btn btn-danger w-100 py-2 mb-3 d-inline-flex align-items-center ${this.props.selectedMenuCategoryItemId ===  props._id ? 'btnActiveMenuCategoryItem' : ''} `} onClick={()=>this.props.handleOnClickMenuCategoryItem(props)}>
                {props.name}
            </button>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps,{})(MenuItem);