import {
    FETCH_MENU_ITEM_BY_ID_WITH_INGREDIENTS,
    CLEAR_MENU_ITEM_INGREDIENTS
} from '../actions/types';


export default function(state={menuItemWithIngredient:{}},action){
    switch(action.type){
        case FETCH_MENU_ITEM_BY_ID_WITH_INGREDIENTS:
            /* console.log('action.payload')
            console.log(action.payload) */
            return {
                    ...state,
                    menuItemWithIngredient: action.payload.menuItem,
                    // total_menu_items: action.payload.total_menu_items
                }
        case CLEAR_MENU_ITEM_INGREDIENTS:
            return {
                    ...state,
                    menuItemWithIngredient: action.payload,
                }
        default:
            return  state;
    }
}