import {
  FETCH_MENU_CATEGORIES,
  GET_MENU_ITEMS_BY_CATEGORY_ID,
  CLEAR_MENU_CATEGORY_MENU_ITEMS,
} from '../actions/types';

export default function(state = {MenuCategories:[],MenuItems:[],total_menu_items:0}, action) {
  switch (action.type) {
    case FETCH_MENU_CATEGORIES:
      return {
        ...state,
        MenuCategories:action.payload.MenuCategories
      };
    case GET_MENU_ITEMS_BY_CATEGORY_ID:
      // console.log('action.payload.total_menu_items')
      // console.log(action.payload.total_menu_items)
      return {
              ...state,
              MenuItems: action.payload.MenuItems,
              total_menu_items: action.payload.total_menu_items
          }
    case CLEAR_MENU_CATEGORY_MENU_ITEMS:
      // console.log('action.payload.total_menu_items')
      // console.log(action.payload.total_menu_items)
      return {
              ...state,
              MenuItems: action.payload,
              total_menu_items: 0
          }
    default:
      return state;
  }
}
