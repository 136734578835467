import React,{Fragment} from 'react';
import LoadingSpinner from '../../../../../utils/LoadingSpinner';
import LoadMoreWebOrderItems from './LoadMoreWebOrderItems';
const WebOrdersNotificationModalBody = (props)  => {
    const order = props.order;
    /* console.log('order.total_existing_orders')
    console.log('order.total_existing_orders')
    console.log('order.total_existing_orders')
    console.log(order.total_existing_orders) */
    if (Object.keys(order).length === 0 || order.webExistingOrders === undefined) {
        return (
            <LoadingSpinner subClass={'mt10 mb10 text-center'} />
        )
    }else{

    }
    return (
        <Fragment>
            <div className="row">
                <div className="table-responsive">   
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Order Type</th>
                                <th>Name</th>
                                <th>Time</th>
                                <th>Total Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                order.webExistingOrders !== 'undefined' && order.webExistingOrders !== undefined ?
                                    <LoadMoreWebOrderItems
                                        limit={props.limitExstingOrders}
                                        total_web_existing_orders={order.total_web_existing_orders}
                                        Orders={order.webExistingOrders}
                                        onClickHandleLoadMoreWebExistingOrderItems={props.onClickHandleLoadMoreWebExistingOrderItems}
                                        handelOnClickWebExistingOrder={props.handelOnClickWebExistingOrder}
                                    />
                                :null
                            }
                        </tbody>
                    </table>

                    {
                        props.isloadingExistingOrders ? <LoadingSpinner subClass={'mt10 mb10 text-center'} /> :null
                    }
                </div>
            </div>
        </Fragment>
    );
}

export default WebOrdersNotificationModalBody;