import React,{Fragment} from 'react';
import OrderItemMistake from './OrderItemMistake';

const OrderItemBlockMistake = (props) => {

    const renderOrders = () => (
        props.list ? 
            props.list.map(card=>(
                <OrderItemMistake
                    key={card._id}
                    handelOnClickMistakeExistingOrder={props.handelOnClickMistakeExistingOrder}
                    {...card}
                />
            ))
        :null
    )

    return (
        <Fragment>
            {props.list ?
                props.list.length === 0 ?
                    <tr className="no_result text-center">
                       <td colSpan="4"> You have not placed any orders yet.</td>
                    </tr>
                :null
            :null}
            { renderOrders(props.list)}

        </Fragment>
    );
};

export default OrderItemBlockMistake;