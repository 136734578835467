import React,{Fragment} from 'react';
import ExistingTabletOrderItemBlock from './ExistingTabletOrderItemBlock'; 

const LoadMoreExistingTabletOrdersNotificationModalItems = (props) => {
    return (
        <Fragment>
            <ExistingTabletOrderItemBlock
                list={props.Orders}
                handelOnClickTabletNewOrder={props.handelOnClickTabletNewOrder}
            />
            {
                props.total_new_tablet_notification_orders > 0 && props.total_new_tablet_notification_orders > props.Orders.length ?
                    <tr className="load_more_container text-center">
                        <td colSpan="4">
                            <div className="cursor-pointer"  onClick={()=> props.onClickHandleLoadMoreExistingTabletNotificationOrderItems()}><i className="fas fa-angle-down show-more"></i></div>
                        </td>
                        {/* <span onClick={()=> props.loadMore()}>
                            Load More
                        </span> */}
                    </tr>
                :null
            }
            

    
        </Fragment>
    );
};

export default LoadMoreExistingTabletOrdersNotificationModalItems;