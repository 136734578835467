import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DiscountModalBody from './DiscountModalBody';
import { showMsg } from '../../../../../utils/general';
class DiscountModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            discount_percentage: '',
            discount_amount:'',
            // modal: props.showDiscountModal

        };
    
        this.toggle = this.toggle.bind(this);
        // this.applyDiscount =this.applyDiscount.bind(this);
        // this.handleChangeDiscountPercentage = this.handleChangeDiscountPercentage.bind(this);
    }

    componentDidMount() {
        this.changeDiscountValuesUsingProps();    
    }
    
    onOpened = () => {
        console.log('onOpened')
        this.changeDiscountValuesUsingProps();
    }
    changeDiscountValuesUsingProps = () =>{
        console.log('this.props.discount_percentage')
        console.log(parseFloat(this.props.discount_percentage))
        console.log('this.props.discount_amount_entered')
        console.log(parseFloat(this.props.discount_amount_entered))
        if(parseFloat(this.props.discount_percentage) !== 0 && this.props.discount_percentage !== ''){
            this.setState({discount_percentage:this.props.discount_percentage});
        }
        if(parseFloat(this.props.discount_amount_entered) !== 0 && this.props.discount_amount_entered !== ''){
            this.setState({discount_amount: this.props.discount_amount_entered});
        }
    }
    applyDiscount = () => {
        console.log('this.props.total')
        console.log(this.props.total)
        if(this.props.total < 0 ){
            alert('Your total is in negative amount so we can not apply discount on it.');
        }else{
            if(this.state.discount_percentage !== '' && this.state.discount_amount !== ''){
                showMsg("You can only enter discount percentage or discount amount not both of them.",'error');
                return false;

            }
            if(this.state.discount_percentage !== '' || this.state.discount_amount !== ''){
                // console.log('this.state.discount_percentage')
                // console.log(this.state.discount_percentage)
                // console.log('this.state.discount_amount')
                // console.log(this.state.discount_amount)
                this.props.handleOnChangeDiscountModalToggle(!this.props.showDiscountModal,true,this.state.discount_percentage,this.state.discount_amount)
                this.setState({discount_percentage:'',discount_amount:''})
            }else{
                alert('Please add some discount value.')
            }
        }
        
    }
    // handleChangeDiscountPercentage(event) {
    handleChangeDiscountPercentage = (event) => {
        this.setState({discount_percentage: event.target.value});
    }
    handleChangeDiscountAmount = (event) => {
        this.setState({discount_amount: event.target.value});
    }
    
    toggle() {
        /* this.setState({
            modal: !this.props.showDiscountModal
            // modal: !this.state.modal
        }); */
        this.props.handleOnChangeDiscountModalToggle(!this.props.showDiscountModal,false,false)
        /* this.setState(prevState => ({
            modal: !prevState.modal
        })); */
    }
    
    render() {
        
        // console.log('this.props.showDiscountModal in modal')
        // console.log(this.props.showDiscountModal)
        
        return (
                <Modal isOpen={this.props.showDiscountModal} onOpened={this.onOpened} toggle={this.toggle} className={this.props.className} size="lg">
                {/* <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="lg"> */}
                    <ModalHeader toggle={this.toggle}>Enter discount</ModalHeader>
                    <ModalBody>
                        <DiscountModalBody 
                            handleChangeDiscountPercentage={this.handleChangeDiscountPercentage}
                            discount_percentage={this.state.discount_percentage}
                            handleChangeDiscountAmount={this.handleChangeDiscountAmount}
                            discount_amount={this.state.discount_amount}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.applyDiscount}>Done</Button>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
        );
    }
};

export default DiscountModal;