import React,{Fragment} from 'react';
import WebOrderItem from './WebOrderItem';

const WebOrderItemBlock = (props) => {

    const renderOrders = () => (
        props.list ? 
            props.list.map(card=>(
                <WebOrderItem
                    key={card._id}
                    handelOnClickWebExistingOrder={props.handelOnClickWebExistingOrder}
                    {...card}
                />
            ))
        :null
    )

    return (
        <Fragment>
            {props.list ?
                props.list.length === 0 ?
                    <tr className="no_result text-center">
                       <td colSpan="4"> No orders have been placed. </td>
                    </tr>
                :null
            :null}
            { renderOrders(props.list)}

        </Fragment>
    );
};

export default WebOrderItemBlock;