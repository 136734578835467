import React,{Fragment} from 'react';
// import logoPng from '../../../../common/images/logo.png';
import trashRedPng from '../../../../common/images/icons/trash-red-large.png';
import RenderSelectedDeliveryType from './components/RenderSelectedDeliveryType';
import CartItemBlock from './components/CartItemBlock';
import {formatTodayDate, paidReceiptTxt, formatExistingOrderDateTimeOnly} from '../../../../utils/general';
import KeyPad from './components/KeyPad';
import { S3_URL } from '../../../../utils/misc';
import { calculateTotalIncludingFee, calculateFinalTotalOfOrderWithOutObjOrder } from '../../../../utils/helpers/OrdersPages/OrdersHelpers';
const FoodCart = (props) => {

    const todayDateTime = new Date();
  
    return (
        // <!-- food-cart-box -->
        <div className="food-cart-box">

            {/* <!-- food-cart-head --> */}
            <div className="food-cart-head bg-white mb-3">
                <div className="row restaurantLogo">
                    <div className={`${props.selectedOnReceiptItemType !== '' ? 'col-9' : 'col-12'} food-cart-logo text-center mb-2`}>
                        <img src={`${S3_URL}${props.headerLogo}`} alt="logo" className="mw-100" />
                        {/* <img src={logoPng} alt="logo" className="mw-100" /> */}
                    </div>
                    {
                        props.selectedOnReceiptItemType !== '' ?
                            <Fragment>
                                <div onClick={()=> props.handleOnClickFoodCartRemoveIcon()} className="col-3 food-cart-logo text-center mb-2 rowNoPad" >
                                    <button className={`btn btn-light-warning btnTrashCart text-white p-2 w-100 h-100 mb-3 d-flex align-items-center justify-content-center `} >
                                        <div>
                                            <p>
                                                <img src={trashRedPng}  alt="icons" className="mw-100" />
                                            </p>
                                        </div>
                                    </button>
                                </div>
                            </Fragment>
                            :null
                    }
                </div>
                <div className="food-info-head">
                    <div className="row">
                        <div className="col-7">
                            <RenderSelectedDeliveryType 
                                selectedOrderType={props.selectedOrderType}
                                selectedExistingOrder={props.selectedExistingOrder}
                                existing_order_id={props.existing_order_id}
                                objCustomer={props.objCustomer}
                            />
                            {
                                Object.keys(props.objCustomer).length > 0 ?
                                    <Fragment>
                                        <p>{props.objCustomer.fullname}</p>
                                        {/* {
                                            (props.existing_order_id !== '' && props.selectedExistingOrder.created_from === 'web' && props.selectedOrderType === 'pickup' ) ?
                                            <p>{formatExistingOrderDateTimeOnly(props.selectedExistingOrder.order_completion_datetime)} </p>
                                            :null
                                        } */}
                                    </Fragment>
                                :null
                            }
                        </div>
                        <div className="col-5 text-right">
                            {
                                props.maxTicketNumber !== undefined ?
                                    <p>Ticket # {props.maxTicketNumber}</p>
                                :null
                            }
                            {
                                props.existing_order_id === '' ?
                                    <p>{formatTodayDate(todayDateTime)}</p>
                                :<p>{formatTodayDate(props.selectedExistingOrder.order_completion_datetime)}</p>
                            }
                        </div>
                        <div className="col-12">
                            {
                                Object.keys(props.objCustomer).length > 0 ?
                                <Fragment>
                                    <p>{props.objCustomer.address}</p>
                                    <p>{props.objCustomer.phone_no}</p>
                                    {
                                        props.selectedOrderType === 'delivery' && props.objCustomer.appartment_unit_number !== '' ?
                                        <p>{props.objCustomer.appartment_unit_number}</p>
                                        : null
                                    }
                                </Fragment>
                                :null
                            }
                            {
                                (Object.keys(props.objCustomer).length > 0 && (props.objCustomer.notes && props.objCustomer.notes !== '')) ?
                                    <p>{props.objCustomer.notes}</p>
                                :null
                            }
                            
                        </div>
                    </div>
                </div>
                    {
                        props.existing_order_id !== '' && ((props.selectedExistingOrder.created_from === 'tablet' || props.selectedExistingOrder.created_from === 'web') && paidReceiptTxt.includes(props.selectedExistingOrder.payment_method) === false ) ?
                        <div className="row ">
                            <div className="col-12">
                                    <p className="txtOrderTabletNotPaid text-center text-capitalize font-weight-bolder">Not Paid
                                        {
                                            props.selectedExistingOrder.created_from === 'web' ? " Mobile"
                                            : props.selectedExistingOrder.created_from === 'tablet' ? " Kiosk"
                                            :null
                                        }
                                    </p>
                    
                            </div>
                        </div>
                        : null
                    }

                    {
                        props.existing_order_id !== '' && ((props.selectedExistingOrder.created_from === 'tablet' || props.selectedExistingOrder.created_from === 'web') && paidReceiptTxt.includes(props.selectedExistingOrder.payment_method)) ?
                        <div className="row">
                            <div className="col-12">
                                    <p className="txtOrderTabletNotPaid text-center text-capitalize font-weight-bolder">Paid
                                        {
                                            props.selectedExistingOrder.created_from === 'web' ? " Mobile"
                                            : props.selectedExistingOrder.created_from === 'tablet' ? " Kiosk"
                                            :null
                                        }
                                    </p>
                    
                            </div>
                        </div>
                        : null
                    }
                <CartItemBlock
                    products={props.cart}
                    selectedOnReceiptMenuItemId={props.selectedOnReceiptMenuItemId}
                    selectedOnReceiptMenuItemTimeStamp={props.selectedOnReceiptMenuItemTimeStamp}
                    selectedOnReceiptMenuItemIngredientId={props.selectedOnReceiptMenuItemIngredientId}
                    selectedOnReceiptMenuItemIngredient={props.selectedOnReceiptMenuItemIngredient}
                    selectedOnReceiptMenuItemComboItemId={props.selectedOnReceiptMenuItemComboItemId}
                    selectedOnReceiptMenuItemComboItemIngredientId={props.selectedOnReceiptMenuItemComboItemIngredientId}
                    selectedOnReceiptMenuItemComboItemIngredient={props.selectedOnReceiptMenuItemComboItemIngredient}
                    type="cart"
                    removeItem={props.removeFromCart}
                    removeSubItemFromCart={(product,subProduct)=> props.removeSubItemFromCart(product,subProduct)}
                    removeIngredientFromCartMenuItem={props.removeIngredientFromCartMenuItem}
                    removeIngredientFromCartComboItem={props.removeIngredientFromCartComboItem}
                    handleOnClickSelectReceiptMenuItem={props.handleOnClickSelectReceiptMenuItem}
                    handleOnClickSelectReceiptMenuItemIngredient={props.handleOnClickSelectReceiptMenuItemIngredient}
                    handleOnClickSelectReceiptMenuItemComboItem={props.handleOnClickSelectReceiptMenuItemComboItem}
                    handleOnClickSelectReceiptComboItemIngredient={props.handleOnClickSelectReceiptComboItemIngredient}
                    selectedOnReceiptItemType={props.selectedOnReceiptItemType}
                />
                
                
            </div>
            {/* <!-- food-cart-head --> */}

            {/* <!-- food-cart-total --> */}
            <div className="food-cart-total bg-white mb-3 py-2">
                <div className="col-8 offset-4">
                    <ul>
                        
                        <li className="d-flex align-items-center justify-content-between">
                            <h6>Discount</h6>
                            <p>${parseFloat(props.discount_amount).toFixed(2)}</p>
                        </li>
                        <li className="d-flex align-items-center justify-content-between">
                            <h6>Subtotal</h6>
                            <p>${parseFloat(props.subTotal).toFixed(2)}</p>
                        </li>
                        <li className="d-flex align-items-center justify-content-between">
                            <h6>Tax</h6>
                            <p>${parseFloat(props.taxAmount).toFixed(2)}</p>
                        </li>
                        <li className="d-flex align-items-center justify-content-between">
                            <h6>TOTAL</h6>
                            <h5 className={`${props.total < 0 ? 'text-danger' : ''}`}>${parseFloat(props.total).toFixed(2)}</h5>
                            {/* <h5 className={`${props.calculateTotalAmount() < 0  ? 'text-danger' : ''}`}>${props.calculateTotalAmount()}</h5> */}
                        </li>
                        {
                            props.selectedOrderType === 'delivery' ?
                            <li className="d-flex align-items-center justify-content-between mt10">
                                <h6>Delivery Fee</h6>
                                <p>${parseFloat(props.delivery_amount).toFixed(2)}</p>
                            </li>
                            : null
                        }
                        {
                            props.selectedOrderType === 'delivery' && props.selectedDeliveryPaymentMethod === 'credit' ?
                                <li className="d-flex align-items-center justify-content-between">
                                    <h6>Card processing fee</h6>
                                    <p>${parseFloat(props.card_processing_fee).toFixed(2)}</p>
                                </li>
                            : null
                        }
                        {
                            props.selectedOrderType === 'delivery'?
                                
                                <li className="d-flex align-items-center justify-content-between">
                                    <h6>Total including fee</h6>
                                    <p>${calculateFinalTotalOfOrderWithOutObjOrder(props.total,props.delivery_amount,props.selectedOrderType,props.selectedDeliveryPaymentMethod,props.card_processing_fee)}</p>
                                </li>
                            : null
                        }
                    </ul>
                </div>
            </div>
            {/* <!-- food-cart-total --> */}

            {/* <!-- food-cart-calculator --> */}
            <div className="food-cart-calculator">
                <input type="text" placeholder="Amount" className="form-control" value={props.customerGivenAmount !== null ? props.customerGivenAmount : ''} onChange={props.handlekeyPadInputValueChange} />
                <KeyPad
                    handleOnClickKeyPadButton={props.handleOnClickKeyPadButton}
                />
            </div>
            {/* <!-- food-cart-calculator --> */}

        </div>
        // {/* <!-- food-cart-box --> */}
    );
};

export default FoodCart;