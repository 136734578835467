import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TabletNewOrdersNotificationModalBody from './TabletNewOrdersNotificationModalBody';
class TabletNewOrdersNotificationModal extends React.Component {
    constructor(props) {
        super(props);
        /* this.state = {
            modal: props.showNewTabletOrderNotificationModal
        }; */
        this.toggle = this.toggle.bind(this);
    }

    componentWillUnmount(){
        // console.log('componet unmount')
    }

    toggle() {
        
        this.props.handleOnChangeNewTabletNotificationModalToggle(!this.props.showNewTabletOrderNotificationModal);
        this.props.clearNewTabletNotificationOrdersHistory();
        this.props.clearNewTabletNotificationOrderLimits();
        
    }
    
    render() {
        /* console.log('this.state.modal in WebOrdersNotificationModal')
        console.log(this.state.modal)
        console.log('this.props.showNewTabletOrderNotificationModal')
        console.log(this.props.showNewTabletOrderNotificationModal) */
        // console.log('this.props.limitExstingOrders')
        // console.log('this.props.limitExstingOrders')
        // console.log(this.props.limitExstingOrders)
        
        return (
                <Modal isOpen={this.props.showNewTabletOrderNotificationModal} toggle={this.toggle} className={this.props.className} size="lg">
                    <ModalHeader toggle={this.toggle}>New Kiosk Notification Orders</ModalHeader>
                    <ModalBody>
                        <TabletNewOrdersNotificationModalBody 
                            order={this.props.order}
                            onClickHandleLoadMoreNewTabletNotificationOrderItems={this.props.onClickHandleLoadMoreNewTabletNotificationOrderItems}
                            limitTabletNewNotificationOrders={this.props.limitTabletNewNotificationOrders}
                            isloadingNewTabletNotificationOrders={this.props.isloadingNewTabletNotificationOrders}
                            handelOnClickTabletNewOrder={this.props.handelOnClickTabletNewOrder}
                        />
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.toggle}>Done</Button>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter> */}
                </Modal>
        );
    }
};

export default TabletNewOrdersNotificationModal;