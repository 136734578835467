import React,{Fragment} from 'react';
import trashRedPng from '../../../../../common/images/icons/trash-red.png';
import RenderComboItemIngredients from './RenderComboItemIngredients';
const CartItemSubBlock = ({
        mainProduct,
        products,
        removeSubItemFromCart,
        removeIngredientFromCartComboItem,
        handleOnClickSelectReceiptMenuItemComboItem,
        selectedOnReceiptMenuItemId,
        selectedOnReceiptMenuItemTimeStamp,
        selectedOnReceiptItemType,
        selectedOnReceiptMenuItemComboItemId,
        selectedOnReceiptMenuItemComboItemIngredientId,
        selectedOnReceiptMenuItemComboItemIngredient,
        handleOnClickSelectReceiptComboItemIngredient,
        confirmAlert
    }) => {

    const handleOnClickComboItem = (mainProduct,product,type) =>{
        handleOnClickSelectReceiptMenuItemComboItem(mainProduct,product,type);
        /* let buttons = [];
        const objButton = {
            label: 'SELECT ITEM',
            onClick: () => {
                handleOnClickSelectReceiptMenuItemComboItem(mainProduct,product);
            }
        }
        buttons.push(objButton);
        const objDeleteButton =    {
                label: 'DELETE ITEM',
                onClick: () => {
                    removeSubItemFromCart(mainProduct,product)
                }
            }
        buttons.push(objDeleteButton);
        confirmAlert({
            // title: 'Confirm to submit',
            // message: 'Are you sure to do this.',
            buttons: buttons
        }); */
    }

    // console.log(products)
    // console.log(products)
    const renderItems = () => (
        products ?
            products.map((product,index)=>{
                if(product.is_note){
                    return <ul className="food-info-sublist" key={index}>
                        <li>
                            <h6 className="position-relative">
                                <div className = {`menu-item-combo-item-name ${(selectedOnReceiptMenuItemId === mainProduct.menu_item_id && selectedOnReceiptMenuItemTimeStamp === mainProduct.timestamp && selectedOnReceiptItemType === 'combo_item_notes' && selectedOnReceiptMenuItemComboItemId === product.timestamp) ? "selected" : ""} `} onClick = {() => handleOnClickComboItem(mainProduct, product,'combo_item_notes')} > With {product.notes } </div>
                                <div className="position-absolute menu-item-combo-item-price">
                                    {/* <img src={trashRedPng} alt="trash" className="mw-100 cursor-pointer trash-icon"  onClick={()=> removeSubItemFromCart(mainProduct,product)}  /> */}
                                </div>
                            </h6>
                        </li>
                    </ul>
                }else{
                    return <ul className="food-info-sublist" key={index}>
                        <li>
                            <h6 className="position-relative">
                                <div className = {`menu-item-combo-item-name ${(selectedOnReceiptMenuItemId === mainProduct.menu_item_id && selectedOnReceiptMenuItemTimeStamp === mainProduct.timestamp && selectedOnReceiptItemType === 'combo_item' && selectedOnReceiptMenuItemComboItemId === product.selectedComboItemId) ? "selected" : ""}`} onClick = {() => handleOnClickComboItem(mainProduct, product,'combo_item')} > With {product.name } </div>
                                <div className="position-absolute menu-item-combo-item-price ">
                                    ${(parseFloat(product.combo_item_price)).toFixed(2) }
                                    {/* <img src={trashRedPng} alt="trash" className="mw-100 cursor-pointer trash-icon"  onClick={()=> removeSubItemFromCart(mainProduct,product)}  /> */}
                                </div>
                            </h6>
                            {
                                product.ingredients.length > 0 ? RenderComboItemIngredients(mainProduct,product,product.ingredients,removeIngredientFromCartComboItem,selectedOnReceiptMenuItemId,selectedOnReceiptMenuItemTimeStamp,selectedOnReceiptItemType,selectedOnReceiptMenuItemComboItemId,selectedOnReceiptMenuItemComboItemIngredientId,selectedOnReceiptMenuItemComboItemIngredient,handleOnClickSelectReceiptComboItemIngredient,confirmAlert) : null
                                // product.ingredients.length > 0 ? RenderComboItemIngredients(mainProduct,product,product.ingredients,removeIngredientFromCartComboItem) : null
                            }
                            
                        </li>
                    </ul>
                }
            })

        :null
    )


    return (
        <Fragment>
            {renderItems()}
        </Fragment>
    );
};

export default CartItemSubBlock;