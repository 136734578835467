import React,{Fragment} from 'react';
import PoskitchenCarouselCartItemSubBlock from './PoskitchenCarouselCartItemSubBlock.js' 
import PosKitchenCarouselRenderMenuItemIngredients from './PosKitchenCarouselRenderMenuItemIngredients';
// import { Link } from 'react-router-dom';
const PosKitchenCarouselCartItemBlock = ({
        products,
        comboItemColor,
    }) => {


    const renderItems = () => (
        products ?
            products.map(product=>{
                if(product.item_type === 'notes'){
                    return <ul className="food-info-list" key={product.timestamp}>
                            <li>
                                <h6 className="position-relative text-warning" >
                                    <span className = {`menu-item-name `}> {product.notes} </span>
                                    {/* <span className="position-absolute">
                                        {
                                            product.is_paid_notes ? <Fragment> ${(parseFloat(product.extra_charges)).toFixed(2) } </Fragment> : null
                                        }
                                        
                                    </span> */}
                                </h6>
                            </li>
                    </ul>
                }else if(product.item_type === 'refunded_item'){
                    return <ul className="food-info-list" key={product.timestamp}>
                                <li>
                                    <h6 className="position-relative text-warning">
                                        <span className = {`menu-item-name`}> {product.item_type === "refunded_item" ? 'Refund ' : null}
                                            {
                                                product.refuned_item_type === 'notes' ? <Fragment>  {product.notes} </Fragment> : <Fragment>{product.is_size_selected ? `${product.size_name} ` : null}{product.is_special_modifier_selected ? `${product.special_modifier_name} ` : null}{product.name}</Fragment>
                                            }
                                        </span>
                                        {/* <span className="position-absolute">
                                            {
                                                ((product.refuned_item_type !== 'notes' && product.price !== undefined && product.price !== null  && !isNaN(Number(product.price))) || product.is_paid_notes) ? <Fragment>  - ${(parseFloat(product.price)).toFixed(2) } </Fragment> : null
                                            }
                                            
                                        </span> */}
                                    </h6>
                                </li>
                            </ul>
                }else{
                    return <ul className="food-info-list" key={product.timestamp}>
                            <li>
                                <h6 className="position-relative text-warning">
                                    <span className = {`menu-item-name  `}> {product.item_type === "refunded_item" ? 'Refund ' : null}{product.is_size_selected ? `${product.size_name} ` : null}{product.is_special_modifier_selected ? `${product.special_modifier_name} ` : null}{product.name} </span>
                                    <span className="position-absolute">
                                    {/* {product.item_type === "refunded_item" ? '-' : null}${(parseFloat(product.price)).toFixed(2) } */}
                                        
                                    </span>
                                </h6>
                                {
                                    product.ingredients.length > 0 ? PosKitchenCarouselRenderMenuItemIngredients(product,product.ingredients) : null
                                }
                                <PoskitchenCarouselCartItemSubBlock
                                    mainProduct={product}
                                    products={product.selectedComboItems}
                                    comboItemColor={comboItemColor}
                                    type="cart"
                                />
                                
                            </li>
                    </ul>
                }
            })

        :null
    )


    return (
        <div>
            {products ?
                        products.length === 0 ?
                            <div className="text-center mt20 mb20">
                                <h3>The cart is empty</h3>
                            </div>
                        :null
                    :null}
            {renderItems()}
        </div>
    );
};

export default PosKitchenCarouselCartItemBlock;