import React,{Fragment} from 'react';
import NotePad from '../NotePad/NotePad';
const MenuItemIngredientsTypes = (props) => {
    const NotePadComp = <NotePad 
                            MenuCategoriesLength={props.menuCategories.MenuCategories.length}
                            addNotesWithExtraCharge={props.addNotesWithExtraCharge}
                            showNotePadModal={props.showNotePadModal}
                            handleOnChangeNotePadToggle={props.handleOnChangeNotePadToggle}
                            isMistakeActive={props.isMistakeActive}
                            mistake_type={props.mistake_type}
                        />
    if(props.menuCategoryItemId !== ''){
        return (
            <Fragment>
                <div className="row preFilledNotesRow">
                    <div className="col">
                        <button
                            className={`btn btnPreWrittenNotes w-100 py-2 mb-3 d-inline-flex align-items-center justify-content-center `} onClick={()=>props.addNotesWithExtraCharge('Heated','no')}>
                            Heated
                        </button>
                    </div>
                    <div className="col">
                        <button
                            className={`btn btnPreWrittenNotes w-100 py-2 mb-3 d-inline-flex align-items-center justify-content-center  `} onClick={()=>props.addNotesWithExtraCharge('Well done','no')}>
                            Well done
                        </button>
                    </div>
                    <div className="col">
                        <button
                            className={`btn btnPreWrittenNotes w-100 py-2 mb-3 d-inline-flex align-items-center justify-content-center  `} onClick={()=>props.addNotesWithExtraCharge('Grilled','no')}>
                            Grilled
                        </button>
                    </div>
                    <div className="col">
                        <button
                            className={`btn btnPreWrittenNotes w-100 py-2 mb-3 d-inline-flex align-items-center justify-content-center  `} onClick={()=>props.addNotesWithExtraCharge('Lightly cooked','no')}>
                            Lightly cooked
                        </button>
                    </div>
                    {/* <div className="col notPadCol"> */}
                    <div className="col">
                        {NotePadComp}
                    </div>
                    
                </div>
                <div className="row ingredientTypeRow">
                    <div className="col-2">
                        <button
                            className={`btn btn-secondary w-100 py-2 mb-3 d-inline-flex align-items-center justify-content-center ${props.selectedMenuItemIngredientTypeCategory ===  'no' ? 'btnActiveIngredientType' : ''} `} onClick={()=>props.handleOnClickChangeMenuItemIngredientType('no')}>
                            No
                        </button>
                    </div>
                    <div className="col-2">
                        <button
                            className={`btn btn-secondary w-100 py-2 mb-3 d-inline-flex align-items-center justify-content-center ${props.selectedMenuItemIngredientTypeCategory ===  'extra' ? 'btnActiveIngredientType' : ''} `} onClick={()=>props.handleOnClickChangeMenuItemIngredientType('extra')}>
                            extra
                        </button>
                    </div>
                    <div className="col-2">
                        <button
                            className={`btn btn-secondary w-100 py-2 mb-3 d-inline-flex align-items-center justify-content-center ${props.selectedMenuItemIngredientTypeCategory ===  'add' ? 'btnActiveIngredientType' : ''} `} onClick={()=>props.handleOnClickChangeMenuItemIngredientType('add')}>
                            Add
                        </button>
                    </div>
                    <div className="col-2">
                        <button
                            className={`btn btn-secondary w-100 py-2 mb-3 d-inline-flex align-items-center justify-content-center ${props.selectedMenuItemIngredientTypeCategory ===  'light' ? 'btnActiveIngredientType' : ''} `} onClick={()=>props.handleOnClickChangeMenuItemIngredientType('light')}>
                            light
                        </button>
                    </div>
                    <div className="col-2">
                        <button
                            className={`btn btn-secondary w-100 py-2 mb-3 d-inline-flex align-items-center justify-content-center ${props.selectedMenuItemIngredientTypeCategory ===  'only' ? 'btnActiveIngredientType' : ''} `} onClick={()=>props.handleOnClickChangeMenuItemIngredientType('only')}>
                            only
                        </button>
                    </div>
                    <div className="col-2">
                        {/* {NotePadComp} */}
                        <button
                        className={`btn btn-secondary w-100 py-2 mb-3 d-inline-flex align-items-center justify-content-center btnPutOnSide ${props.selectedMenuItemIngredientTypeCategory === 'put_on_side' ? 'btnActiveIngredientType' : ''} `} onClick={() => props.handleOnClickChangeMenuItemIngredientType('put_on_side')}>
                            PUT ON SIDE
                        </button>
                        {/* <button
                            className={`btn btn-secondary w-100 py-2 mb-3 d-inline-flex align-items-center justify-content-center ${props.selectedMenuItemIngredientTypeCategory ===  'well_done' ? 'btnActiveIngredientType' : ''} `} onClick={()=>props.handleOnClickChangeMenuItemIngredientType('well_done')}>
                            well done
                        </button> */}
                    </div>
                </div>
            </Fragment>
        );
    }else{
        return (
            <div className="row">
                <div className="col-10">
                    
                </div>
                
                <div className="col-2">
                    {NotePadComp}
                    {/* <button
                        className={`btn btn-secondary w-100 py-2 mb-3 d-inline-flex align-items-center justify-content-center ${props.selectedMenuItemIngredientTypeCategory ===  'well_done' ? 'btnActiveIngredientType' : ''} `} onClick={()=>props.handleOnClickChangeMenuItemIngredientType('well_done')}>
                        well done
                    </button> */}
                </div>
            </div>
    );
    }
};

export default MenuItemIngredientsTypes;