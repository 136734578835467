import { toast } from 'react-toastify';
import moment from 'moment';
import { BASE_URL } from './misc';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';
export const required = value => value ? undefined : 'This field is required.';

export function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g,"\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function showMsg(msg,type) {
    if(type === 'success'){
        toast.success(msg);
    }else if(type === 'error'){
        toast.error(msg);
    }
    else if(type === 'info'){
        toast.info(msg);
    }
}
export function showInternetConnectionMsg(msg,type,opt={}) {
    if(type === 'success'){
        toast.success(msg,opt);
    }else if(type === 'error'){
        toast.error(msg,opt);
    }
    else if(type === 'info'){
        toast.info(msg,opt);
    }
}

export function getUniqueName() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    let timestamp = new Date().getUTCMilliseconds();
    return timestamp + Math.random().toString(36).substr(2, 9);
}

export function getUniqueNameWithTimeStamp() {
    let timestamp = new Date().getUTCMilliseconds();
    return (timestamp + Math.random().toString(36).substring(2, 15)) + Math.random().toString(36).substring(2, 15);
}


export function timeAgo(date) {
    moment.locale('en', {
        relativeTime : {
            future: "%s ago",
        }
    });
    /*moment.fromNow = function (a) {
        if (Math.abs(moment().diff(this)) < 25000) { // 25 seconds before or after now
            return 'a few seconds ago';
        }
        return this.fromNow(a);
    }*/
  var output = new Date(date*1000);
  output = moment(output).fromNow();
  return output;
}
export function formatOrderDate(datetime) {
    // let date = new Date(datetime);
    let formated_date = moment(datetime).format("Do MMMM YYYY"); 
    // formated_date = date.getDate() + '-' + (date.getMonth()+1) + '-' +  date.getFullYear();
    return formated_date;
}
export function formatOrderDateNumber(datetime) {
    // let date = new Date(datetime);
    let formated_date = moment(datetime).format("DD-MM-YYYY kk:mm"); 
    // formated_date = date.getDate() + '-' + (date.getMonth()+1) + '-' +  date.getFullYear();
    return formated_date;
}
export function formatTodayDate(datetime) {
    // let date = new Date(datetime);
    let formated_date = moment(datetime).format("MMM D YYYY"); 
    // formated_date = date.getDate() + '-' + (date.getMonth()+1) + '-' +  date.getFullYear();
    return formated_date;
}
export function formatExistingOrderDateTime(datetime) {
    // let date = new Date(datetime);
    let formated_date = moment(datetime).format("MMM D YYYY hh:mm A"); 
    // formated_date = date.getDate() + '-' + (date.getMonth()+1) + '-' +  date.getFullYear();
    return formated_date;
}
export function formatExistingOrderDate(datetime) {
    // let date = new Date(datetime);
    let formated_date = moment(datetime).format("MMM D YYYY"); 
    // formated_date = date.getDate() + '-' + (date.getMonth()+1) + '-' +  date.getFullYear();
    return formated_date;
}
export function formatExistingOrderDateTimeOnly(datetime) {
    // let date = new Date(datetime);
    let formated_date = moment(datetime).format("hh:mm A"); 
    // formated_date = date.getDate() + '-' + (date.getMonth()+1) + '-' +  date.getFullYear();
    return formated_date;
}

export function getDifferenceBetweenTwoTimesinHMS(orderTime) {
    // let date = new Date(datetime);
    orderTime = moment(orderTime)
    let now = moment(new Date())

    // calculate the duration
    let d = moment.duration(now.diff(orderTime));
    // let d = moment.duration(orderTime.diff(now));

    // console.log('d')
    // console.log(d)
    // console.log(d.hours())
    // console.log(d.minutes())
    // console.log(d.seconds()) 
    // formated_date = date.getDate() + '-' + (date.getMonth()+1) + '-' +  date.getFullYear();
    // const diff = `${d.asHours()}:${d.asMinutes()}:${d.asSeconds()}` 
    let h = "";
    let m = "";
    let s = "";
    // console.log('d.hours().toString().length')
    // console.log('d.minutes().toString().length')
    // console.log('d.seconds().toString().length')
    // console.log(d.hours().toString().length)
    // console.log(d.minutes().toString().length)
    // console.log(d.seconds().toString().length)
    if (d.hours().toString().length === 1) {
        h = `0${d.hours()}`;
    }else{
        h = d.hours();
    }
    if (d.minutes().toString().length === 1) {
        m = `0${d.minutes()}`;
    }else{
        m = d.minutes();
    }
    if (d.seconds().toString().length === 1) {
        s = `0${d.seconds()}`;
    }else{
        s = d.seconds();
    }
    // console.log(h)
    // console.log(m)
    // console.log(s)
    // const diff = `${d.hours()}:${d.minutes()}:${d.seconds()}` 
    const diff = `${h}:${m}:${s}` 
    return diff;
}

export function calculateOrderMenuItemTotal(order) {
    let tax = parseFloat(order.tax_per/100).toFixed(2);
    
    let total = 0;
    let total_amount = 0.00;
    if(order.order_details.length > 0 ){

        for (let index = 0; index < order.order_details.length; index++) {
            const element = order.order_details[index];
            total = parseFloat(parseFloat(total) + parseFloat(element.totalPrice)).toFixed(2);
        }
        let tax_amount = ((total) * (tax)).toFixed(2);
        total_amount = (parseFloat(total) + parseFloat(tax_amount)).toFixed(2);
        
        
    }
    // console.log(order.order_no)
    // console.log('total')
    // console.log(total)
    // console.log('total_amount')
    // console.log(total_amount)
    return total_amount;
}
export function validatePhoneNumber(phone_no) {
    let err = false;
    let msg = '';
    if (phone_no === '') {
        err = true;
        msg = 'Phone Number Field is Required.';
    }else if (isNaN(Number(phone_no))) {
        err = true;
        msg = 'Phone Number Field Must be a Number'
    }else if(!/^(0|[1-9][0-9]{9})$/i.test(phone_no)){
        err = true;
        msg = 'Invalid phone number, must be 10 digits';
    }
    return {err,msg};
}

export function addTimeToCurrentDateTime(time_in_mins) {
    /* console.log('time_in_mins')
    console.log('time_in_mins')
    console.log('time_in_mins')
    console.log(time_in_mins) */
    // let date = new Date(datetime);
    // let formated_date = moment(datetime).format("MMM D YYYY"); 
    var dt = new Date();
    /* console.log('dt before')
    console.log('dt before')
    console.log('dt before')
    console.log(dt)
    console.log('dt.getMinutes()')
    console.log(dt.getMinutes()) */
    dt.setMinutes( dt.getMinutes() + parseInt(time_in_mins) );
    /* console.log('dt after')
    console.log('dt after')
    console.log('dt after')
    console.log(dt) */
    // formated_date = date.getDate() + '-' + (date.getMonth()+1) + '-' +  date.getFullYear();
    return dt;
}

export function isDateMatches (date1,date2) {
    /* var date1 = new Date(date1);
    var date2 = new Date(date2); */
    date1 = new Date(date1);
    date2 = new Date(date2);
    return ((date1.getFullYear() === date2.getFullYear()) &&
           // getMonth is 0-indexed
           (date1.getMonth() === date2.getMonth()) &&
           (date1.getDate() === date2.getDate()));
}

export async function checkInternetConnection () {
    // return false;
    try {
        const response = await fetch(`${BASE_URL}/api/check-connection`);
        /* console.log('i am in response of checkInternetConnection')
        console.log(response)
        console.log('response.ok')
        console.log(response.ok) */
        if (!response.ok) {
            throw Error(response.statusText);
        }
        // const json = await response.json();
        return true;
    } catch (error) {
        // console.log('i am in error of checkInternetConnection')
        // console.log(error)
        return false;
        // console.log(error);
    }

    /* fetch('//google.com', {
        mode: 'no-cors',
        })
    .then(() => {
        return true;
    }).catch(() => {
        return false;
    }) */
    /* if (window.navigator.onLine) {
        return true;
    } else {
        return false;
    } */   
}

export function checkInternetConnectionWithBrowserNavigation () {
    if (window.navigator.onLine) {
        return true;
    } else {
        return false;
    }
}

function getDistanceInKiloToBeRemoved(delivery_fees){
    let distance_in_kilo_to_be_removed = 0;
    let distance_in_kilo_to_be_removed_price = 0;
    // if(delivery_fees.extra_charge_per_km_after.price !== ''){
    //     distance_in_kilo_to_be_removed = 15;
    // }else
    if(delivery_fees.fourtheen_to_fifteen.price !== ''){
        distance_in_kilo_to_be_removed = 15;
        distance_in_kilo_to_be_removed_price = delivery_fees.fourtheen_to_fifteen.price;
    }else if(delivery_fees.thirteen_to_fourtheen.price !== ''){
        distance_in_kilo_to_be_removed = 14;
        distance_in_kilo_to_be_removed_price = delivery_fees.thirteen_to_fourtheen.price;
    }else if(delivery_fees.twelve_to_thirteen.price !== ''){
        distance_in_kilo_to_be_removed = 13;
        distance_in_kilo_to_be_removed_price = delivery_fees.twelve_to_thirteen.price;
    }else if(delivery_fees.eleven_to_twelve.price !== ''){
        distance_in_kilo_to_be_removed = 12;
        distance_in_kilo_to_be_removed_price = delivery_fees.eleven_to_twelve.price;
    }else if(delivery_fees.ten_to_eleven.price !== ''){
        distance_in_kilo_to_be_removed = 11;
        distance_in_kilo_to_be_removed_price = delivery_fees.ten_to_eleven.price;
    }else if(delivery_fees.nine_to_ten.price !== ''){
        distance_in_kilo_to_be_removed = 10;
        distance_in_kilo_to_be_removed_price = delivery_fees.nine_to_ten.price;
    }else if(delivery_fees.eight_to_nine.price !== ''){
        distance_in_kilo_to_be_removed = 9;
        distance_in_kilo_to_be_removed_price = delivery_fees.eight_to_nine.price;
    }else if(delivery_fees.seven_to_eight.price !== ''){
        distance_in_kilo_to_be_removed = 8;
        distance_in_kilo_to_be_removed_price = delivery_fees.seven_to_eight.price;
    }else if(delivery_fees.six_to_seven.price !== ''){
        distance_in_kilo_to_be_removed = 7;
        distance_in_kilo_to_be_removed_price = delivery_fees.six_to_seven.price;
    }else if(delivery_fees.five_to_six.price !== ''){
        distance_in_kilo_to_be_removed = 6;
        distance_in_kilo_to_be_removed_price = delivery_fees.five_to_six.price;
    }else if(delivery_fees.four_to_five.price !== ''){
        distance_in_kilo_to_be_removed = 5;
        distance_in_kilo_to_be_removed_price = delivery_fees.four_to_five.price;
    }else if(delivery_fees.three_to_four.price !== ''){
        distance_in_kilo_to_be_removed = 4;
        distance_in_kilo_to_be_removed_price = delivery_fees.three_to_four.price;
    }else if(delivery_fees.two_to_three.price !== ''){
        distance_in_kilo_to_be_removed = 3;
        distance_in_kilo_to_be_removed_price = delivery_fees.two_to_three.price;
    }else if(delivery_fees.one_to_two.price !== ''){
        distance_in_kilo_to_be_removed = 2;
        distance_in_kilo_to_be_removed_price = delivery_fees.one_to_two.price;
    }else{
        distance_in_kilo_to_be_removed = 0;
        distance_in_kilo_to_be_removed_price =0;
    }
    return {distance_in_kilo_to_be_removed,distance_in_kilo_to_be_removed_price};
}

export function getDeliveryAmount(delivery_amount,delivery_fees,distance_in_kilo) {
    if(distance_in_kilo > 0 && distance_in_kilo<= 1){
        delivery_amount = delivery_fees.zeor_to_one.price;
    }else if(distance_in_kilo > 1 && distance_in_kilo<= 2){
        delivery_amount = delivery_fees.one_to_two.price;
    }else if(distance_in_kilo > 2 && distance_in_kilo<= 3){
        delivery_amount = delivery_fees.two_to_three.price;
    }else if(distance_in_kilo > 3 && distance_in_kilo<= 4){
        delivery_amount = delivery_fees.three_to_four.price;
    }else if(distance_in_kilo > 4 && distance_in_kilo<= 5){
        delivery_amount = delivery_fees.four_to_five.price;
    }else if(distance_in_kilo > 5 && distance_in_kilo<= 6){
        delivery_amount = delivery_fees.five_to_six.price;
    }else if(distance_in_kilo > 6 && distance_in_kilo<= 7){
        delivery_amount = delivery_fees.six_to_seven.price;
    }else if(distance_in_kilo > 7 && distance_in_kilo<= 8){
        delivery_amount = delivery_fees.seven_to_eight.price;
    }else if(distance_in_kilo > 8 && distance_in_kilo<= 9){
        delivery_amount = delivery_fees.eight_to_nine.price;
    }else if(distance_in_kilo > 9 && distance_in_kilo<= 10){
        delivery_amount = delivery_fees.nine_to_ten.price;
    }else if(distance_in_kilo > 10 && distance_in_kilo<= 11){
        delivery_amount = delivery_fees.ten_to_eleven.price;
    }else if(distance_in_kilo > 11 && distance_in_kilo<= 12){
        delivery_amount = delivery_fees.eleven_to_twelve.price;
    }else if(distance_in_kilo > 12 && distance_in_kilo<= 13){
        delivery_amount = delivery_fees.twelve_to_thirteen.price;
    }else if(distance_in_kilo > 13 && distance_in_kilo<= 14){
        delivery_amount = delivery_fees.thirteen_to_fourtheen.price;
    }else if(distance_in_kilo > 14 && distance_in_kilo<= 15){
        delivery_amount = delivery_fees.fourtheen_to_fifteen.price;
    }else if(distance_in_kilo >= 15){
        
        // console.log('delivery_fees.fourtheen_to_fifteen.price')
        // console.log(delivery_fees.fourtheen_to_fifteen.price)
        let price = 0;
        if(delivery_fees.fourtheen_to_fifteen.price !== ''){
            price = delivery_fees.fourtheen_to_fifteen.price;
        }else if(delivery_fees.thirteen_to_fourtheen.price !== ''){
            price = delivery_fees.thirteen_to_fourtheen.price;
        }else if(delivery_fees.twelve_to_thirteen.price !== ''){
            price = delivery_fees.twelve_to_thirteen.price;
        }else if(delivery_fees.eleven_to_twelve.price !== ''){
            price = delivery_fees.eleven_to_twelve.price;
        }else if(delivery_fees.ten_to_eleven.price !== ''){
            price = delivery_fees.ten_to_eleven.price;
        }else if(delivery_fees.nine_to_ten.price !== ''){
            price = delivery_fees.nine_to_ten.price;
        }else if(delivery_fees.eight_to_nine.price !== ''){
            price = delivery_fees.eight_to_nine.price;
        }else if(delivery_fees.seven_to_eight.price !== ''){
            price = delivery_fees.seven_to_eight.price;
        }else if(delivery_fees.six_to_seven.price !== ''){
            price = delivery_fees.six_to_seven.price;
        }else if(delivery_fees.five_to_six.price !== ''){
            price = delivery_fees.five_to_six.price;
        }else if(delivery_fees.four_to_five.price !== ''){
            price = delivery_fees.four_to_five.price;
        }else if(delivery_fees.three_to_four.price !== ''){
            price = delivery_fees.three_to_four.price;
        }else if(delivery_fees.two_to_three.price !== ''){
            price = delivery_fees.two_to_three.price;
        }else if(delivery_fees.one_to_two.price !== ''){
            price = delivery_fees.one_to_two.price;
        }else{
            price = 0;
        }
        if(distance_in_kilo > 15 && delivery_fees.extra_charge_per_km_after.price !== ''){
            price = delivery_fees.extra_charge_per_km_after.price;
        }

        // if(delivery_fees.extra_charge_per_km_after.price !== ''){
        //     price = delivery_fees.extra_charge_per_km_after.price;
        // }
        console.log('price')
        console.log('price')
        console.log(price);
        let {distance_in_kilo_to_be_removed,distance_in_kilo_to_be_removed_price} = getDistanceInKiloToBeRemoved(delivery_fees);
        console.log('distance_in_kilo_to_be_removed')
        console.log('distance_in_kilo_to_be_removed')
        console.log(distance_in_kilo_to_be_removed);
        console.log('distance_in_kilo_to_be_removed_price')
        console.log('distance_in_kilo_to_be_removed_price')
        console.log(distance_in_kilo_to_be_removed_price);
        let calculate_over_distance = parseFloat(parseFloat(distance_in_kilo) - parseFloat(distance_in_kilo_to_be_removed)).toFixed(2);
        // let calculate_over_distance = parseFloat(parseFloat(distance_in_kilo) - 15).toFixed(2);
        console.log('calculate_over_distance')
        console.log('calculate_over_distance')
        console.log(calculate_over_distance);
        if(price === 0){
            delivery_amount = 0;
        }else{
            delivery_amount = parseFloat((parseFloat(price))*parseFloat(calculate_over_distance)).toFixed(2);
            delivery_amount = parseFloat(parseFloat(delivery_amount)+ parseFloat(distance_in_kilo_to_be_removed_price)).toFixed(2);
            // delivery_amount = parseFloat(parseFloat(price)+parseFloat(calculate_over_distance)).toFixed(2);
        }
        // delivery_amount = parseFloat(9.00+parseFloat(calculate_over_distance)).toFixed(2);
    }
    if(delivery_amount === ''){
        delivery_amount = 0;
    }

    return delivery_amount;
}



export const notesCommon = ['heated','well done','grilled','lightly cooked'];

export const paidReceiptTxt = ['cash', 'debit_card', 'credit_card', 'gift_card'];

export const cashThroughTypes = ['cash', 'debit_card', 'credit_card', 'gift_card'];