import React,{Fragment}from 'react';
const KeyPad = (props) => {

  
    return (
        <Fragment>
            <div className="row">
                <div className="col-4">
                    <button name="7" onClick={e => props.handleOnClickKeyPadButton(e.target.name)}
                        className="btn btn-white text-white mb-3 w-100 d-flex align-items-center justify-content-around">
                        7
                    </button>
                </div>
                <div className="col-4">
                    <button name="8" onClick={e => props.handleOnClickKeyPadButton(e.target.name)}
                        className="btn btn-white text-white mb-3 w-100 d-flex align-items-center justify-content-around">
                        8
                    </button>
                </div>
                <div className="col-4">
                    <button name="9" onClick={e => props.handleOnClickKeyPadButton(e.target.name)}
                        className="btn btn-white text-white mb-3 w-100 d-flex align-items-center justify-content-around">
                        9
                    </button>
                </div>
                <div className="col-4">
                    <button name="4" onClick={e => props.handleOnClickKeyPadButton(e.target.name)}
                        className="btn btn-white text-white mb-3 w-100 d-flex align-items-center justify-content-around">
                        4
                    </button>
                </div>
                <div className="col-4">
                    <button name="5" onClick={e => props.handleOnClickKeyPadButton(e.target.name)}
                        className="btn btn-white text-white mb-3 w-100 d-flex align-items-center justify-content-around">
                        5
                    </button>
                </div>
                <div className="col-4">
                    <button name="6" onClick={e => props.handleOnClickKeyPadButton(e.target.name)}
                        className="btn btn-white text-white mb-3 w-100 d-flex align-items-center justify-content-around">
                        6
                    </button>
                </div>
                <div className="col-4">
                    <button name="1" onClick={e => props.handleOnClickKeyPadButton(e.target.name)}
                        className="btn btn-white text-white mb-3 w-100 d-flex align-items-center justify-content-around">
                        1
                    </button>
                </div>
                <div className="col-4">
                    <button name="2" onClick={e => props.handleOnClickKeyPadButton(e.target.name)}
                        className="btn btn-white text-white mb-3 w-100 d-flex align-items-center justify-content-around">
                        2
                    </button>
                </div>
                <div className="col-4">
                    <button name="3" onClick={e => props.handleOnClickKeyPadButton(e.target.name)}
                        className="btn btn-white text-white mb-3 w-100 d-flex align-items-center justify-content-around">
                        3
                    </button>
                </div>
                <div className="col-4">
                    <button name="clear" onClick={e => props.handleOnClickKeyPadButton(e.target.name)}
                        className="btn btn-white text-white text-regular mb-3 w-100 d-flex align-items-center justify-content-around">
                        clear
                    </button>
                </div>
                <div className="col-4">
                    <button name="0" onClick={e => props.handleOnClickKeyPadButton(e.target.name)}
                        className="btn btn-white text-white mb-3 w-100 d-flex align-items-center justify-content-around">
                        0
                    </button>
                </div>
                <div className="col-4">
                    <button name="." onClick={e => props.handleOnClickKeyPadButton(e.target.name)}
                        className="btn btn-white pb-4 text-white mb-3 w-100 d-flex align-items-center justify-content-around">
                        .
                    </button>
                </div>
            </div>
            <div className="row rowExtraPaymentButtons">
                <div className="btnRow margin-right-5">
                    <button name="5" onClick={e => props.handleOnClickKeyPadButton(5)}
                        className="btn btn-white text-white mb-3 w-100 d-flex align-items-center justify-content-around btnColorLightGreen">
                        5
                    </button>
                </div>
                <div className="btnRow margin-right-5">
                    <button name="10" onClick={e => props.handleOnClickKeyPadButton(10)}
                        className="btn btn-white text-white mb-3 w-100 d-flex align-items-center justify-content-around btnColorLightGreen">
                        10
                    </button>
                </div>
                <div className="btnRow margin-right-5">
                    <button name="20" onClick={e => props.handleOnClickKeyPadButton(20)}
                        className="btn btn-white text-white mb-3 w-100 d-flex align-items-center justify-content-around btnColorLightGreen">
                        20
                    </button>
                </div>
                <div className="btnRow">
                    <button name="50" onClick={e => props.handleOnClickKeyPadButton(50)}
                        className="btn btn-white text-white mb-3 w-100 d-flex align-items-center justify-content-around btnColorLightGreen">
                        50
                    </button>
                </div>
            </div>
        </Fragment>
    );
};

export default KeyPad;