import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class KitchenHeadCarousel extends React.Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          dropdownOpen: false
        };
    }
    
    toggle() {
        this.setState({
          dropdownOpen: !this.state.dropdownOpen
        });
    }

    
    render() {
        return (
            <section className="kitchen-head">
                <div className="container-fluid bg-white">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-end">
                            <ButtonDropdown  isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                <DropdownToggle className="btn btn-none-bg d-flex align-items-center btnMenuDropDownToggle" caret>
                                    Menu
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <Link to="/" target="_blank"><DropdownItem>POS</DropdownItem></Link>
                                    <Link to="/pos-orders" target="_blank"><DropdownItem>POS Orders</DropdownItem></Link>
                                    <a href={`https://${process.env.REACT_APP_API_DOMAIN}.${process.env.REACT_APP_FULL_DOMAIN}/admin/login`} target="_blank" rel="noopener noreferrer"><DropdownItem>Admin Pannel</DropdownItem></a>
                                    {/* <DropdownItem header>Header</DropdownItem> */}
                                    {/* <DropdownItem disabled>Action</DropdownItem> */}
                                    <DropdownItem divider />
                                    <Link to="/logout" ><DropdownItem>Logout</DropdownItem></Link>
                                </DropdownMenu>
                            </ButtonDropdown>
                            {/* <button className="btn btn-none-bg d-flex align-items-center">
                                menu
                            </button> */}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
};

export default KitchenHeadCarousel;