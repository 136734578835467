import React, { Component,Fragment } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Script from 'react-load-script';
import { fetchOrderByOId  } from '../../../actions/order_actions';
import { fetchRestaurantName} from '../../../actions/settings_actions';
import LoadingSpinner from '../../utils/LoadingSpinner';
import OrderItemPosPrint from './components/OrderItemPosPrint';
import PosOrderPrintRenderSelectedDeliveryType from './components/PosOrderPrintRenderSelectedDeliveryType';
import { Button } from 'reactstrap';
import { formatExistingOrderDateTimeOnly,checkInternetConnection } from '../../utils/general';
// import './css/print_pos_order.css'
class PrintPosOrder extends Component {
    _isMounted = false;
    state = {
        order_id:'',
        isloadingOrder: false,
        restaurant_name:'',
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        let order_id = this.props.match.params.order_id;
        if (this._isMounted) {
            this.setState({order_id,isloadingOrder:true});
        }
        this.props.fetchOrderByOId(order_id).then(response => {
            console.log('this.props.order.Order');
            console.log(this.props.order.Order);
        });
        this.props.fetchRestaurantName().then(response => {
            // console.log('this.props.objtax in resp');
            // console.log(this.props.objtax);
            if (this._isMounted) {
                this.setState({restaurant_name:this.props.objRestaurantName.restaurant_name});
            }
            // console.log(this.props.objtax);
        });
        // document.getElementById("focustext").onfocus = function () { window.close(); }
        this.handleScriptLoad = this.handleScriptLoad.bind(this);
    }
    printGoogleCloudPage = async () => {
        if(this.gadget !== undefined){
            // console.log('this.gadget')
            // console.log('this.gadget')
            // console.log(this.gadget)
            this.gadget.setPrintDocument("url", "Test Page", `https://jubzi.com/admin/orders/view-order-receipt-g-print?id=${this.props.match.params.order_id}`)
            this.gadget.openPrintDialog();

        }
    }
    printPage = async () => {
        window.print();
        setInterval(function () {
            document.getElementById("focustext").focus();
        }, 50);
        document.getElementById("focustext").onfocus = function () { window.close(); }
    }
    async handleScriptLoad() {
        // Declare Options For Autocomplete
        /* var options = {
          types: ['(cities)'],
        }; */

        // Initialize Google Autocomplete
        /*global google*/ // To disable any eslint 'google not defined' errors
        const isConnected = await checkInternetConnection();
        if (isConnected) {
            // this.autocomplete = new google.maps.places.Autocomplete(
            //     document.getElementById('autocomplete'),
            //     //   options,
            // );


            // // Fire Event when a suggested name is selected
            // this.autocomplete.addListener('place_changed', this.handlePlaceSelect);

            // this.DistanceMatrixServiceG = new google.maps.DistanceMatrixService; 
            /*global cloudprint*/ // To disable any eslint 'cloudprint not defined' errors
            this.gadget = new cloudprint.Gadget();
            this.gadget.setPrintButton(
                cloudprint.Gadget.createDefaultPrintButton("print_button_container")); // div id to contain the button
            // this.gadget.setPrintDocument("google.drive", "Test Page document title", '11aSLL0hi1KZwv62lN7wYGbcPX51CVZlA');
            // this.gadget.setPrintDocument("url", "Test Page document title", 'https://honeyballz.jubzi.com/print-pos-order/5d3baf5856502e796b538f06');
        
        }
        // this.calculateDistance();

    }
    
    render() {
        
        const order = this.props.order
        const objOrder = this.props.order.Order;
        console.log('objOrder')
        console.log('objOrder')
        console.log(objOrder)
        if (Object.keys(order).length === 0 || objOrder === undefined) {
            return (
                <LoadingSpinner subClass={'mt10 mb10 text-center'} />
            )
        }
        if (objOrder !== undefined && Object.keys(objOrder).length === 0) {
            return (
                <div className="col-12 no_result">
                   <p className="text-center"> Order not found.</p>
                </div>
            )
        }
        return (
            <Fragment>
                <input id="focustext" type="text" />
                <div className='text-center'>
                    <Button color="warning" className="btnNotPadModalButton no-print" onClick={() => this.printGoogleCloudPage()}>Google Cloud Print</Button>
                </div>
                <div className='text-center'>
                    <Button color="warning" className="btnNotPadModalButton no-print" onClick={() => this.printPage()}>Print</Button>
                </div>
                <Script
                    url="https://www.google.com/cloudprint/client/cpgadget.js"
                    onLoad={this.handleScriptLoad}
                />
                <div className="OrderReceiptPrint" id="invoice-POS">
                    <center id="top">
                        <div className="info">
                            <h2 className="resturantName">{this.state.restaurant_name}</h2>
                        </div>
                        <div id="mid">
                            <div className="info">
                                <h2 className="OrderNo">Ticket # {objOrder.ticket_no}</h2>
                                {
                                    Object.keys(objOrder.objCustomer).length > 0 && objOrder.objCustomer.phone_no ?
                                    <Fragment>
                                        <p className="CustomerPhoneNo">  {objOrder.objCustomer.phone_no} </p>
                                    </Fragment>
                                    :null
                                }
                                {
                                    Object.keys(objOrder.objCustomer).length > 0 && objOrder.objCustomer.address ?
                                    <Fragment>
                                            <p > {objOrder.objCustomer.address} </p>
                                    </Fragment>
                                    :null
                                }
                                {
                                    objOrder.delivery_type === 'delivery' && Object.keys(objOrder.objCustomer).length > 0  && objOrder.objCustomer.appartment_unit_number !== '' ?
                                    <p>{objOrder.appartment_unit_number}</p>
                                    : null
                                }
                                {
                                    (Object.keys(objOrder.objCustomer).length > 0 && (objOrder.objCustomer.notes && objOrder.objCustomer.notes !== '')) ?
                                        <Fragment><p className="OrderNotes">{objOrder.objCustomer.notes}</p></Fragment>
                                        : null
                                }
                                <PosOrderPrintRenderSelectedDeliveryType
                                    selectedOrderType={objOrder.delivery_type}
                                />
                                {
                                    (objOrder.objCustomer !== undefined && objOrder.objCustomer !== null) && Object.keys(objOrder.objCustomer).length > 0 && objOrder.objCustomer.fullname ?
                                        <Fragment>
                                            {/* {(objOrder.objCustomer.phone_no)  ? <br /> : null} */}
                                            <p className="CustomerName"> {objOrder.objCustomer.fullname} </p>
                                        </Fragment>
                                        : null
                                }
                                <p className="OrderCompletionTime"> {formatExistingOrderDateTimeOnly(objOrder.order_completion_datetime)}</p>
                            </div>
                        </div>
                        <div id="bot">
                            <div id="table">
                                <table>
                                    <colgroup>
                                        <col width="85%" />
                                        <col width="15%" />
                                    </colgroup>
                                    <tbody>
                                        <OrderItemPosPrint
                                            {...objOrder}
                                        />  
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </center>
                    {/* <div id="print_button_container"></div> */}
                </div>
                
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        order : state.order,
        objRestaurantName : state.settings.objRestaurantName,
    };
}

// export default Home
export default connect(mapStateToProps, {fetchOrderByOId,fetchRestaurantName})(withRouter(PrintPosOrder));
