import React,{Fragment,Component} from 'react';
import printImgPng from '../../../../../../assests/img/btn-icons/print-img.png';
import noKitchenImgPng from '../../../../../../assests/img/btn-icons/no-kitchen-img.png';
import cancelImgPng from '../../../../../../assests/img/btn-icons/cancel-img.png';
import checkMarkImgPng from '../../../../../../assests/img/btn-icons/check-mark-img.png';
import lineImgPng from '../../../../../../assests/img/btn-icons/line-img.png';

// import { Button } from 'reactstrap';

class DoneModalBody extends Component {
    
    // const DoneModalBody = (props)  => {
    render() {
        // console.log('this.props.isShowLoader in mod');
        // console.log('this.props.isShowLoader in mod');
        // console.log(this.props.isShowLoader);
        return (
            <Fragment>
                {
                    this.props.hideButtons === false ?
                        <div className="row DoneModalBtns">
                            <div className="col">
                                <button
                                        ref={this.props.btnPrint} onClick={this.props.handelOnClickPrint} className="btn btn-light-danger text-white p-2 w-100 h-100 mb-3 d-flex align-items-center justify-content-center" >
                                        <div>
                                            <p>
                                                <img src={printImgPng} alt="icons" className="mw-100 mb-2" />
                                            </p>
                                            <p>
                                                Print
                                            </p>
                                        </div>
                                </button>
                            </div>
                            {
                                this.props.existing_order_id === '' && this.props.isShowLoader === false  ?
                                    <div className="col">
                                        <button ref={this.props.btnNoKitchen} onClick={this.props.handelOnClickNoKitchen}
                                                className="btn btn-light-danger text-white p-2 w-100 h-100 mb-3 d-flex align-items-center justify-content-center" >
                                            <img src={lineImgPng} alt="icons" className="mw-100 mb-2 lineImg" />
                                                <div>
                                                    <p>
                                                        <img src={noKitchenImgPng} alt="icons" className="mw-100 mb-2" />
                                                    </p>
                                                    <p>
                                                        No Kitchen
                                                    </p>
                                                </div>
                                        </button>
                                    </div>
                                : null
                            }
                            <div className="col">
                                <button
                                    ref={this.props.btnDone} onClick={()=> this.props.submitDoneOrder()}    className="btn btn-light-danger text-white p-2 w-100 h-100 mb-3 d-flex align-items-center justify-content-center" >
                                    <img src={lineImgPng} alt="icons" className="mw-100 mb-2 lineImg" />
                                        <div>
                                            <p>
                                                <img src={checkMarkImgPng} alt="icons" className="mw-100 mb-2 pl-18" />
                                            </p>
                                            <p>
                                                Done
                                            </p>
                                        </div>
                                </button>
                            </div>
                            <div className="col">
                                <button
                                    ref={this.props.btnCancel} onClick={this.props.toggle}    className="btn btn-light-danger text-white p-2 w-100 h-100 mb-3 d-flex align-items-center justify-content-center" >
                                    <img src={lineImgPng} alt="icons" className="mw-100 mb-2 lineImg" />
                                        <div>
                                            <p>
                                                <img src={cancelImgPng} alt="icons" className="mw-100 mb-2" />
                                            </p>
                                            <p>
                                                Cancel
                                            </p>
                                        </div>
                                </button>
                            </div>
                        </div>
                    : null
                }
                {/* {
                    this.props.hideButtons === false ?
                        <div className="text-center">
                            {
                                this.props.existing_order_id === '' && this.props.isShowLoader === false  ?
                                    <Fragment>
                                        <button ref={this.props.btnNoKitchen} onClick={this.props.handelOnClickNoKitchen} className="btnNotPadModalButton btn btn-warning" type="button">No Kitchen</button>
                                    </Fragment>
                                : null
                            }
                            <button ref={this.props.btnPrint} onClick={this.props.handelOnClickPrint} className="btnNotPadModalButton btn btn-warning" type="button">Print</button>
                            <button ref={this.props.btnDone} onClick={()=> this.props.submitDoneOrder()} className="btnNotPadModalButton btn btn-warning" type="button">Done</button>
                            <button ref={this.props.btnCancel} onClick={this.props.toggle} className="btnNotPadModalButton btn btn-warning" type="button">Cancel</button>
                        </div>
                   : null
                } */}
            </Fragment>
        );
    }
// }
}

export default DoneModalBody;