import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../../../utils/LoadingSpinner';

class SauceIngredientItem extends Component {

    state = {
        loading: false,
    }

    render() {
        if(this.state.loading){
            return (
                <LoadingSpinner />
            )
        }
        // we are extracting the addIngredientToMenuItemCart from the this.props and assigning the reaming object keys to the props variable
        const {addIngredientToMenuItemCart,...props} = this.props;
        // const props = this.props;
        // console.log('props in Sau')
        // console.log(props)
        return (
            <button className={`btn btn-warning w-100 py-2 mb-3 d-inline-flex align-items-center `} onClick={()=>addIngredientToMenuItemCart(props,'sauces')}>
                {/* <button className={`btn btn-danger w-100 py-2 mb-3 d-inline-flex align-items-center ${this.props.selectedMenuCategoryItemId ===  props._id ? 'btnActiveMenuCategoryItem' : ''} `} onClick={()=>this.props.handleOnClickMenuCategoryItem(props)}>
                    {props.name}
                </button> */}
                {props.name}
                {/* {props.name} */}
            </button>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps,{})(SauceIngredientItem);