import React,{Fragment} from 'react';
import OrderItemPosKitchenBlock from './OrderItemPosKitchenBlock';
import KitchenOrderMenuOptionsModal from './KitchenOrderMenuOptionsModal/KitchenOrderMenuOptionsModal';
import LoadingSpinner from '../../../utils/LoadingSpinner';
import moment from 'moment';
const KitchenOrders = (props) => {
    // sort them in ascending order
    // let Orders = props.Orders.sort((a, b) => a.order_completion_datetime.localeCompare(b.order_completion_datetime));
    let Orders = props.Orders.sort((a, b) => {
        // You can check if your value is null then you can assign blank using pipe.
        a.order_completion_datetime = a.order_completion_datetime || '';
        b.order_completion_datetime = b.order_completion_datetime || '';
        // console.log('a')
        // console.log(a)
        // console.log('b')
        // console.log(b)
        return a.order_completion_datetime.localeCompare(b.order_completion_datetime)
    });
    Orders.map(o => {
        // let currentTime = moment().format("hh:mm:ss a");
        // let endTime = moment(o.order_completion_datetime).format("hh:mm:ss a");

        // // currentTime = moment(currentTime, "hh:mm:ss a");
        // // endTime = moment(endTime, "hh:mm:ss a");
        // console.log('currentTime')
        // console.log(currentTime)
        // console.log('endTime')
        // console.log(endTime)
        // // let mins = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(currentTime, "HH:mm:ss"))).format("mm")
        // let mins = moment.utc(moment(currentTime, "HH:mm:ss").diff(moment(endTime, "HH:mm:ss"))).format("mm")
        // // let TotalHours = endTime.diff(currentTime, 'hours') + " Hrs and " + mins + " Mns";
        // console.log('mins')
        // console.log(mins)

        // Example
        // var start = moment();
        // console.log(start.format("hh:mm a"))
        // var  end = moment('2019-08-06T15:09:22.529Z');
        // console.log(end.format("hh:mm a"))
        // end.diff(start, 'minutes') // 1
        let start = moment();
        // console.log(start.format("hh:mm a"))
        let  end = moment(o.order_completion_datetime);
        // console.log(end.format("hh:mm a"))
        let mins = end.diff(start, 'minutes') 
        // console.log('mins')
        // console.log(mins)
        if((mins === 0 || mins < 0 || mins <= 5) && (o.kitchen_status !== 'done') && (o.delivery_type === 'pickup' || o.delivery_type === 'delivery')){
            console.log('i im last if')
            o.isLate = true;
            if(o.status === 'cash' || o.status === 'debit_card' || o.status === 'credit_card' || o.status === 'gift_card'){
                o.isLateClass = 'orderLateCashedThrough';
            }else{
                o.isLateClass = 'orderLate';
            }
        }else{
            o.isLate = false;
            o.isLateClass = '';
        }
        // console.log('o.isLate')
        // console.log(o.isLate)
        console.log('o.isLateClass')
        console.log(o.isLateClass)
        // console.log("endTime.diff(currentTime, 'hours')")
        // console.log(endTime.diff(currentTime, 'hours'))
        // if (selected.includes(o.id)) {
        //   o.checked = true;
        // }
        return o;
    });
    return (
        <Fragment>
            <div className="row">
                <OrderItemPosKitchenBlock
                    list={Orders}
                    // list={props.Orders}
                    comboItemColor={props.comboItemColor}
                    onClickHandleKitchenOrder={props.onClickHandleKitchenOrder}
                    // handelOnClickPosOrderPrint={props.handelOnClickPosOrderPrint}
                />
                <KitchenOrderMenuOptionsModal 
                    handleOnChangeKitchenOrderMenuOptionsModalToggle={props.handleOnChangeKitchenOrderMenuOptionsModalToggle}
                    showKitchenOrderMenuOptionsModal={props.showKitchenOrderMenuOptionsModal}
                    handleOnClickDoneOrder={props.handleOnClickDoneOrder}
                    handleOnClickClearOrder={props.handleOnClickClearOrder}
                />
            </div>
            {
                props.isloadingPosKitchenOrders ? <LoadingSpinner subClass={'mt10 mb10'} /> :null
            }
            {
                props.total_pos_kitchen_orders > 0 && props.total_pos_kitchen_orders > props.Orders.length ?
                    <div>
                        <div className="text-center mt20 pb20">
                            <button className="btn btn-light-success text-white btnPrint btnLoadMore btnLoadMoreKitchenOrders"  onClick={()=> props.onClickHandleLoadMorePosKitchenOrderItems()}>Load More Orders</button>
                        </div> 
                    </div>
                :null
            }
           {/*  <div className="row">
                <div className="col-3">
                    <div className="food-cart-head kitchen-item-head text-white">
                        <div className="food-info-head">
                            <div className="row">
                                <div className="col-6">
                                    <p>pickup</p>
                                </div>
                                <div className="col-6 text-right">
                                    <p>3:30</p>
                                </div>
                                <div className="col-12">
                                    <p>
                                        tim miller
                                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="food-cart-head kitchen-item-body text-white">
                        <ul className="food-info-list">
                            <li>
                                <p className="text-warning">
                                    Beef/Lamb Gyro
                                            </p>
                                <ul className="food-info-sublist">
                                    <li>
                                        no onions
                                                </li>
                                    <li>
                                        no tomatoes
                                                </li>
                                    <li className="text-gold pt-5 pb-5">
                                        with fries
                                                </li>
                                    <ul className="pl-2">
                                        <li>
                                            well done
                                                    </li>
                                        <li className="text-success pt-5 pb-5">
                                            with salad
                                                    </li>
                                        <li>
                                            no tomoatoes
                                                    </li>
                                        <li>
                                            no cucumbes
                                                    </li>
                                    </ul>
                                </ul>
                            </li>
                            <li>
                                <p className="text-warning">
                                    hamburger
                                            </p>
                                <ul className="food-info-sublist">
                                    <li>
                                        plain
                                                </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-3">
                    <div className="food-cart-head kitchen-item-head text-white bg-danger">
                        <div className="food-info-head">
                            <div className="row">
                                <div className="col-6">
                                    <p>pickup</p>
                                </div>
                                <div className="col-6 text-right">
                                    <p>3:30</p>
                                </div>
                                <div className="col-12">
                                    <p>
                                        tim miller
                                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="food-cart-head kitchen-item-body text-white">
                        <ul className="food-info-list">
                            <li>
                                <p className="text-warning">
                                    shiskbabob
                                            </p>
                                <ul className="food-info-sublist">
                                    <li>
                                        no tomatoes
                                                </li>
                                    <li className="text-gold">
                                        with fries
                                                </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-3">
                    <div className="food-cart-head kitchen-item-head text-white">
                        <div className="food-info-head">

                        </div>
                    </div>
                    <div className="food-cart-head kitchen-item-body text-white">

                    </div>
                </div>
                <div className="col-3">
                    <div className="food-cart-head kitchen-item-head text-white">
                        <div className="food-info-head">

                        </div>
                    </div>
                    <div className="food-cart-head kitchen-item-body text-white">

                    </div>
                </div>
                <div className="col-3">
                    <div className="food-cart-head kitchen-item-head text-white">
                        <div className="food-info-head">

                        </div>
                    </div>
                    <div className="food-cart-head kitchen-item-body text-white">

                    </div>
                </div>
                <div className="col-3">
                    <div className="food-cart-head kitchen-item-head text-white">
                        <div className="food-info-head">

                        </div>
                    </div>
                    <div className="food-cart-head kitchen-item-body text-white">

                    </div>
                </div>
                <div className="col-3">
                    <div className="food-cart-head kitchen-item-head text-white">
                        <div className="food-info-head">

                        </div>
                    </div>
                    <div className="food-cart-head kitchen-item-body text-white">

                    </div>
                </div>
                <div className="col-3">
                    <div className="food-cart-head kitchen-item-head text-white">
                        <div className="food-info-head">

                        </div>
                    </div>
                    <div className="food-cart-head kitchen-item-body text-white">

                    </div>
                </div>

                        </div> */}
        </Fragment>
    );
};

export default KitchenOrders;