import React,{Fragment} from 'react';
import loaderImage from '../images/ajax-loader.svg'
const LoadingCircle = props => {
    // console.log('props')
    // console.log(props)
    const renderLoadingCircle = () => (
        <div id="loading" >
            <img id="loader-image" src={loaderImage} alt="loading"/>
        </div>
    )
    return (
        <Fragment>
            { props.isShowLoader ? renderLoadingCircle() : null }
            
        </Fragment>
    )
}

export default LoadingCircle;