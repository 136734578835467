import React,{Fragment} from 'react';
import { Button } from 'reactstrap';
import checkImgPng from '../../../../../assests/img/btn-icons/kitchen/kitchen-check-icon.png';
import closeImgPng from '../../../../../assests/img/btn-icons/kitchen/kitchen-close-icon.png';
import trashImgPng from '../../../../../assests/img/btn-icons/kitchen/kitchen-trash-icon.png';
const KitchenOrderMenuOptionsModalBody = (props)  => {
    // Made it= Done
    // Remove it= Clear
    // Nevermind= Cancel

    return (
        <Fragment>
            <div className="row kitchenOptionsModalBtns">
                <div className="col">
                    <button
                            onClick={()=> props.handleOnClickDoneOrder()} className="btn btn-light-danger btnMadeIt text-white p-2 w-100 h-100 mb-3 d-flex align-items-center justify-content-center" >
                            <div>
                                <p>
                                    <img src={checkImgPng} alt="icons" className="mw-100 mb-2" />
                                </p>
                                <p>
                                    Made It
                                </p>
                            </div>
                    </button>
                </div>
                <div className="col">
                    <button 
                            onClick={()=> props.handleOnClickClearOrder()} className="btn btn-light-danger btnTrash text-white p-2 w-100 h-100 mb-3 d-flex align-items-center justify-content-center" >
                            <div>
                                <p>
                                    <img src={trashImgPng} alt="icons" className="mw-100 mb-2" />
                                </p>
                                <p>
                                    Remove It
                                </p>
                            </div>
                    </button>
                </div>
                <div className="col">
                    <button
                        onClick={props.toggle} className="btn btn-light-danger btnNeverMind text-white p-2 w-100 h-100 mb-3 d-flex align-items-center justify-content-center" >
                            <div>
                                <p>
                                    <img src={closeImgPng} alt="icons" className="mw-100 mb-2" />
                                </p>
                                <p>
                                    Nevermind
                                </p>
                            </div>
                    </button>
                </div>
            </div>
            {/* <form>
                <div className="text-center">
                    <Button color="warning" className="btnNotPadModalButton" onClick={()=> props.handleOnClickClearOrder()} >Clear</Button>
                    <Button color="warning" className="btnNotPadModalButton" onClick={()=> props.handleOnClickDoneOrder()} >Done</Button>
                    <Button color="warning" className="btnNotPadModalButton" onClick={props.toggle}>Cancel</Button>
                </div>
            </form> */}
        </Fragment>
    );
}

export default KitchenOrderMenuOptionsModalBody;