import React,{Fragment} from 'react';
import OrderItemBlockMistake from './OrderItemBlockMistake'; 

const LoadMoreMistakeExistingOrderItems = (props) => {
    return (
        <Fragment>
            <OrderItemBlockMistake
                list={props.Orders}
                handelOnClickMistakeExistingOrder={props.handelOnClickMistakeExistingOrder}
            />
            {
                props.total_mistake_existing_orders > 0 && props.total_mistake_existing_orders > props.Orders.length ?
                    <tr className="load_more_container text-center">
                        <td colSpan="4">
                            <div className="cursor-pointer"  onClick={()=> props.onClickHandleLoadMoreMistakeExistingOrderItems()}><i className="fas fa-angle-down show-more"></i></div>
                        </td>
                        {/* <span onClick={()=> props.loadMore()}>
                            Load More
                        </span> */}
                    </tr>
                :null
            }
            

    
        </Fragment>
    );
};

export default LoadMoreMistakeExistingOrderItems;