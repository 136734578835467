import React from 'react';
// import {formatExistingOrderDate,formatExistingOrderDateTimeOnly,formatExistingOrderDateTime,isDateMatches} from '../../../utils/general';
import PosKitchenRenderSelectedDeliveryType from './PosKitchenRenderSelectedDeliveryType';
import PosKitchenCartItemBlock from './PosKitchenCartItemBlock';
import {formatExistingOrderDateTimeOnly} from '../../../utils/general';
// import RenderDeliveryType from './RenderDeliveryType';
const OrderItemPosKitchen = (props) => {
    // console.log('props in OrderItemPosKitchen')
    // console.log(props)
    let doneOrderItemStyle = {};
    let notDoneOrderButCashedThroughClass = '';
    let pickUpDelivryTextColor = '';
    if(((props.delivery_type === 'pickup' || props.delivery_type === 'delivery' ) || (props.original_delivery_type === 'pickup' || props.original_delivery_type === 'delivery' )) && (props.kitchen_status === 'done' && (props.status === 'cash' || props.status === 'debit_card' || props.status === 'credit_card' || props.status === 'gift_card'))){
    // if((props.kitchen_status === 'done' && props.status === 'cash')){
        doneOrderItemStyle = {
            backgroundColor: 'green',
        };
        pickUpDelivryTextColor = 'color-white';
    }else if(props.kitchen_status === 'done'){
        doneOrderItemStyle = {
            backgroundColor: 'blue',
        };
        pickUpDelivryTextColor = 'color-white';
    }else if(((props.delivery_type === 'pickup' || props.delivery_type === 'delivery') || (props.original_delivery_type === 'pickup' || props.original_delivery_type === 'delivery' )) && (props.kitchen_status !== 'done' && (props.status === 'cash' || props.status === 'debit_card' || props.status === 'credit_card' || props.status === 'gift_card'))){
    // }else if(props.kitchen_status !== 'done' && props.status === 'cash'){
        
            doneOrderItemStyle = {
                backgroundColor: 'green',
            };
            notDoneOrderButCashedThroughClass = 'notDoneOrderButCashedThroughClass';
            pickUpDelivryTextColor = 'color-white';
        
    }
    return (
        <div className="col-3">
            <div className={`food-cart-head kitchen-item-head text-white ${(props.isLate) ? props.isLateClass : ""} ${notDoneOrderButCashedThroughClass} `}  style={doneOrderItemStyle}>

                <div className={`food-info-head `} >
                    <div className="row">
                        <div className="col-6">
                            <PosKitchenRenderSelectedDeliveryType 
                                selectedOrderType={props.delivery_type}
                                pickUpDelivryTextColor={pickUpDelivryTextColor}
                            />
                        </div>
                        <div className="col-6 text-right">
                            {/* <p>3:30</p> */}
                            <p>{formatExistingOrderDateTimeOnly(props.order_completion_datetime)}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {
                                (props.objCustomer !== undefined && props.objCustomer !== null) && Object.keys(props.objCustomer).length > 0 ?
                                    <p className="CustomerName">{props.objCustomer.fullname}</p>
                                :<p></p>
                            }
                        </div>
                        <div className="col text-right">
                            <p className="ticket_no fs-10">Ticket# {props.ticket_no}</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="food-cart-head kitchen-item-body text-white cursor-pointer" onClick={()=> props.onClickHandleKitchenOrder(props)}>
                <PosKitchenCartItemBlock
                    products={props.order_details}
                    comboItemColor={props.comboItemColor}
                    type="cart"
                />
            </div>

        </div>
    );
}



export default OrderItemPosKitchen;