import React,{Fragment} from 'react';
import WebOrderItemBlock from './WebOrderItemBlock'; 

const LoadMoreWebOrderItems = (props) => {
    return (
        <Fragment>
            <WebOrderItemBlock
                list={props.Orders}
                handelOnClickWebExistingOrder={props.handelOnClickWebExistingOrder}
            />
            {
                props.total_web_existing_orders > 0 && props.total_web_existing_orders > props.Orders.length ?
                    <tr className="load_more_container text-center">
                        <td colSpan="4">
                            <div className="cursor-pointer"  onClick={()=> props.onClickHandleLoadMoreWebExistingOrderItems()}><i className="fas fa-angle-down show-more"></i></div>
                        </td>
                        {/* <span onClick={()=> props.loadMore()}>
                            Load More
                        </span> */}
                    </tr>
                :null
            }
            

    
        </Fragment>
    );
};

export default LoadMoreWebOrderItems;