import React,{Fragment} from 'react';
import OrderReceiptPopupCartItemSubBlock from './OrderReceiptPopupCartItemSubBlock.js' 
import OrderReceiptPopupRenderMenuItemIngredients from './OrderReceiptPopupRenderMenuItemIngredients';
import { paidReceiptTxt } from '../../../../../utils/general.js';
// import { Link } from 'react-router-dom';
const OrderReceiptPopupCartItemBlock = ({
        products,
        objOrder,
    }) => {

    // console.log('products')
    // console.log('products')
    // console.log(products)
    const renderItems = () => (
        products ?
            products.map(product=>{
                if(product.item_type === 'notes'){
                    return <tr className="tabletitle MainItem" key={product.timestamp}>
                                <td className="item">
                                    <h2>{product.notes}</h2>
                                </td>
                                <td className="Rate Price">
                                    <h2>
                                        {
                                            product.is_paid_notes ? <Fragment> ${(parseFloat(product.extra_charges)).toFixed(2) } </Fragment> : null
                                        }
                                    </h2>
                                </td>
                            </tr>
                }else if(product.item_type === 'refunded_item'){
                    
                    return <tr className="tabletitle MainItem" key={product.timestamp}>
                                <td className="item">
                                    <h2>{product.item_type === "refunded_item" ? 'Refund ' : null} 
                                        {
                                            product.refuned_item_type === 'notes' ? <Fragment>  {product.notes} </Fragment> : <Fragment>{product.is_size_selected ? `${product.size_name} ` : null}{product.is_special_modifier_selected ? `${product.special_modifier_name} ` : null}{product.name}</Fragment>
                                        }
                                    </h2>
                                </td>
                                <td className="Rate Price">
                                    <h2>
                                        {
                                            ((product.refuned_item_type !== 'notes' && product.price !== undefined && product.price !== null  && !isNaN(Number(product.price))) || product.is_paid_notes) ? <Fragment>  -${(parseFloat(product.price)).toFixed(2) } </Fragment> : null
                                        }
                                    </h2>
                                </td>
                        </tr>
                }else{
                    return <Fragment key={product.timestamp}>
                                <tr className="tabletitle MainItem" >
                                    <td className="item">
                                        <h2>
                                            
                                            {product.item_type === "refunded_item" ? 'Refund ' : null}{product.is_size_selected ? `${product.size_name} ` : null}{product.is_special_modifier_selected ? `${product.special_modifier_name} ` : null}{product.name}
                                        </h2>
                                    </td>
                                    <td className="Rate Price">
                                        <h2>
                                            {product.item_type === "refunded_item" ? '-' : null}${(parseFloat(product.price)).toFixed(2) }
                                        </h2>
                                    </td> 
                                </tr>
                                {
                                    product.ingredients.length > 0 ? OrderReceiptPopupRenderMenuItemIngredients(product,product.ingredients) : null
                                }
                                <OrderReceiptPopupCartItemSubBlock
                                    mainProduct={product}
                                    products={product.selectedComboItems}
                                    type="cart"
                                /> 
                            </Fragment>

                }
            })

        :null
    )


    return (
        <Fragment>
            {
                ((objOrder.created_from === 'tablet' || objOrder.created_from === 'web') && paidReceiptTxt.includes(objOrder.payment_method) === false ) ?
                    <tr className="txtOrderTabletNotPaid text-center text-capitalize"><td colSpan="2" className="font-weight-bolder">
                        Not Paid
                        {
                            objOrder.created_from === 'web' ? " Mobile"
                            : objOrder.created_from === 'tablet' ? " Kiosk"
                            :null
                        }            
                    </td></tr>
                :null
            }
            {
                ((objOrder.created_from === 'tablet' || objOrder.created_from === 'web') && paidReceiptTxt.includes(objOrder.payment_method)) ?
                    <tr className="txtOrderTabletNotPaid text-center text-capitalize"><td colSpan="2" className="font-weight-bolder">
                        Paid
                        {
                            objOrder.created_from === 'web' ? " Mobile"
                            : objOrder.created_from === 'tablet' ? " Kiosk"
                            :null
                        }
                    </td></tr>
                :null
            }
            
            {renderItems()}
        </Fragment>
    );
};

export default OrderReceiptPopupCartItemBlock;