import {
    FETCH_MENU_ITEM_BY_ID,
    FETCH_MENU_ITEM_COMBOS_BY_MENU_ITEM_ID,
    CLEAR_MENU_ITEM_COMBO_ITEMS
} from '../actions/types';


export default function(state={MenuItemCombos:[],total_menu_item_combos:0},action){
    switch(action.type){
        case FETCH_MENU_ITEM_BY_ID:
           
            return {
                    ...state,
                    menuItem: action.payload.menuItem,
                    // total_menu_items: action.payload.total_menu_items
                }
        case FETCH_MENU_ITEM_COMBOS_BY_MENU_ITEM_ID:
            // console.log('action.payload.total_menu_items')
            // console.log(action.payload.total_menu_items)
            return {
                    ...state,
                    MenuItemCombos: action.payload.MenuItemCombos,
                    total_menu_item_combos: action.payload.total_menu_item_combos
                }
        case CLEAR_MENU_ITEM_COMBO_ITEMS:
            // console.log('action.payload.total_menu_items')
            // console.log(action.payload.total_menu_items)
            return {
                    ...state,
                    MenuItemCombos: action.payload,
                    total_menu_item_combos: 0
                }
        default:
            return state;
                
    }
}