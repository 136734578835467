import axios from 'axios';
import {
    FETCH_MENU_ITEM_COMBOS_BY_MENU_ITEM_ID,
    FETCH_MENU_ITEM_BY_ID_WITH_INGREDIENTS,
    CLEAR_MENU_ITEM_COMBO_ITEMS,
    CLEAR_MENU_ITEM_INGREDIENTS
} from './types';
import { BASE_URL } from '../components/utils/misc';
// import { checkInternetConnection } from '../components/utils/general';
import localForage from 'localforage';
import _ from 'lodash';
export const fetchMenuItemCombosByMenuItemId = (menu_item_id) => async dispatch => {
    // let isConnected = await checkInternetConnection();
    let {MenuItems} = await localForage.getItem('fetch-menu-items')  || {};
    
    if((MenuItems === null || MenuItems === undefined)){
        const res = await axios.get(`${BASE_URL}/api/fetch-menu-item-by-id-with-combo-item-with-sorting?id=${menu_item_id}`);
        dispatch({ type: FETCH_MENU_ITEM_COMBOS_BY_MENU_ITEM_ID, payload: res.data });
    }else{
        // let {MenuItems} = await localForage.getItem('fetch-menu-items');
        // let MenuItems = objMenuItems.MenuItems;
        let objMenuItem = MenuItems.find(m => m._id === menu_item_id);
        // console.log('objMenuItem')
        // console.log(objMenuItem)
        // console.log('menu_item_id')
        // console.log(menu_item_id)
        let MenuItemCombos = MenuItems.filter(m => (m.comboItems !== null && m.comboItems.includes(menu_item_id)));
        // console.log('MenuItemCombos')
        // console.log(MenuItemCombos)
        for (let index = 0; index < MenuItemCombos.length; index++) {
            const element = MenuItemCombos[index];
			let position = objMenuItem.menu_items_combos_order.findIndex(id => id === element._id);
			MenuItemCombos[index].position = position;
		}
        MenuItemCombos = _.sortBy(MenuItemCombos, ['position']);
        // console.log('MenuItemCombos after')
        // console.log(MenuItemCombos)
        let res = {
            total_menu_item_combos: MenuItemCombos.length,
            MenuItemCombos: MenuItemCombos
        }
        dispatch({ type: FETCH_MENU_ITEM_COMBOS_BY_MENU_ITEM_ID, payload:res });
    }  
};

/* export const fetchMenuItemCombosByMenuItemId = (menu_item_id) => async dispatch => {
    let isConnected = await checkInternetConnection();
    let {MenuItems} = await localForage.getItem('fetch-menu-items')  || {};
    if(isConnected && (MenuItems === null || MenuItems === undefined)){
        const res = await axios.get(`${BASE_URL}/api/fetch-menu-item-by-id-with-combo-item-with-sorting?id=${menu_item_id}`);
        dispatch({ type: FETCH_MENU_ITEM_COMBOS_BY_MENU_ITEM_ID, payload: res.data });
    }else{
        // let {MenuItems} = await localForage.getItem('fetch-menu-items');
        // let MenuItems = objMenuItems.MenuItems;
        let objMenuItem = MenuItems.find(m => m._id === menu_item_id);
        // console.log('objMenuItem')
        // console.log(objMenuItem)
        // console.log('menu_item_id')
        // console.log(menu_item_id)
        let MenuItemCombos = MenuItems.filter(m => (m.comboItems !== null && m.comboItems.includes(menu_item_id)));
        // console.log('MenuItemCombos')
        // console.log(MenuItemCombos)
        for (let index = 0; index < MenuItemCombos.length; index++) {
            const element = MenuItemCombos[index];
			let position = objMenuItem.menu_items_combos_order.findIndex(id => id == element._id);
			MenuItemCombos[index].position = position;
		}
        MenuItemCombos = _.sortBy(MenuItemCombos, ['position']);
        // console.log('MenuItemCombos after')
        // console.log(MenuItemCombos)
        let res = {
            total_menu_item_combos: MenuItemCombos.length,
            MenuItemCombos: MenuItemCombos
        }
        dispatch({ type: FETCH_MENU_ITEM_COMBOS_BY_MENU_ITEM_ID, payload:res });
    }  
}; */

export const fetchMenuItemByIdWithIngredients = (menu_item_id,sort_by) => async dispatch => {
    // let isConnected = await checkInternetConnection();
    let {MenuItems} = await localForage.getItem('fetch-menu-items')  || {};
    let {Ingredients} = await localForage.getItem('fetch-ingredients')  || {};
    let {IngredientMenuItems} = await localForage.getItem('fetch-ingredient-menu-items')  || {};
    // console.log('MenuItems')
    // console.log(MenuItems)
    // console.log('MenuIngredients')
    // console.log(MenuIngredients)
    // if(isConnected && (MenuItems === null || MenuItems === undefined) && (MenuIngredients === null || MenuIngredients === undefined)){
    // MenuItems = null;
    if((MenuItems === null || MenuItems === undefined) && (Ingredients === null || Ingredients === undefined) && (IngredientMenuItems === null || IngredientMenuItems === undefined)){
        const res = await axios.get(`${BASE_URL}/api/fetch-menu-item-by-id-with-ingredients-with-sorting?id=${menu_item_id}&sort_by=${sort_by}`);
        dispatch({ type: FETCH_MENU_ITEM_BY_ID_WITH_INGREDIENTS, payload: res.data });
    }else{
        let objMenuItem = MenuItems.find(m => m._id === menu_item_id);
        
        let objIngredientMenuItems = IngredientMenuItems.filter(m => (m.menuItem === menu_item_id));
        // console.log('objIngredientMenuItems')
        // console.log(objIngredientMenuItems)
        for (let index = 0; index < objIngredientMenuItems.length; index++) {
            const element = objIngredientMenuItems[index];
            // console.log('element')
            // console.log(element)
            // let objIngredient = element.ingredientsMenuItems.find(ing => ing.menuItem === menu_item_id);
            // console.log('objIngredient')
            // console.log(objIngredient)
            // objIngredientMenuItems[index].ingredientType = objIngredient.ingredientType;
            // objIngredientMenuItems[index].price = objIngredient.price;
            objIngredientMenuItems[index].name = element.ingredient.name;
		    objIngredientMenuItems[index].ingredient_main_type = element.ingredient.type;
		    objIngredientMenuItems[index].ingredient_id = element.ingredient._id;
            if(element.ingredientType === 'included'){
                objIngredientMenuItems[index].sort = 1;
            }else if (element.ingredientType === 'optional_free'){
                objIngredientMenuItems[index].sort = 2;
            }else if (element.ingredientType === 'extras_charged'){
                objIngredientMenuItems[index].sort = 3;
            }
            delete objIngredientMenuItems[index].ingredient;
        }

        if(sort_by === 'no'){
            // only included
            // objIngredientMenuItems = objIngredientMenuItems.filter(ing => (ing.ingredientType == 'included'));
            objIngredientMenuItems = objIngredientMenuItems.filter(ing => (ing.ingredientType === 'included' || ing.ingredientType === 'both'));

            // console.log('objIngredientMenuItems')
            // console.log(objIngredientMenuItems)
            if(objMenuItem.menu_item_ingredient_no_order && objMenuItem.menu_item_ingredient_no_order.length > 0){
                for (let index = 0; index < objIngredientMenuItems.length; index++) {
                    const element = objIngredientMenuItems[index];
                    let position = objMenuItem.menu_item_ingredient_no_order.findIndex(id => id === element._id);
                    // console.log('position in no')
                    // console.log(position)
                    objIngredientMenuItems[index].position = position;
                }
            }
        }else if(sort_by === 'extra'){
            // only included
            objIngredientMenuItems = objIngredientMenuItems.filter(ing => (ing.ingredientType === 'included' || ing.ingredientType === 'both'));
            // objIngredientMenuItems = objIngredientMenuItems.filter(ing => (ing.ingredientType === 'both'));
            if(objMenuItem.menu_item_ingredient_extra_order && objMenuItem.menu_item_ingredient_extra_order.length > 0){
                for (let index = 0; index < objIngredientMenuItems.length; index++) {
                    const element = objIngredientMenuItems[index];
                    let position = objMenuItem.menu_item_ingredient_extra_order.findIndex(id => id === element._id);
                    // console.log('position in no')
                    // console.log(position)
                    objIngredientMenuItems[index].position = position;
                }
            }
        }else if(sort_by === 'add'){
            // optional free and extra charged
            // objIngredientMenuItems = objIngredientMenuItems.filter(ing => (ing.ingredientType == 'optional_free' || ing.ingredientType == 'extras_charged'));
            objIngredientMenuItems = objIngredientMenuItems.filter(ing => (ing.ingredientType === 'optional_free' || ing.ingredientType === 'extras_charged' ));
            if(objMenuItem.menu_item_ingredient_add_order && objMenuItem.menu_item_ingredient_add_order.length > 0){
                for (let index = 0; index < objIngredientMenuItems.length; index++) {
                    const element = objIngredientMenuItems[index];
                    let position = objMenuItem.menu_item_ingredient_add_order.findIndex(id => id === element._id);
                    // console.log('position in no')
                    // console.log(position)
                    objIngredientMenuItems[index].position = position;
                }
            }
        }else if(sort_by === 'light'){
            // included and optional free  
            objIngredientMenuItems = objIngredientMenuItems.filter(ing => (ing.ingredientType === 'included'  || ing.ingredientType === 'both'));
            // objIngredientMenuItems = objIngredientMenuItems.filter(ing => (ing.ingredientType === 'included' || ing.ingredientType === 'optional_free'));
            if(objMenuItem.menu_item_ingredient_light_order && objMenuItem.menu_item_ingredient_light_order.length > 0){
                for (let index = 0; index < objIngredientMenuItems.length; index++) {
                    const element = objIngredientMenuItems[index];
                    let position = objMenuItem.menu_item_ingredient_light_order.findIndex(id => id === element._id);
                    // console.log('position in no')
                    // console.log(position)
                    objIngredientMenuItems[index].position = position;
                }
            }
        }else if(sort_by === 'only'){
            // included,optional free and extra charged
            objIngredientMenuItems = objIngredientMenuItems.filter(ing => (ing.ingredientType === 'included' || ing.ingredientType === 'optional_free' || ing.ingredientType === 'extras_charged' || ing.ingredientType === 'both'));
            // objIngredientMenuItems = objIngredientMenuItems.filter(ing => (ing.ingredientType === 'included' || ing.ingredientType === 'optional_free' || ing.ingredientType === 'extras_charged'));
            if(objMenuItem.menu_item_ingredient_only_order && objMenuItem.menu_item_ingredient_only_order.length > 0){
                for (let index = 0; index < objIngredientMenuItems.length; index++) {
                    const element = objIngredientMenuItems[index];
                    let position = objMenuItem.menu_item_ingredient_only_order.findIndex(id => id === element._id);
                    // console.log('position in no')
                    // console.log(position)
                    objIngredientMenuItems[index].position = position;
                }
            }
        }else if(sort_by === 'well_done'){
            // included,optional free and extra charged
            objIngredientMenuItems = objIngredientMenuItems.filter(ing => (ing.ingredientType === 'included' || ing.ingredientType === 'optional_free' || ing.ingredientType === 'extras_charged'));
            if(objMenuItem.menu_item_ingredient_well_done_order && objMenuItem.menu_item_ingredient_well_done_order.length > 0){
                for (let index = 0; index < objIngredientMenuItems.length; index++) {
                    const element = objIngredientMenuItems[index];
                    let position = objMenuItem.menu_item_ingredient_well_done_order.findIndex(id => id === element._id);
                    // console.log('position in no')
                    // console.log(position)
                    objIngredientMenuItems[index].position = position;
                }
            }
        } else if (sort_by === 'put_on_side') {
            // only included
            // objIngredientMenuItems = objIngredientMenuItems.filter(ing => (ing.ingredientType == 'included'));
            objIngredientMenuItems = objIngredientMenuItems.filter(ing => (ing.ingredientType === 'included' || ing.ingredientType === 'both'));
    
            // console.log('objIngredientMenuItems')
            // console.log(objIngredientMenuItems)
            if (objMenuItem.menu_item_ingredient_put_on_side_order && objMenuItem.menu_item_ingredient_put_on_side_order.length > 0) {
                for (let index = 0; index < objIngredientMenuItems.length; index++) {
                    const element = objIngredientMenuItems[index];
                    let position = objMenuItem.menu_item_ingredient_put_on_side_order.findIndex(id => id === element._id);
                    // console.log('position in no')
                    // console.log(position)
                    objIngredientMenuItems[index].position = position;
                }
            }
        }
        
        // console.log('objIngredientMenuItems before')
        // console.log(objIngredientMenuItems)
        objIngredientMenuItems = _.sortBy(objIngredientMenuItems, ['position']);
        // console.log('objIngredientMenuItems after sorting')
        // console.log(objIngredientMenuItems)
        // let objMenuItem1 = objMenuItem.toObject();
        objMenuItem["Ingredients"] = objIngredientMenuItems;
        
        let res = {
            menuItem: objMenuItem
        }
        dispatch({ type: FETCH_MENU_ITEM_BY_ID_WITH_INGREDIENTS, payload:res });
    }
};

export const clearMenuItemComboItems = () => async dispatch => {
    dispatch({ type: CLEAR_MENU_ITEM_COMBO_ITEMS, payload: [] });
};

export const clearMenuItemIngredients = () => async dispatch => {
    dispatch({ type: CLEAR_MENU_ITEM_INGREDIENTS, payload: {} });
};