import React,{Fragment} from 'react';
import MenuItemSize from './MenuItemSize'
const MenuItemSizes = (props) => {

    // console.log('props in MenuItemSizes')
    // console.log(props)
    const Sizes = props.Sizes;
    const menuCategoryItem = props.menuCategoryItem;
    const renderMenuItemsSizes = (menuCategoryItem,Sizes) => (
        // Sizes ? 
            // Sizes.slice(0,props.limitMenuItems).map((card, index)=>(
                <MenuItemSize
                    // key={index}
                    // {...card}
                    Sizes={Sizes}
                    handleOnClickMenuCategoryMenuItemSize={props.handleOnClickMenuCategoryMenuItemSize}
                    selectedMenuCategoryItemId={props.selectedMenuCategoryItemId}
                    menuCategoryItem={menuCategoryItem}
                />
            // ))
        // :null
    )
    return (
            <Fragment>
                {Sizes ?
                    Sizes.length === 0 ?
                        <div className="no_result">
                            Sorry, no results
                        </div>
                    :null
                :null}
                { renderMenuItemsSizes(menuCategoryItem,Sizes)}
                
            </Fragment>     
    );
};

export default MenuItemSizes;