import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import CashModalBody from './CashModalBody';
class CashModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            discount: '',
            hideButtons:false,
            // modal: props.showCashModal

        };
    
        this.toggle = this.toggle.bind(this);
        this.btnNoKitchen = React.createRef();
        this.btnPrint = React.createRef();
        this.btnDone = React.createRef();
        this.btnExit = React.createRef();
    }
    
    
    toggle() {
        
        this.props.handleOnChangeCashModalToggle(!this.props.showCashModal,false)
    }
    ModalOpened = () => {
        // console.log('opened')
        // console.log('opened')
        // console.log('opened')
        // console.log('opened')
        this.setState({hideButtons:false});
    }
     
    customToggle = async (click_type) => { 
        if (click_type === 'no_kitchen'){
            this.props.handelOnClickNoKitchen();
        } else if (click_type === 'print'){
            this.btnPrint.current.disabled = true;
            this.setState({hideButtons:true},()=>{
                this.props.handelOnClickPrint();
                this.props.handleOnChangeCashModalToggle(!this.props.showCashModal,true)
            });
        } else if (click_type === 'exist') {
            this.btnExit.current.disabled = true;
            this.setState({hideButtons:true},()=>{
                this.props.submitDoneOrder();
                this.props.handleOnChangeCashModalToggle(!this.props.showCashModal,true)
            })
        }
    }
    
    render() {
        
        // console.log('this.props.showCashModal in modal')
        // console.log(this.props.showCashModal)
        
        return (
            <Modal centered={true} isOpen={this.props.showCashModal} onOpened={this.ModalOpened} toggle={this.toggle} className={this.props.className} >
                {/* <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="lg"> */}
                    {/* <ModalHeader  >Select your option</ModalHeader> */}
                    <ModalBody className="CashModalBody">
                        <CashModalBody 
                            toggle={this.toggle}
                            changeAmount={this.props.changeAmount}
                            calculateChangeAmount={this.props.calculateChangeAmount}
                            handelOnClickChanegModalPrint={this.props.handelOnClickChanegModalPrint}
                            handelOnClickNoKitchen={this.props.handelOnClickNoKitchen}
                            submitDoneOrder={this.props.submitDoneOrder}
                            handelOnClickPrint={this.props.handelOnClickPrint} 
                            existing_order_id={this.props.existing_order_id}  
                            customToggle={this.customToggle}
                            cart={this.props.cart} 
                            orderResponse={this.props.orderResponse} 
                            btnNoKitchen={this.btnNoKitchen} 
                            btnPrint={this.btnPrint} 
                            btnDone={this.btnDone} 
                            btnExit={this.btnExit}
                            isShowLoader={this.props.isShowLoader} 
                            hideButtons={this.state.hideButtons} 
                        />
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.applyDiscount}>Done</Button>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter> */}
                </Modal>
        );
    }
};

export default CashModal;