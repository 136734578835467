import React from 'react';
// import trashRedPng from '../../../../../common/images/icons/trash-red.png';
import {notesCommon} from '../../../../../utils/general'
const RenderMenuItemIngredients = (product, ingredients, removeIngredientFromCartMenuItem, selectedOnReceiptMenuItemId, selectedOnReceiptMenuItemTimeStamp, selectedOnReceiptItemType, selectedOnReceiptMenuItemIngredientId, selectedOnReceiptMenuItemIngredient, handleOnClickSelectReceiptMenuItemIngredient,confirmAlert) => {
    // console.log('product')
    // console.log(product.name)
    // console.log('ingredients')
    // console.log(ingredients)
    // console.log(ingredients.length) 
    // ingredients = ingredients.filter(i => i.is_checked === true);
    // console.log('ingredients after')
    // console.log(ingredients)
    const handleOnClickMenuItemIngredient = (product,ingredient,type) =>{
        handleOnClickSelectReceiptMenuItemIngredient(product,ingredient,type);
        /* let buttons = [];
        const objButton = {
            label: 'SELECT ITEM',
            onClick: () => {
                handleOnClickSelectReceiptMenuItemIngredient(product,ingredient);
            }
        }
        buttons.push(objButton);
        
        const objDeleteButton =    {
                label: 'DELETE ITEM',
                onClick: () => {
                    removeIngredientFromCartMenuItem(product,ingredient)
                }
            }
        buttons.push(objDeleteButton);
        confirmAlert({
            // title: 'Confirm to submit',
            // message: 'Are you sure to do this.',
            buttons: buttons
        }); */
    }
    if(ingredients.length > 0){
        // let rowLen =ingredients.length;
        // return images[0].url
        let ret = ingredients.map((ingredient,index) => {
            const trashButton = null;
            // const trashButton = <img src={trashRedPng} alt="trash" className="mw-100 cursor-pointer trash-icon"  onClick={()=> removeIngredientFromCartMenuItem(product,ingredient)}  />;
            // console.log('ingredient.name')
            // console.log(ingredient.name)
            // console.log('ingredient.ingredient_category_type_blue')
            // console.log(ingredient.ingredient_category_type_blue)
            // console.log('ingredient.ingredientType')
            // console.log(ingredient.ingredientType)
            /* if (owned_game.game_id === game.game_id) {
              return renderOwned
            } else {
              return renderPrice
            } */
            /* if (rowLen === index + 1) {
                // last one
                return <li key={index}> {ingredient.name}  </li>;
            } else { */
                // not last one
                if(ingredient.added_from === 'web'){
                    if(ingredient.ingredientType === "extras_charged" && ingredient.is_checked === true){
                        // coding for the web ingredinats listing
                        // return <p className="ingredient" key={index}> <span className={`menu-item-ingredient ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptMenuItemIngredientId === ingredient._id && selectedOnReceiptMenuItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemIngredient.menu_item_ingredient_timestamp === ingredient.menu_item_ingredient_timestamp && selectedOnReceiptItemType === 'menu_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickSelectReceiptMenuItemIngredient(product,ingredient)}> Only {ingredient.name} </span> <span className="float-right">${parseFloat(ingredient.price).toFixed(2)} {trashButton}</span>  </p>;
                        // old working one
                        // return <div className="ingredient position-relative" key={index}> <div className={`menu-item-ingredient ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptMenuItemIngredientId === ingredient._id  && selectedOnReceiptItemType === 'menu_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickMenuItemIngredient(product,ingredient,'menu_item_ingredient')}> Add {ingredient.name} </div> <div className="position-absolute menu-item-ingredient-price">${parseFloat(ingredient.price).toFixed(2)} {trashButton}</div>  </div>;
                        return <div className="ingredient position-relative" key={index}> <div className={`menu-item-ingredient ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptMenuItemIngredientId === ingredient._id && selectedOnReceiptMenuItemIngredient.menu_item_ingredient_timestamp === ingredient.menu_item_ingredient_timestamp &&  selectedOnReceiptItemType === 'menu_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickMenuItemIngredient(product,ingredient,'menu_item_ingredient')}> Add {ingredient.name} </div> <div className="position-absolute menu-item-ingredient-price">${parseFloat(ingredient.price).toFixed(2)} {trashButton}</div>  </div>;
                    }else if(ingredient.ingredientType === "included" && ingredient.is_checked === false){
                        // coding for the web ingredinats listing
                        return <div className="ingredient position-relative" key={index}> <div className={`menu-item-ingredient ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptMenuItemIngredientId === ingredient._id  && selectedOnReceiptItemType === 'menu_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickMenuItemIngredient(product,ingredient,'menu_item_ingredient')}> No {ingredient.name} </div> <div className="position-absolute menu-item-ingredient-price">{trashButton}</div>  </div>;
                    }else if(ingredient.ingredientType === "both" && ingredient.is_checked === false){
                        // coding for the web ingredinats listing
                        return <div className="ingredient position-relative" key={index}> <div className={`menu-item-ingredient ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptMenuItemIngredientId === ingredient._id  && selectedOnReceiptItemType === 'menu_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickMenuItemIngredient(product,ingredient,'menu_item_ingredient')}> No {ingredient.name} </div> <div className="position-absolute menu-item-ingredient-price">{trashButton}</div>  </div>;
                    }else if(ingredient.ingredientType === "optional_free" && ingredient.is_checked === true){
                        // coding for the web ingredinats listing
                        return <div className="ingredient position-relative" key={index}> <div className={`menu-item-ingredient ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptMenuItemIngredientId === ingredient._id  && selectedOnReceiptItemType === 'menu_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickMenuItemIngredient(product,ingredient,'menu_item_ingredient')}> Add {ingredient.name} </div> <div className="position-absolute menu-item-ingredient-price">{trashButton}</div>  </div>;
                    }
                }else{
                    if(ingredient.ingredient_category_type_blue === "no" && ingredient.is_checked === true){
                        if(ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged'){
                            return <div className="ingredient position-relative" key={index}> <div className={`menu-item-ingredient ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptMenuItemIngredientId === ingredient._id && selectedOnReceiptMenuItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemIngredient.menu_item_ingredient_timestamp === ingredient.menu_item_ingredient_timestamp && selectedOnReceiptItemType === 'menu_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickMenuItemIngredient(product,ingredient,'menu_item_ingredient')}> No {ingredient.name} </div> <div className="position-absolute menu-item-ingredient-price">${parseFloat(ingredient.price).toFixed(2)} {trashButton}</div> </div>;
                        }else{
                            return <div className="ingredient position-relative" key={index}> <div className={`menu-item-ingredient ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptMenuItemIngredientId === ingredient._id && selectedOnReceiptMenuItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemIngredient.menu_item_ingredient_timestamp === ingredient.menu_item_ingredient_timestamp && selectedOnReceiptItemType === 'menu_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickMenuItemIngredient(product,ingredient,'menu_item_ingredient')}> No {ingredient.name} </div> <div className="position-absolute menu-item-ingredient-price">{trashButton}</div> </div>;
                        }
                    }else if(ingredient.ingredient_category_type_blue === "extra" && ingredient.is_checked === true){
                        if(ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged'){
                            return <div className="ingredient position-relative" key={index}> <div className={`menu-item-ingredient ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptMenuItemIngredientId === ingredient._id && selectedOnReceiptMenuItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemIngredient.menu_item_ingredient_timestamp === ingredient.menu_item_ingredient_timestamp && selectedOnReceiptItemType === 'menu_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickMenuItemIngredient(product,ingredient,'menu_item_ingredient')}> Extra {ingredient.name} </div> <div className="position-absolute menu-item-ingredient-price">${parseFloat(ingredient.price).toFixed(2)} {trashButton}</div>  </div>;
                        }else{
                            return <div className="ingredient position-relative" key={index}> <div className={`menu-item-ingredient ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptMenuItemIngredientId === ingredient._id && selectedOnReceiptMenuItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemIngredient.menu_item_ingredient_timestamp === ingredient.menu_item_ingredient_timestamp && selectedOnReceiptItemType === 'menu_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickMenuItemIngredient(product,ingredient,'menu_item_ingredient')}> Extra {ingredient.name} </div> <div className="position-absolute menu-item-ingredient-price">{trashButton}</div> </div>;
                        }
                    }else if(ingredient.ingredient_category_type_blue === "add" && ingredient.is_checked === true){
                        if(ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged'){
                            return <div className="ingredient position-relative" key={index}> <div className={`menu-item-ingredient ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptMenuItemIngredientId === ingredient._id && selectedOnReceiptMenuItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemIngredient.menu_item_ingredient_timestamp === ingredient.menu_item_ingredient_timestamp && selectedOnReceiptItemType === 'menu_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickMenuItemIngredient(product,ingredient,'menu_item_ingredient')}> Add {ingredient.name} </div> <div className="position-absolute menu-item-ingredient-price">${parseFloat(ingredient.price).toFixed(2)} {trashButton} </div>  </div>;
                        }else{
                            return <div className="ingredient position-relative" key={index}> <div className={`menu-item-ingredient ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptMenuItemIngredientId === ingredient._id && selectedOnReceiptMenuItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemIngredient.menu_item_ingredient_timestamp === ingredient.menu_item_ingredient_timestamp && selectedOnReceiptItemType === 'menu_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickMenuItemIngredient(product,ingredient,'menu_item_ingredient')}> Add {ingredient.name} </div> <div className="position-absolute menu-item-ingredient-price"> {trashButton} </div> </div>;
                        }
                    }else if(ingredient.ingredient_category_type_blue === "light" && ingredient.is_checked === true){
                        if(ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged'){
                            return <div className="ingredient position-relative" key={index}> <div className={`menu-item-ingredient ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptMenuItemIngredientId === ingredient._id && selectedOnReceiptMenuItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemIngredient.menu_item_ingredient_timestamp === ingredient.menu_item_ingredient_timestamp && selectedOnReceiptItemType === 'menu_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickMenuItemIngredient(product,ingredient,'menu_item_ingredient')}> Light {ingredient.name} </div> <div className="position-absolute menu-item-ingredient-price">${parseFloat(ingredient.price).toFixed(2)} {trashButton}</div>  </div>;
                        }else{
                            return <div className="ingredient position-relative" key={index}> <div className={`menu-item-ingredient ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptMenuItemIngredientId === ingredient._id && selectedOnReceiptMenuItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemIngredient.menu_item_ingredient_timestamp === ingredient.menu_item_ingredient_timestamp && selectedOnReceiptItemType === 'menu_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickMenuItemIngredient(product,ingredient,'menu_item_ingredient')}> Light {ingredient.name} </div> <div className="position-absolute menu-item-ingredient-price">{trashButton}</div> </div>;
                        }
                    }else if(ingredient.ingredient_category_type_blue === "only" && ingredient.is_checked === true){
                        if(ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged'){
                            return <div className="ingredient position-relative" key={index}> <div className={`menu-item-ingredient ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptMenuItemIngredientId === ingredient._id && selectedOnReceiptMenuItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemIngredient.menu_item_ingredient_timestamp === ingredient.menu_item_ingredient_timestamp && selectedOnReceiptItemType === 'menu_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickMenuItemIngredient(product,ingredient,'menu_item_ingredient')}> Only {ingredient.name} </div> <div className="position-absolute menu-item-ingredient-price">${parseFloat(ingredient.price).toFixed(2)} {trashButton}</div>  </div>;
                        }else{
                            return <div className="ingredient position-relative" key={index}> <div className={`menu-item-ingredient ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptMenuItemIngredientId === ingredient._id && selectedOnReceiptMenuItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemIngredient.menu_item_ingredient_timestamp === ingredient.menu_item_ingredient_timestamp && selectedOnReceiptItemType === 'menu_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickMenuItemIngredient(product,ingredient,'menu_item_ingredient')}> Only {ingredient.name} </div> <div className="position-absolute menu-item-ingredient-price">{trashButton}</div> </div>;
                        }
                    }else if(ingredient.ingredient_category_type_blue === "well_done" && ingredient.is_checked === true){
                        if(ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged'){
                            return <div className="ingredient position-relative" key={index}> <div className={`menu-item-ingredient ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptMenuItemIngredientId === ingredient._id && selectedOnReceiptMenuItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemIngredient.menu_item_ingredient_timestamp === ingredient.menu_item_ingredient_timestamp && selectedOnReceiptItemType === 'menu_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickMenuItemIngredient(product,ingredient,'menu_item_ingredient')}> Well Done {ingredient.name} </div> <div className="position-absolute menu-item-ingredient-price">${parseFloat(ingredient.price).toFixed(2)} {trashButton}</div>  </div>;
                        }else{
                            return <div className="ingredient position-relative" key={index}> <div className={`menu-item-ingredient ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptMenuItemIngredientId === ingredient._id && selectedOnReceiptMenuItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemIngredient.menu_item_ingredient_timestamp === ingredient.menu_item_ingredient_timestamp && selectedOnReceiptItemType === 'menu_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickMenuItemIngredient(product,ingredient,'menu_item_ingredient')}> Well Done {ingredient.name} </div> <div className="position-absolute menu-item-ingredient-price">{trashButton}</div> </div>;
                        }
                    } else if (ingredient.ingredient_category_type_blue === "put_on_side" && ingredient.is_checked === true) {
                        if (ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged') {
                            return <div className="ingredient position-relative" key={index}> <div className={`menu-item-ingredient ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptMenuItemIngredientId === ingredient._id && selectedOnReceiptMenuItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemIngredient.menu_item_ingredient_timestamp === ingredient.menu_item_ingredient_timestamp && selectedOnReceiptItemType === 'menu_item_ingredient') ? "selected" : ""} `} onClick={() => handleOnClickMenuItemIngredient(product,ingredient,'menu_item_ingredient')}> Put {ingredient.name} on side </div> <div className="position-absolute menu-item-ingredient-price">${parseFloat(ingredient.price).toFixed(2)} {trashButton}</div> </div>;
                        } else {
                            return <div className="ingredient position-relative" key={index}> <div className={`menu-item-ingredient ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptMenuItemIngredientId === ingredient._id && selectedOnReceiptMenuItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemIngredient.menu_item_ingredient_timestamp === ingredient.menu_item_ingredient_timestamp && selectedOnReceiptItemType === 'menu_item_ingredient') ? "selected" : ""} `} onClick={() => handleOnClickMenuItemIngredient(product,ingredient,'menu_item_ingredient')}> Put {ingredient.name} on side </div> <div className="position-absolute menu-item-ingredient-price">{trashButton}</div> </div>;
                        }
                    }
                    else if(ingredient.is_note){
                        if(ingredient.is_paid_notes){
                            return <div className="ingredient position-relative" key={index}> <div className={`menu-item-ingredient font-weight-bolder ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptMenuItemIngredientId === ingredient.timestamp  && selectedOnReceiptItemType === 'menu_item_ingredient_notes') ? "selected" : ""}`} onClick={() => handleOnClickMenuItemIngredient(product, ingredient,'menu_item_ingredient_notes')} >{ingredient.notes} </div> <div className="position-absolute menu-item-ingredient-price" >${parseFloat(ingredient.extra_charges).toFixed(2)} {trashButton}</div>  </div>;
                        }else{
                            return <div className="ingredient position-relative" key={index}> <div className={`menu-item-ingredient font-weight-bolder ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptMenuItemIngredientId === ingredient.timestamp  && selectedOnReceiptItemType === 'menu_item_ingredient_notes') ? "selected" : ""} ${(notesCommon.includes(ingredient.notes.toLowerCase())) ? "no-bold-notes" : ""} `} onClick={() => handleOnClickMenuItemIngredient(product, ingredient,'menu_item_ingredient_notes')} >{ingredient.notes}</div> <div className="position-absolute menu-item-ingredient-price">{trashButton}</div> </div>;
                        }
                    }

                }
                
                // return <span key={index}> {ingredient.name} , </span>;
            // }
            
        })
        return ret;
        
    } else {
        // return '/images/image_not_availble.png'
        return null;
    }
}

export default RenderMenuItemIngredients;