import React,{Fragment} from 'react';
import MiscIngredientItem from './MiscIngredientItem';
const MiscIngredients = (props) => {
    // console.log('props in MiscIngredients')
    // console.log('props in MiscIngredients')
    // console.log(props)
    const menuItemWithIngredient = props.menuItemWithIngredient;
    const renderMenuItemWithIngredient = (Ingredients) => (
        Ingredients ? 
            Ingredients.slice(0,props.limitMiscIngredients).map((card,index)=>{
                // console.log('card')
                // console.log('card')
                // console.log(card)
                if(card.ingredient_main_type === "misc"){
                    return    <MiscIngredientItem 
                                    key={card._id}
                                    addIngredientToMenuItemCart={props.addIngredientToMenuItemCart}
                                    {...card}
                                    // handleOnClickMenuCategoryItem={props.handleOnClickMenuCategoryItem}
                                    // selectedMenuCategoryItemId={props.selectedMenuCategoryItemId}
                                />; 
                }
            })
        :null
    )
    return (
                <Fragment>
                    {menuItemWithIngredient ?
                        menuItemWithIngredient.Ingredients.length === 0 ?
                            <div className="no_result">
                                Sorry, no results
                            </div>
                        :null
                    :null}
                    { renderMenuItemWithIngredient(menuItemWithIngredient.Ingredients.filter(ingredient => ingredient.ingredient_main_type === "misc"))}
                    {/* { renderMenuItemWithIngredient(menuItemWithIngredient.Ingredients)} */}
                   
                </Fragment>
    );
};

export default MiscIngredients;