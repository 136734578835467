import React,{Fragment} from 'react';
const OrderItemOrderReceiptPopup = (props) => {
    const {ObjOrder} = props;
    let paymentType,customerGivenAmount,changeAmount = '';
    if(ObjOrder.payment_method === 'cash'){
        paymentType = 'in cash';
    }else if(ObjOrder.payment_method === 'debit_card'){
        paymentType = 'with debit card';
    }else if(ObjOrder.payment_method === 'credit_card'){
        paymentType = 'with credit card';
    }else if(ObjOrder.payment_method === 'gift_card'){
        paymentType = 'with gift card';
    }else{
        return null;
    }
    // console.log('ObjOrder.final_total')
    // console.log('ObjOrder.final_total')
    // console.log('ObjOrder.final_total')
    // console.log('ObjOrder.final_total')
    // console.log(ObjOrder)
    // console.log(ObjOrder.final_total)
    if(ObjOrder.customer_given_amount && ObjOrder.customer_given_amount !== '' && ObjOrder.customer_given_amount !== null && parseFloat(ObjOrder.customer_given_amount)> 0){
        changeAmount = (parseFloat(ObjOrder.customer_given_amount) - parseFloat(ObjOrder.total)).toFixed(2);
        customerGivenAmount = ObjOrder.customer_given_amount;
    }else{
        customerGivenAmount = ObjOrder.total;
        changeAmount = 0.00;
    }

    return (
        <Fragment>
            <tr className="tabletitle">
                <td className="Rate"><h2>Paid {paymentType}</h2></td>
                <td className="payment"><h2>${parseFloat(customerGivenAmount).toFixed(2)}</h2></td>
            </tr>
            <tr className="tabletitle">
                <td className="Rate"><h2>Change</h2></td>
                <td className="payment"><h2>${parseFloat(changeAmount).toFixed(2)}</h2></td>
            </tr>   
        </Fragment>
    );
};

export default OrderItemOrderReceiptPopup;