import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import KitchenOrderMenuOptionsModalBody from './KitchenOrderMenuOptionsModalBody';
class KitchenOrderMenuOptionsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            discount: ''
            // modal: props.showKitchenOrderMenuOptionsModal

        };
    
        this.toggle = this.toggle.bind(this);
        // this.handleChange = this.handleChange.bind(this);
    }
    
    toggle() {
        this.props.handleOnChangeKitchenOrderMenuOptionsModalToggle(!this.props.showKitchenOrderMenuOptionsModal)
    } 
    
    render() {
        
        // console.log('this.props.showKitchenOrderMenuOptionsModal in modal')
        // console.log(this.props)
        
        return (
                <Modal size="lg" centered={true} isOpen={this.props.showKitchenOrderMenuOptionsModal} toggle={this.toggle} className={this.props.className} >
                {/* <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="lg"> */}
                    {/* <ModalHeader toggle={this.toggle}>Select your option</ModalHeader> */}
                    <ModalBody className="kitchenOptionsModalBody">
                        <KitchenOrderMenuOptionsModalBody 
                            toggle={this.toggle}
                            handleOnClickDoneOrder={this.props.handleOnClickDoneOrder}
                            handleOnClickClearOrder={this.props.handleOnClickClearOrder}
                        />
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.applyDiscount}>Done</Button>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter> */}
                </Modal>
        );
    }
};

export default KitchenOrderMenuOptionsModal;