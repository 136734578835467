import React,{Fragment} from 'react';
import MenuItem from './MenuItem'
const MenuItemsColumn = (props) => {

    // console.log('props in MenuItemsColumn')
    // console.log(props)
    const MenuItems = props.MenuItems;
    const renderMenuItems = (MenuItems) => (
        MenuItems ? 
            MenuItems.slice(0,props.limitMenuItems).map(card=>(
                <MenuItem
                    key={card._id}
                    {...card}
                    handleOnClickMenuCategoryItem={props.handleOnClickMenuCategoryItem}
                    selectedMenuCategoryItemId={props.selectedMenuCategoryItemId}
                />
            ))
        :null
    )
    return (
            <Fragment>
                {MenuItems ?
                    MenuItems.length === 0 ?
                        <div className="no_result">
                            Sorry, no results
                        </div>
                    :null
                :null}
                { renderMenuItems(MenuItems)}
                {/* <button className="btn btn-danger w-100 py-2 mb-3 d-inline-flex align-items-center">
                    chickenkebob
                </button>
                <button className="btn btn-danger w-100 py-2 mb-3 d-inline-flex align-items-center">
                    gyros
                </button>
                <button className="btn btn-danger w-100 py-2 mb-3 d-inline-flex align-items-center">
                    shishkabob
                </button>
                <button className="btn btn-danger w-100 py-2 mb-3 d-inline-flex align-items-center">
                    pork gyros
                </button>
                <button className="btn btn-danger w-100 py-2 mb-3 d-inline-flex align-items-center">
                    crazy chicken
                </button>
                <button className="btn btn-danger w-100 py-2 mb-3 d-inline-flex align-items-center">
                    heidis special
                </button>
                <button className="btn btn-danger w-100 py-2 mb-3 d-inline-flex align-items-center">
                    vegie pita
                </button>
                <button className="btn btn-danger w-100 py-2 mb-3 d-inline-flex align-items-center">
                    gyro parm
                </button>
                <button className="btn btn-danger w-100 py-2 mb-3 d-inline-flex align-items-center">
                    chickenkebob
                </button> */}
            </Fragment>     
    );
};

export default MenuItemsColumn;