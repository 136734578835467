import React,{Fragment} from 'react';
import PosRenderComboItemIngredients from './PosRenderComboItemIngredients';
const PosCartItemSubBlock = ({
        mainProduct,
        products,
    }) => {

    // console.log(products)
    // console.log(products)
    const renderItems = () => (
        products ?
            products.map((product,index)=>{
                if(product.is_note){
                    return <ul className="food-info-sublist" key={index}>
                        <li>
                            <h6 className="position-relative">
                                <div className = {`menu-item-combo-item-name `}  > With {product.notes } </div>
                                <div className="position-absolute menu-item-combo-item-price">
                                    
                                </div>
                            </h6>
                        </li>
                    </ul>
                }else{
                    return <ul className="food-info-sublist" key={index}>
                        <li>
                            <h6 className="position-relative">
                                <div className = {`menu-item-combo-item-name `} > With {product.name } </div>
                                <div className="position-absolute menu-item-combo-item-price">
                                    ${(parseFloat(product.combo_item_price)).toFixed(2) }
                                    {/* $ {(parseFloat(product.ingredientTotal) +parseFloat(product.combo_item_price)).toFixed(2) } */}
                                    
                                </div>
                            </h6>
                            {
                                product.ingredients.length > 0 ? PosRenderComboItemIngredients(mainProduct,product,product.ingredients) : null
                                // product.ingredients.length > 0 ? PosRenderComboItemIngredients(mainProduct,product,product.ingredients,removeIngredientFromCartComboItem) : null
                            }
                            
                        </li>
                    </ul>
                }
            })

        :null
    )


    return (
        <Fragment>
            {renderItems()}
        </Fragment>
    );
};

export default PosCartItemSubBlock;