import React,{Fragment} from 'react';
import OrderItemPosKitchenCarousel from './OrderItemPosKitchenCarousel';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
const OrderItemPosKitchenCarouselBlock = (props) => {

    const renderOrders = () => (
        props.list ? 
            props.list.map(card=>(
                <OrderItemPosKitchenCarousel
                    key={card._id}
                    // handelOnClickPosOrderPrint={props.handelOnClickPosOrderPrint}
                    comboItemColor={props.comboItemColor}
                    onClickHandleKitchenOrder={props.onClickHandleKitchenOrder}
                    {...card}
                />
            ))
        :null
    )
    // console.log('props.showItems')
    // console.log('props.showItems')
    // console.log(props.showItems)
    return (
        <Fragment>
            {props.list ?
                props.list.length === 0 ?
                    <div className="col-12 no_result">
                       {/* <p className="text-center text-white"> You have not placed any orders yet.</p> */}
                    </div>
                :null
            :null}
            
                    {/* { renderOrders(props.list)} */}
                <Carousel
                slidesPerPage={props.showItems}
                slidesPerScroll={props.showItems}
                arrows
                infinite={false}
                >
                        {renderOrders(props.list)}
                  {/* <h3 style={{color:"#fff"}}>This is a heading 1</h3>
                  <h3 style={{color:"#fff"}}>This is a heading 2</h3>
                  <h3 style={{color:"#fff"}}>This is a heading 3</h3>
                  <h3 style={{color:"#fff"}}>This is a heading 4</h3>
                  <h3 style={{color:"#fff"}}>This is a heading 5</h3>
                  <h3 style={{color:"#fff"}}>This is a heading 6</h3>
                  <h3 style={{color:"#fff"}}>This is a heading 7</h3> */}

                </Carousel>
        </Fragment>
    );
};

export default OrderItemPosKitchenCarouselBlock;