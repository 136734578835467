import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import DoneModalBody from './DoneModalBody';
// import { Button } from 'reactstrap';
class DoneModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            discount: '',
            hideButtons:false,
            // modal: props.showDoneModal

        };
    
        this.toggle = this.toggle.bind(this);
        this.btnNoKitchen = React.createRef();
        this.btnPrint = React.createRef();
        this.btnDone = React.createRef();
        this.btnCancel = React.createRef();
        // this.applyDiscount =this.applyDiscount.bind(this);
        // this.handleChange = this.handleChange.bind(this);
    }
    
    applyDiscount = () => {
        if(this.state.discount !== ''){
            this.props.handleOnChangeDoneModalToggle(!this.props.showDoneModal)
        }else{
            alert('Please add some discount value.')
        }
        
    }
    // handleChange(event) {
    handleChange = (event) => {
        // console.log('event.target.value')
        // console.log(event.target.value)
        this.setState({discount: parseInt(event.target.value)});
    }
    
    toggle() {
        this.props.handleOnChangeDoneModalToggle(!this.props.showDoneModal)
    }

    ModalOpened = () => {
        console.log('opened')
        console.log('opened')
        console.log('opened')
        console.log('opened')
        this.setState({hideButtons:false});
    }
    
    handleOnClickModalDoneButton = () => {
        console.log('dsd');
        console.log(this.btnDone)
        console.log(this.btnDone.current)
        // console.log('event.target')
        // console.log(event.target)
        // this.btnNoKitchen.current.disabled = true;
        // this.btnPrint.current.disabled = true;
        this.btnDone.current.disabled = true;
        // this.btnCancel.current.disabled = true;
        this.setState({hideButtons:true},()=>{
            this.props.submitDoneOrder();
        });
        // this.btnDone.current.style.display = 'none';
        // this.btnDone.current.setAttribute("disabled", "disabled");;
    }
    handelOnClickModalPrintButton = () => {
        console.log('dsd');
        console.log(this.btnPrint)
        console.log(this.btnPrint.current)
        // console.log('event.target')
        // console.log(event.target)
        // this.btnNoKitchen.current.disabled = true;
        // this.btnPrint.current.disabled = true;
        this.btnPrint.current.disabled = true;
        // this.btnCancel.current.disabled = true;
        this.setState({hideButtons:true},()=>{
            this.props.handelOnClickPrint();
        });
        // this.btnDone.current.style.display = 'none';
        // this.btnDone.current.setAttribute("disabled", "disabled");;
    }
    
    render() {
        
        // console.log('this.props.showDoneModal in modal')
        // console.log(this.props.showDoneModal)
        
        return (
            <Modal centered={true} isOpen={this.props.showDoneModal} onOpened={this.ModalOpened} toggle={this.toggle} className={this.props.className} >
                {/* <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="lg"> */}
                    {/* <ModalHeader >Select your option</ModalHeader> */}
                    <ModalBody className="DoneModalBody">
                        <DoneModalBody 
                            handleChange={this.handleChange}
                            discount={this.state.discount}
                            hideButtons={this.state.hideButtons}
                            toggle={this.toggle}
                            submitDoneOrder={this.handleOnClickModalDoneButton}
                            // submitDoneOrder={this.props.submitDoneOrder}
                            handelOnClickPrint={this.handelOnClickModalPrintButton} 
                            handelOnClickNoKitchen={this.props.handelOnClickNoKitchen} 
                            existing_order_id={this.props.existing_order_id} 
                            isShowLoader={this.props.isShowLoader}
                            showDoneModal={this.props.showDoneModal}
                            btnNoKitchen={this.btnNoKitchen} 
                            btnPrint={this.btnPrint} 
                            btnDone={this.btnDone} 
                            btnCancel={this.btnCancel} 
                        />
                        {/* <button ref={this.btnDone} onClick={()=> this.handleOnClickModalDoneButton()} type="button">Done</button> */}
                        {/* <Button ref={this.btnDone} color="warning" disabled={false} className="btnNotPadModalButton"  onClick={()=> this.handleOnClickModalDoneButton()} >Done</Button> */}
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.applyDiscount}>Done</Button>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter> */}
                </Modal>
        );
    }
};

export default DoneModal;