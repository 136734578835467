import React from 'react';
import { notesCommon } from '../../../utils/general';
const PosKitchenRenderMenuItemIngredients = (product, ingredients) => {
    // console.log('product')
    // console.log(product.name)
    // console.log('ingredients')
    // console.log(ingredients)
    // console.log(ingredients.length) 
    // ingredients = ingredients.filter(i => i.is_checked === true);
    // console.log('ingredients after')
    // console.log(ingredients)
    if(ingredients.length > 0){
        // let rowLen =ingredients.length;
        // return images[0].url
        let ret = ingredients.map((ingredient,index) => {
            // console.log('ingredient.name')
            // console.log(ingredient.name)
            // console.log('ingredient.ingredient_category_type_blue')
            // console.log(ingredient.ingredient_category_type_blue)
            // console.log('ingredient.ingredientType')
            // console.log(ingredient.ingredientType)
            /* if (owned_game.game_id === game.game_id) {
              return renderOwned
            } else {
              return renderPrice
            } */
            /* if (rowLen === index + 1) {
                // last one
                return <li key={index}> {ingredient.name}  </li>;
            } else { */
                // not last one
                if(ingredient.added_from === 'web'){
                    if(ingredient.ingredientType === "extras_charged" && ingredient.is_checked === true){
                        // coding for the web ingredinats listing
                        return <p className="ingredient" key={index}> <span className={`menu-item-ingredient`} > Add {ingredient.name} </span>  </p>;
                    }else if(ingredient.ingredientType === "included" && ingredient.is_checked === false){
                        // coding for the web ingredinats listing
                        return <p className="ingredient" key={index}> <span className={`menu-item-ingredient`} > No {ingredient.name} </span>   </p>;
                    }else if(ingredient.ingredientType === "optional_free" && ingredient.is_checked === true){
                        // coding for the web ingredinats listing
                        return <p className="ingredient" key={index}> <span className={`menu-item-ingredient`} > Add {ingredient.name} </span>   </p>;
                    }else if(ingredient.ingredientType === "both" && ingredient.is_checked === false){
                        // coding for the web ingredinats listing
                        return <p className="ingredient" key={index}> <span className={`menu-item-ingredient`} > No {ingredient.name} </span>   </p>;
                    }
                }else{
                    if(ingredient.ingredient_category_type_blue === "no" && ingredient.is_checked === true){
                        if(ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged'){
                            return <p className="ingredient" key={index}> <span className={`menu-item-ingredient  `} > No {ingredient.name} </span> {/* <span className="float-right">${parseFloat(ingredient.price).toFixed(2)} </span> */} </p>;
                        }else{
                            return <p className="ingredient" key={index}> <span className={`menu-item-ingredient  `} > No {ingredient.name} </span>  </p>;
                        }
                    }else if(ingredient.ingredient_category_type_blue === "extra" && ingredient.is_checked === true){
                        if(ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged'){
                            return <p className="ingredient" key={index}> <span className={`menu-item-ingredient  `} > Extra {ingredient.name} </span> {/* <span className="float-right">${parseFloat(ingredient.price).toFixed(2)} </span> */}  </p>;
                        }else{
                            return <p className="ingredient" key={index}> <span className={`menu-item-ingredient  `} > Extra {ingredient.name} </span>  </p>;
                        }
                    }else if(ingredient.ingredient_category_type_blue === "add" && ingredient.is_checked === true){
                        if(ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged'){
                            return <p className="ingredient" key={index}> <span className={`menu-item-ingredient  `} > Add {ingredient.name} </span> {/* <span className="float-right">${parseFloat(ingredient.price).toFixed(2)} </span> */}  </p>;
                        }else{
                            return <p className="ingredient" key={index}> <span className={`menu-item-ingredient  `} > Add {ingredient.name} </span>  </p>;
                        }
                    }else if(ingredient.ingredient_category_type_blue === "light" && ingredient.is_checked === true){
                        if(ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged'){
                            return <p className="ingredient" key={index}> <span className={`menu-item-ingredient  `} > Light {ingredient.name} </span> {/* <span className="float-right">${parseFloat(ingredient.price).toFixed(2)} </span> */}  </p>;
                        }else{
                            return <p className="ingredient" key={index}> <span className={`menu-item-ingredient  `} > Light {ingredient.name} </span>  </p>;
                        }
                    }else if(ingredient.ingredient_category_type_blue === "only" && ingredient.is_checked === true){
                        if(ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged'){
                            return <p className="ingredient" key={index}> <span className={`menu-item-ingredient  `} > Only {ingredient.name} </span> {/* <span className="float-right">${parseFloat(ingredient.price).toFixed(2)} </span> */}  </p>;
                        }else{
                            return <p className="ingredient" key={index}> <span className={`menu-item-ingredient  `} > Only {ingredient.name} </span>  </p>;
                        }
                    }else if(ingredient.ingredient_category_type_blue === "well_done" && ingredient.is_checked === true){
                        if(ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged'){
                            return <p className="ingredient" key={index}> <span className={`menu-item-ingredient  `} > Well Done {ingredient.name} </span> {/* <span className="float-right">${parseFloat(ingredient.price).toFixed(2)} </span> */}  </p>;
                        }else{
                            return <p className="ingredient" key={index}> <span className={`menu-item-ingredient  `} > Well Done {ingredient.name} </span>  </p>;
                        }
                    } else if (ingredient.ingredient_category_type_blue === "put_on_side" && ingredient.is_checked === true) {
                        if (ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged') {
                            return <p className="ingredient" key={index}> <span className={`menu-item-ingredient  `} > Put {ingredient.name} on side </span> {/* <span className="float-right">${parseFloat(ingredient.price).toFixed(2)} </span> */} </p>;
                        } else {
                            return <p className="ingredient" key={index}> <span className={`menu-item-ingredient  `} > Put {ingredient.name} on side </span>  </p>;
                        }
                    }else if(ingredient.is_note){
                        if(ingredient.is_paid_notes){
                            return <p className="ingredient" key={index}> <span className={`font-weight-bolder`} >{ingredient.notes} </span> {/* <span className="float-right">${parseFloat(ingredient.extra_charges).toFixed(2)} </span> */}  </p>;
                        }else{
                            return <p className="ingredient" key={index}> <span className={`font-weight-bolder ${(notesCommon.includes(ingredient.notes.toLowerCase())) ? "no-bold-notes" : ""} `} >{ingredient.notes} </span>  </p>;
                        }
                    }
                }
                
                // return <span key={index}> {ingredient.name} , </span>;
            // }
            
        })
        return ret;
        
    } else {
        // return '/images/image_not_availble.png'
        return null;
    }
}

export default PosKitchenRenderMenuItemIngredients;