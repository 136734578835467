import React,{Fragment} from 'react';
import {formatExistingOrderDateTimeOnly,formatExistingOrderDateTime,isDateMatches} from '../../../../../utils/general';
import WebRenderDeliveryType from './WebRenderDeliveryType';
const WebOrderItem = (props) => {


        // console.log('props in OrderItem')
        // console.log(props)
        return (
                <tr className="cursor-pointer" onClick={()=> props.handelOnClickWebExistingOrder(props)}>
                    <td>
                        <WebRenderDeliveryType   
                            selectedOrderType={props.delivery_type}
                        />
                    </td>
                    <td>
                        {props.fullname}
                    </td>
                    <td>
                        {/* {formatExistingOrderDateTime(props.order_completion_datetime)} <br /> */}
                        {/* {formatExistingOrderDate(props.order_completion_datetime)}<br /> */}
                        {formatExistingOrderDateTimeOnly(props.order_completion_datetime)} <br />
                        {
                                isDateMatches(props.order_completion_datetime,new Date()) === false ?
                                    <Fragment> {formatExistingOrderDateTime(props.order_completion_datetime)} </Fragment>
                                :null
                            }

                    </td>
                    <td>
                        ${props.final_total}
                        {/* ${props.total_amount_after_tax_delivery_charges} */}
                    </td>
                    
                </tr>
        );
}



export default WebOrderItem;