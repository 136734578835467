import React,{Fragment} from 'react';
const IsNewOrExistingCustomerSelectionModalBody = (props)  => {
    return (
        <Fragment>
            <div className="row">
                <div className="col-md-6">
                    <button type="button" onClick={(e) => props.handleOnClickCustomerTypeSelection(e,'new')} className="btn btn-primary btn-primary-original btn-block btn-lg">New</button> 
                </div>
                <div className="col-md-6">
                    <button type="button" onClick={(e) => props.handleOnClickCustomerTypeSelection(e,'existing')} className="btn btn-primary btn-primary-original btn-block btn-lg">Existing</button> 
                </div>
            </div>
        </Fragment>
    );
}

export default IsNewOrExistingCustomerSelectionModalBody;