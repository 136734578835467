import React,{Fragment} from 'react';
import LoadingSpinner from '../../../../../utils/LoadingSpinner';
import LoadMoreMistakeExistingOrderItems from './LoadMoreMistakeExistingOrderItems';
const MistakeExistingOrdersModalBody = (props)  => {
    const order = props.order;
    /*console.log('order')
    console.log('order')
    console.log('order')
    console.log(order)*/
    if (Object.keys(order).length === 0 || order.mistakeExistingOrders === undefined) {
        return (
            <LoadingSpinner subClass={'mt10 mb10 text-center'} />
        )
    }else{

    }
    return (
        <Fragment>
            <div className="row">
                <div className="table-responsive">   
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Phone No</th>
                                {/* <th>Delivery Type</th> */}
                                <th>Name</th>
                                {/* <th>Time</th> */}
                                <th>Total Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                order.mistakeExistingOrders !== 'undefined' && order.mistakeExistingOrders !== undefined ?
                                    <LoadMoreMistakeExistingOrderItems
                                        limit={props.limitMistakeExstingOrders}
                                        total_mistake_existing_orders={order.total_mistake_existing_orders}
                                        Orders={order.mistakeExistingOrders}
                                        onClickHandleLoadMoreMistakeExistingOrderItems={props.onClickHandleLoadMoreMistakeExistingOrderItems}
                                        handelOnClickMistakeExistingOrder={props.handelOnClickMistakeExistingOrder}
                                    />
                                :null
                            }
                        </tbody>
                    </table>

                    {
                        props.isloadingMistakeExistingOrders ? <LoadingSpinner subClass={'mt10 mb10 text-center'} /> :null
                    }
                </div>
            </div>
        </Fragment>
    );
}

export default MistakeExistingOrdersModalBody;