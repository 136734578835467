import React,{Fragment} from 'react';
import OrderItemPosKitchen from './OrderItemPosKitchen';

const OrderItemPosKitchenBlock = (props) => {

    const renderOrders = () => (
        props.list ? 
            props.list.map(card=>(
                <OrderItemPosKitchen
                    key={card._id}
                    // handelOnClickPosOrderPrint={props.handelOnClickPosOrderPrint}
                    comboItemColor={props.comboItemColor}
                    onClickHandleKitchenOrder={props.onClickHandleKitchenOrder}
                    {...card}
                />
            ))
        :null
    )

    return (
        <Fragment>
            {props.list ?
                props.list.length === 0 ?
                    <div className="col-12 no_result">
                       <p className="text-center text-white"> You have not placed any orders yet.</p>
                    </div>
                :null
            :null}
            { renderOrders(props.list)}

        </Fragment>
    );
};

export default OrderItemPosKitchenBlock;