import React,{Fragment} from 'react';
const IsNewOrExistingTabletOrderNotificationSelectionModalBody = (props)  => {
    return (
        <Fragment>
            <div className="row">
                <div className="col-md-6">
                    <button type="button" onClick={(e) => props.handleOnClickTabletOrderNotificationTypeSelection(e,'new')} className="btn btn-primary btn-primary-original btn-block btn-lg btnKioskOrderSlection">NEW ORDERS</button> 
                </div>
                <div className="col-md-6">
                    <button type="button" onClick={(e) => props.handleOnClickTabletOrderNotificationTypeSelection(e,'existing')} className="btn btn-primary btn-primary-original btn-block btn-lg btnKioskOrderSlection">SAVED ORDERS</button> 
                </div>
            </div>
        </Fragment>
    );
}

export default IsNewOrExistingTabletOrderNotificationSelectionModalBody;