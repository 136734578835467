import React from 'react';
import sixthPng from '../../../../../common/images/icons/6.png';
import {  Modal, ModalHeader, ModalBody } from 'reactstrap';
import NotePadModalBody from './NotePadModalBody';
import { showMsg } from '../../../../../utils/general';
// const NotePad = (props) => {
class NotePad extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
        /* this.setState(prevState => ({
            modal: !prevState.modal
        }),()=>{
            this.props.handleOnChangeNotePadToggle(this.state.modal)
        }); */
    
    }
    submit = async values => {
        
        if(values.notes !== ''){
            if(values.hasOwnProperty('txtExtraCharges')){
                if(this.props.isMistakeActive && this.props.mistake_type === 'new'){
                    showMsg("You can not add Note with extra charge on new mistake order.",'error');
                }else{
                    this.props.addNotesWithExtraCharge(values.notes,values.txtExtraCharges);
                    this.toggle();
                }
            }else{
                this.props.addNotesWithExtraCharge(values.notes,'no')
                this.toggle();
            }
        }
    }
    render() {
        /* let inLineStyle = {
            height: "45px"
        }
        if(this.props.MenuCategoriesLength < 8){
            inLineStyle = {
                display: 'block',
                maxWidth: '300px',
                margin: 'auto',
                marginTop :'-18px', 
                marginBottom :'18px', 
            }
        }else{
            inLineStyle = {
                position : 'absolute'
            }
        } */
        const selectedId = '';
        return (
            <div className={`NotePadSection notepad-button   ${this.props._id ===  selectedId ? 'activeMenuCategory' : ''} `}  >
                {/* <div className={`NotePadSection notepad-button  ${this.props._id ===  selectedId ? 'activeMenuCategory' : ''} `} style={inLineStyle} > */}
                <button onClick={this.toggle} className="btn btn-danger py-2 d-inline-flex align-items-center justify-content-center w-100" >
                    <img src={sixthPng} alt="icons" className="mw-100" />
                </button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="lg">
                    <ModalHeader toggle={this.toggle}>Note</ModalHeader>
                    <ModalBody>
                        <NotePadModalBody 
                            onSubmit={this.submit}
                            toggle={this.toggle} 
                        />
                    </ModalBody>
                    
                </Modal>
            </div>
        );
    }
};

export default NotePad;