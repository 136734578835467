import React,{ useState, useEffect } from 'react'
// import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { fetchPosOrders,clearPosOrderHistory,fetchPosTodayOrders,deleteOrderFromPosOrdersPage  } from '../../../actions/order_actions';
import { fetchResturantAddress  } from '../../../actions/settings_actions';
import { fetchExistingDeliveryOrders  } from '../../../actions/order_actions';
import LoadingSpinner from '../../utils/LoadingSpinner';
import MapContainer from './components/MapContainer';
// import { socket } from '../../layout/Header';
import { useDispatch, useSelector } from "react-redux";
import Geocode from "react-geocode";
import MapsMenu from './components/MapsMenu';
import { Button } from 'reactstrap';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
// set response language. Defaults to english.
Geocode.setLanguage("en");


const DeliveryOrdersGoogleMaps = () => {
    const [stores, setStores] = useState([
        // {lat: 47.49855629475769, lng: -122.14184416996333},
        // {latitude: 47.359423, longitude: -122.021071},
        // {latitude: 47.2052192687988, longitude: -121.988426208496},
        // {latitude: 47.6307081, longitude: -122.1434325},
        // {latitude: 47.3084488, longitude: -122.2140121},
        // {latitude: 47.5524695, longitude: -122.0425407}
    ]);
    const [isloadingOrders,setIsloadingOrders] = useState(false);
    const [RestaurantAddress, setRestaurantAddress] = useState({});

    const dispatch = useDispatch();
    const {  objRestaurantAddress,objExistingDeliveryOrders } = useSelector(state => ({
        objRestaurantAddress : state.settings.objRestaurantAddress,
        objExistingDeliveryOrders : state.order.objExistingDeliveryOrders,
        // count: state.counter.count,
        // user: state.user,
    }));
    const setTheStores = async (Orders) =>{
        let storeArr = [];
        for (let objOrder of Orders) {
            try {
                const {results,status} = await Geocode.fromAddress(objOrder.address);
                // const {results,status} = await Geocode.fromAddress('1790 Gladstone Ave.');
                console.log('status')
                console.log(status)
                if(status === 'OK'){
                    const { lat, lng } = results[0].geometry.location; 
                    let objLocation = {type:'order',latitude: lat, longitude:lng,customer_name:objOrder.objCustomer.fullname,address:objOrder.address}
                    storeArr.push(objLocation)
                    // setStores([...stores, objLocation]);
                    // setStores([...stores, restaurantAddress]);   
                }
            } catch (error) {
                console.log('Error in geocode')
                console.log('Error in geocode')
                console.log(error)
            }
           
        }
    
        if(storeArr.length > 0){
            setStores([...stores, ...storeArr]);
        }
    };
    useEffect(() => {
        // CompoennetDIdMoint 
        // _isMounted = true;
        setIsloadingOrders(true);
        dispatch(fetchResturantAddress()).then(response =>{
            console.log('fetchResturantAddress')
            console.log('fetchResturantAddress')
            let objRestaurantAddress = response.data.objRestaurantAddress;
            // console.log('response.data.restaurant_address')
            // console.log('response.data.restaurant_address')
            // console.log('response.data.restaurant_address')
            // console.log('response.data.restaurant_address')
            // console.log(response.data.restaurant_address)
            const objData = response.data
            // let restaurantAddress = {type:'restaurant_address',latitude: objData.restaurant_address_lat, longitude: objData.restaurant_address_lng}
            // console.log('...stores in fetchResturantAddress')
            // console.log('...stores in fetchResturantAddress')
            // console.log(stores)
            // setStores([...stores, restaurantAddress]);
            // setRestaurantAddress(restaurantAddress)
            // alert('sss')
        });
        dispatch(fetchExistingDeliveryOrders()).then(async response =>{
            console.log('fetchExistingDeliveryOrders')
            console.log('fetchExistingDeliveryOrders')
            // let objRestaurantAddress = response.data.objRestaurantAddress;
            // console.log('response.data in fetchExistingDeliveryOrders')
            // console.log('response.data in fetchExistingDeliveryOrders')
            // console.log('response.data in fetchExistingDeliveryOrders')
            // console.log('response.data in fetchExistingDeliveryOrders')
            // console.log(response.data.Orders)
            const Orders = response.data.Orders;
            setIsloadingOrders(false);
            setTheStores(Orders);
            /* let storeArr = [];
            for (let objOrder of Orders) {
            // Orders.forEach(async (objOrder) => {
                // console.log('objOrder.address')
                // console.log(objOrder.address)
                const {results,status} = await Geocode.fromAddress(objOrder.address);
                // console.log('status')
                // console.log(status)
                if(status === 'OK'){
                    const { lat, lng } = results[0].geometry.location; 
                    let objLocation = {type:'order',latitude: lat, longitude:lng,customer_name:objOrder.objCustomer.fullname,address:objOrder.address}
                    storeArr.push(objLocation)
                    // console.log('storeArr after push')
                    // console.log(storeArr)
                    // console.log('stores before')
                    // console.log('stores before')
                    // console.log(stores)
                    // setStores([...stores, objLocation]);
                    // setStores([...stores, restaurantAddress]);   
                }
                // console.log('addressResult')
                // console.log('addressResult')
                // console.log(addressResult)
                // Geocode.fromAddress(objOrder.address).then(
                //     response => {
                //       const { lat, lng } = response.results[0].geometry.location;
                //       console.log('lat, lng');
                //       console.log('lat, lng');
                //       console.log('lat, lng');
                //       console.log('lat, lng');
                //       console.log(lat, lng);
                //     },
                //     error => {
                //         console.log('error');
                //         console.log('error');
                //         console.error('objOrder.address in error is');
                //         console.error('objOrder.address in error is');
                //         console.error(objOrder.address);
                //         console.error(error);
                //     }
                // );
            // })
            }
            // console.log('storeArr')
            // console.log(storeArr)
            // console.log('storeArr')
            // console.log(...storeArr)
            if(storeArr.length > 0){
                // console.log('...stores')
                // console.log('...stores')
                // console.log(...stores)
                // console.log('objRestaurantAddress')
                // console.log('objRestaurantAddress')
                // console.log(objRestaurantAddress)
                // console.log('RestaurantAddress')
                // console.log('RestaurantAddress')
                // console.log(RestaurantAddress)
                setStores([...stores, ...storeArr]);
            } */
            // let restaurantAddress = {latitude: objData.restaurant_address_lat, longitude: objData.restaurant_address_lng}
            // setStores([...stores, restaurantAddress]);
        });
        
       
        // returned function will be called on component unmount componenetWillUnmount 
        return () => {
        //   window.removeEventListener('mousemove', () => {})
        //   _isMounted = false;
        }
    }, []);

    const handleOnClickRefreshMap  = () => {
        setIsloadingOrders(true);
        dispatch(fetchExistingDeliveryOrders()).then(async response =>{
            const Orders = response.data.Orders;
            setIsloadingOrders(false);
            setTheStores(Orders)
        });
    };
    // console.log('objRestaurantAddress')
    // console.log(objRestaurantAddress)
    // console.log('objExistingDeliveryOrders')
    // console.log('objExistingDeliveryOrders')
    // console.log(objExistingDeliveryOrders)
    // console.log('stores in render')
    // console.log(stores)
    if (objRestaurantAddress === undefined || objExistingDeliveryOrders === undefined || isloadingOrders === true) {
        return (
            <LoadingSpinner subClass={'mt10 mb10 text-center'} />
        )
    }
    return (
        
        <div className="container-fluid PosOrdersPage">
            <div className="row">
                <div className="col">
                    <MapsMenu />
                </div>
            </div>
            <div className="row">
                <div className="col text-center">
                    <Button color="success" className="text-center" onClick={()=> handleOnClickRefreshMap()}>Refresh</Button>
                </div>
            </div>
            <MapContainer 
                objRestaurantAddress={objRestaurantAddress}
                // restaurantAddress={restaurantAddress}
                stores={stores}
            />
                
        </div>
    
    )
    
};




// export default Home
export default withRouter(DeliveryOrdersGoogleMaps);
