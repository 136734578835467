import React,{Fragment} from 'react';

import MenuItemComboItem from './MenuItemComboItem';
const MenuItemComboItems = (props) => {
    const MenuItemCombos = props.MenuItemCombos;
    const renderMenuItemComboItems = (MenuItemCombos) => (
        MenuItemCombos ? 
            MenuItemCombos.slice(0,props.limitMenuItemComboItems).map(card=>(
                <MenuItemComboItem
                    key={card._id}
                    {...card}
                    handleOnClickMenuItemComboItem={props.handleOnClickMenuItemComboItem}
                    selectedMenuItemComboItemId={props.selectedMenuItemComboItemId}
                />
            ))
        :null
    )
    return (
                <Fragment>
                    {MenuItemCombos ?
                        MenuItemCombos.length === 0 ?
                            <div className="no_result">
                                Sorry, no results
                            </div>
                        :null
                    :null}
                    { renderMenuItemComboItems(MenuItemCombos)}
                    {/* <button className="btn btn-success w-100 py-2 mb-3 d-inline-flex align-items-center">
                        with fries
                    </button>
                    <button className="btn btn-success w-100 py-2 mb-3 d-inline-flex align-items-center">
                        with salad
                    </button> */}
                </Fragment>
    );
};

export default MenuItemComboItems;