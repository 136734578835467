import React,{Fragment} from 'react';
import MeatCheeseIngredientItem from './MeatCheeseIngredientItem';
const MeatCheeseIngredients = (props) => {
    const menuItemWithIngredient = props.menuItemWithIngredient;
    const renderMenuItemWithIngredient = (Ingredients) => (
        Ingredients ? 
            Ingredients.slice(0,props.limitMeatCheeseIngredients).map((card,index)=>{
                if(card.ingredient_main_type === "meat_cheese"){
                    return    <MeatCheeseIngredientItem 
                                    key={card._id}
                                    addIngredientToMenuItemCart={props.addIngredientToMenuItemCart}
                                    {...card}
                                    // handleOnClickMenuCategoryItem={props.handleOnClickMenuCategoryItem}
                                    // selectedMenuCategoryItemId={props.selectedMenuCategoryItemId}
                                />; 
                }
            })
        :null
    )
    return (
                <Fragment>
                    {menuItemWithIngredient ?
                        menuItemWithIngredient.Ingredients.length === 0 ?
                            <div className="no_result">
                                Sorry, no results
                            </div>
                        :null
                    :null}
                    { renderMenuItemWithIngredient(menuItemWithIngredient.Ingredients.filter(ingredient => ingredient.ingredient_main_type === "meat_cheese"))}
                    {/* { renderMenuItemWithIngredient(menuItemWithIngredient.Ingredients)} */}
                    

                </Fragment>
    );
};

export default MeatCheeseIngredients;