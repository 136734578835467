import React, { Component } from 'react';

class MenuCategory extends Component {
    /* state = {
        load : false
    }
    componentWillReceiveProps(nextProps) {
        console.log('nextProps')
        console.log(nextProps)
        this.setState({ load: !this.state.load });  
    } */
    render() {
        
        const props = this.props;
        // console.log('props.selectedMenuCategoryId in MenuCategory')
        // console.log(this.props.selectedMenuCategoryId)
        // console.log('props.isRefundActive in MenuCategory')
        // console.log(this.props.isRefundActive)
        
        return (

            <div className={`colCategory item`} onClick={()=>props.handleOnClickMenuCategory(props)} key={props._id}>
                <button
                    className={`btn btn-primary w-100 py-2 d-inline-flex align-items-center justify-content-center ${props.selectedMenuCategoryId ===  props._id ? 'activeMenuCategory' : ''}`}>
                    {props.name}
                </button>
            </div>
        );
    }
}



export default MenuCategory;