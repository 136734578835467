import axios from 'axios';
import {
    FETCH_RESTURANT_TAX,
    FETCH_RESTAURANT_NAME,
    FETCH_POS_COMBO_ITEM_COLOR,
    FETCH_RESTURANT_DELIVERY_FEES,
    FETCH_RESTURANT_ADDRESS,
    FETCH_RESTAURANT_LOGO,
    FETCH_RESTAURANT_PROVINCE,
    FETCH_RESTAURANT_CARD_PROCESSING_FEE,
} from './types';

import { BASE_URL } from '../components/utils/misc';
import { checkInternetConnection } from '../components/utils/general';
import localForage from 'localforage';



export const fetchResturantTax = () => async dispatch => {
    let isConnected = await checkInternetConnection();
    if(isConnected){
        const res = await axios.get(`${BASE_URL}/api/fetch_resturant_tax`);
        // console.log('res in tax')
        // console.log(res)
        localForage.setItem('fetch-pos-resturant-tax', res.data)
        dispatch({ type: FETCH_RESTURANT_TAX, payload: res.data });
    }else{
        let res = await localForage.getItem('fetch-pos-resturant-tax');
        dispatch({ type: FETCH_RESTURANT_TAX, payload:res });
    }
};

export const fetchRestaurantProvince = () => async dispatch => {
    let isConnected = await checkInternetConnection();
    if(isConnected){
        const res = await axios.get(`${BASE_URL}/api/settings/fetch-restaurant-province`);
        // console.log('res in tax')
        // console.log(res)
        localForage.setItem('fetch-pos-restaurant-province', res.data)
        dispatch({ type: FETCH_RESTAURANT_PROVINCE, payload: res.data });
    }else{
        let res = await localForage.getItem('fetch-pos-restaurant-province');
        dispatch({ type: FETCH_RESTAURANT_PROVINCE, payload:res });
    }
};

export const fetchRestaurantCardProcessingFee = () => async dispatch => {
    let isConnected = await checkInternetConnection();
    if(isConnected){
        const res = await axios.get(`${BASE_URL}/api/settings/fetch-restaurant-card-processing-fee`);
        localForage.setItem('fetch-pos-restaurant-card-processing-fee', res.data)
        dispatch({ type: FETCH_RESTAURANT_CARD_PROCESSING_FEE, payload: res.data });
    }else{
        let res = await localForage.getItem('fetch-pos-restaurant-card-processing-fee');
        dispatch({ type: FETCH_RESTAURANT_CARD_PROCESSING_FEE, payload:res });
    }
};

export const fetchRestaurantName = () => async dispatch => {
    let isConnected = await checkInternetConnection();
    if(isConnected){
        const res = await axios.get(`${BASE_URL}/api/settings/fetch-restaurant-name`);
        // console.log('res in tax')
        // console.log(res)
        localForage.setItem('fetch-pos-restaurant-tax', res.data)
        dispatch({ type: FETCH_RESTAURANT_NAME, payload: res.data });
    }else{
        let res = await localForage.getItem('fetch-pos-restaurant-tax');
        dispatch({ type: FETCH_RESTAURANT_NAME, payload:res });
    }
};

export const fetchPosComboItemColor = () => async dispatch => {
    let isConnected = await checkInternetConnection();
    if(isConnected){
        const res = await axios.get(`${BASE_URL}/api/pos/fetch_kitchen_combo_item_color`);
        localForage.setItem('fetch-pos-combo-item-color', res.data)
        dispatch({ type: FETCH_POS_COMBO_ITEM_COLOR, payload: res.data });
    }else{
        let res = await localForage.getItem('fetch-pos-combo-item-color');
        dispatch({ type: FETCH_POS_COMBO_ITEM_COLOR, payload:res });
    }
    // console.log('res in tax')
    // console.log(res)
};

export const fetchResturantDeliveryFees = () => async dispatch => {
    let isConnected = await checkInternetConnection();
    if(isConnected){
        const res = await axios.get(`${BASE_URL}/api/settings/fetch-restaurant-delivery-fee`);
        localForage.setItem('fetch-pos-resturant-delivery-fees', res.data)
        dispatch({ type: FETCH_RESTURANT_DELIVERY_FEES, payload: res.data });
    }else{
        let res = await localForage.getItem('fetch-pos-resturant-delivery-fees');
        dispatch({ type: FETCH_RESTURANT_DELIVERY_FEES, payload:res });
    }
    
};

export const fetchResturantAddress = () => async dispatch => {
    let isConnected = await checkInternetConnection();
    if(isConnected){
        const res = await axios.get(`${BASE_URL}/api/settings/fetch-resturant-address`);
        localForage.setItem('fetch-pos-resturant-address', res.data)
        dispatch({ type: FETCH_RESTURANT_ADDRESS, payload: res.data });
        return Promise.resolve(res);
    }else{
        let res = await localForage.getItem('fetch-pos-resturant-address');
        dispatch({ type: FETCH_RESTURANT_ADDRESS, payload:res });
        return Promise.resolve(res);
    }
};

export const fetchResturantLogo = () => async dispatch => {
    let isConnected = await checkInternetConnection();
    if(isConnected){
        const res = await axios.get(`${BASE_URL}/api/settings/fetch-restaurant-logo`);
        localForage.setItem('fetch-pos-restaurant-logo', res.data)
        dispatch({ type: FETCH_RESTAURANT_LOGO, payload: res.data });
    }else{
        let res = await localForage.getItem('fetch-pos-restaurant-logo');
        dispatch({ type: FETCH_RESTAURANT_LOGO, payload:res });
    }
};