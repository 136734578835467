import {
    FETCH_RESTURANT_TAX,
    FETCH_POS_COMBO_ITEM_COLOR,
    FETCH_RESTURANT_DELIVERY_FEES,
    FETCH_RESTURANT_ADDRESS,
    FETCH_RESTAURANT_LOGO,
    FETCH_RESTAURANT_NAME,
    FETCH_RESTAURANT_PROVINCE,
    FETCH_RESTAURANT_CARD_PROCESSING_FEE,
} from '../actions/types';
 

export default function(state={},action){
    switch(action.type){
        case FETCH_RESTURANT_TAX:
            // console.log('action')
            // console.log(action)
            return {...state, objtax: action.payload }
        case FETCH_RESTAURANT_NAME:
            // console.log('action')
            // console.log(action)
            return {...state, objRestaurantName: action.payload }
        case FETCH_POS_COMBO_ITEM_COLOR:
            // console.log('action')
            // console.log(action)
            return {...state, objPosComboItemColor: action.payload }
        case FETCH_RESTURANT_DELIVERY_FEES:
            return {...state, objRestaurantDeliveryFees: action.payload }
        case FETCH_RESTURANT_ADDRESS:
            return {...state, objRestaurantAddress: action.payload }
        case FETCH_RESTAURANT_LOGO:
            return {...state, objRestaurantLogo: action.payload }
        case FETCH_RESTAURANT_PROVINCE:
            return {...state, objRestaurantProvince: action.payload }
        case FETCH_RESTAURANT_CARD_PROCESSING_FEE:
            return {...state, objRestaurantCardProcessingFee: action.payload }
        default:
            return state;
    }
}