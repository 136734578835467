import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import OrderItemOrderReceiptPopup from './components/OrderItemOrderReceiptPopup';
import OrderReceiptPopupRenderSelectedDeliveryType from './components/OrderReceiptPopupRenderSelectedDeliveryType';
import { formatExistingOrderDateTimeOnly } from '../../../../utils/general';
// import './css/print_pos_order.css'
class OrderReceiptPopup extends Component {
    _isMounted = false;
    state = {
        order_id: '',
        isloadingOrder: false,
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
    }

    
    

    render() {

        const objOrder = this.props.objOrder;
        if (Object.keys(objOrder).length === 0 || objOrder === undefined) {
            return (
                null
                // <LoadingSpinner subClass={'mt10 mb10 text-center'} />
            )
        }
        return (
            <Fragment>
                <div className="OrderReceiptPrint print" id="invoice-POS">
                    <center id="top">
                        <div className="info">
                            <h2 className="resturantName">{this.props.restaurant_name}</h2>
                        </div>
                        <div id="mid">
                            <div className="info">
                                <h2 className="OrderNo">Ticket # {objOrder.ticket_no}</h2>
                                {
                                    Object.keys(objOrder.objCustomer).length > 0 && objOrder.objCustomer.phone_no ?
                                        <Fragment>
                                            <p className="CustomerPhoneNo">  {objOrder.objCustomer.phone_no} </p>
                                        </Fragment>
                                        : null
                                }
                                {
                                    Object.keys(objOrder.objCustomer).length > 0 && objOrder.objCustomer.address ?
                                        <Fragment>
                                            <p > {objOrder.objCustomer.address} </p>
                                        </Fragment>
                                        : null
                                }
                                {
                                    objOrder.delivery_type === 'delivery' && Object.keys(objOrder.objCustomer).length > 0  && objOrder.objCustomer.appartment_unit_number !== '' ?
                                    <p>{objOrder.appartment_unit_number}</p>
                                    : null
                                }
                                {
                                    (Object.keys(objOrder.objCustomer).length > 0 && (objOrder.objCustomer.notes && objOrder.objCustomer.notes !== '')) ?
                                        <Fragment><p className="OrderNotes">{objOrder.objCustomer.notes}</p></Fragment>
                                        : null
                                }
                                <OrderReceiptPopupRenderSelectedDeliveryType
                                    selectedOrderType={objOrder.delivery_type}
                                />
                                {
                                    (objOrder.objCustomer !== undefined && objOrder.objCustomer !== null) && Object.keys(objOrder.objCustomer).length > 0 && objOrder.objCustomer.fullname ?
                                        <Fragment>
                                            {/* {(objOrder.objCustomer.phone_no)  ? <br /> : null} */}
                                            <p className="CustomerName"> {objOrder.objCustomer.fullname} </p>
                                        </Fragment>
                                        : null
                                }
                                <p className="OrderCompletionTime"> {formatExistingOrderDateTimeOnly(objOrder.order_completion_datetime)}</p>
                            </div>
                        </div>
                        <div id="bot">
                            <div id="table">
                                <table>
                                    <colgroup>
                                        <col width="85%" />
                                        <col width="15%" />
                                    </colgroup>
                                    <tbody>
                                        <OrderItemOrderReceiptPopup
                                            {...objOrder}
                                        />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </center>
                </div>

            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
    };
}

// export default Home
export default connect(mapStateToProps, { })(OrderReceiptPopup);
