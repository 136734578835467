import React,{Fragment} from 'react';
// import fifthPng from '../../../../../common/images/icons/5.png'
import iconMobilePng from '../../../images/notifications-images/icon-mobile.png'
const Notifications = (props) => {

    const imgStyle = {
        height: "45px"
    }

    return (
        <Fragment>
            {/* // <div className="col"> */}
                {/* // <button onClick={props.showWebNotificationsModal}
                //     className="notification position-relative btn btn-none-bg w-100 py-2 d-inline-flex align-items-center justify-content-end">
                //     <img src={fifthPng} alt="icons" className="mw-100" style={imgStyle} />
                //     <span className="position-absolute">{props.WebOrderNotificationsCount}</span>
                // </button> */}
                <button
                className={`btn btn-danger py-2 d-inline-flex align-items-center justify-content-center notification btnUpperMenu`} onClick={props.showWebNotificationsModal}>
                    <p>
                        <img src={iconMobilePng} alt="icons" className="mw-100 pr-3" />
                        Mobile
                    </p>
                    {
                        props.WebOrderNotificationsCount > 0?
                        <span className="position-absolute">{props.WebOrderNotificationsCount}</span>
                        : null
                    }
                </button>
            {/* // </div> */}

        </Fragment>
    );
};

export default Notifications;
