import React,{Fragment} from 'react';
// import {formatExistingOrderDate,formatExistingOrderDateTimeOnly,formatExistingOrderDateTime,isDateMatches} from '../../../utils/general';
// import PosRenderSelectedDeliveryType from './PosRenderSelectedDeliveryType';
import PosOrderPrintCartItemBlock from './PosOrderPrintCartItemBlock';
import OrderItemPrintReceiptPaidChangeFooter from './OrderItemPrintReceiptPaidChangeFooter';
import { calculateFinalTotalOfOrder } from '../../../utils/helpers/OrdersPages/OrdersHelpers';
// import {formatTodayDate} from '../../../utils/general';
// import RenderDeliveryType from './RenderDeliveryType';
const OrderItemPosPrint = (props) => {
    // const todayDateTime = new Date();
    // console.log('props in OrderItemPosPrint')
    // console.log(props)
    return (
        <Fragment>
                <PosOrderPrintCartItemBlock 
                    products={props.order_details}
                    objOrder={props}
                    type="cart"
                />
                {
                props.is_created_before_card_processing_fee === false ?
                <Fragment>
                <tr className="tabletitle">
                    <td className="Rate">
                        <h2 className={'mt20'}>Discount</h2>
                    </td>
                    <td className="payment">
                        <h2 className={'mt20'}>${parseFloat(props.discount_amount).toFixed(2)}</h2>
                    </td>
                </tr>
                <tr className="tabletitle">
                    <td className="Rate">
                        <h2>Sub total</h2>
                    </td>
                    <td className="payment">
                        <h2>${parseFloat(props.subTotal).toFixed(2)}</h2>
                    </td>
                </tr>
                <tr className="tabletitle">
                    <td className="Rate">
                        <h2>Tax</h2>
                    </td>
                    <td className="payment">
                        <h2>${parseFloat(props.taxAmount).toFixed(2)}</h2>
                    </td>
                </tr>
                <tr className="tabletitle">
                    <td className="Rate">
                        <h2>Total</h2>
                    </td>
                    <td className="payment">
                        <h2>${parseFloat(props.total).toFixed(2)}</h2>
                    </td>
                </tr>
                {
                    props.delivery_type === 'delivery' ?
                    <tr className="tabletitle">
                        <td className="Rate">
                            <h2 className="mt20">Delivery Fee</h2>
                        </td>
                        <td className="payment">
                            <h2 className="mt20">${parseFloat(props.delivery_amount).toFixed(2)}</h2>
                        </td>
                    </tr>
                    : null
                }
                {
                    props.delivery_type === 'delivery'  && props.delivery_payment_method === 'credit' ?
                    <tr className="tabletitle">
                        <td className="Rate">
                            <h2>Card processing fee</h2>
                        </td>
                        <td className="payment">
                            <h2>${parseFloat(props.card_processing_fee).toFixed(2)}</h2>
                        </td>
                    </tr>
                    : null
                }
                {
                    props.delivery_type === 'delivery' ?
                    <tr className="tabletitle">
                        <td className="Rate">
                            <h2>Total including fee</h2>
                        </td>
                        <td className="payment">
                            <h2>${calculateFinalTotalOfOrder(props,props.delivery_payment_method ,props.card_processing_fee)}</h2>
                        </td>
                    </tr>
                    : null
                }
                </Fragment>
                :
                <Fragment>
                {
                    props.delivery_type === 'delivery' ?
                    <tr className="tabletitle">
                        <td className="Rate">
                            <h2 className="mt20">Delivery Fee</h2>
                        </td>
                        <td className="payment">
                            <h2 className="mt20">${parseFloat(props.delivery_amount).toFixed(2)}</h2>
                        </td>
                    </tr>
                    : null
                }
                <tr className="tabletitle">
                    <td className="Rate">
                        <h2 className={'mt20'}>Discount</h2>
                    </td>
                    <td className="payment">
                        <h2 className={'mt20'}>${parseFloat(props.discount_amount).toFixed(2)}</h2>
                    </td>
                </tr>
                <tr className="tabletitle">
                    <td className="Rate">
                        <h2>Sub total</h2>
                    </td>
                    <td className="payment">
                        <h2>${parseFloat(props.total_amount).toFixed(2)}</h2>
                    </td>
                </tr>
                <tr className="tabletitle">
                    <td className="Rate">
                        <h2>Tax</h2>
                    </td>
                    <td className="payment">
                        <h2>${parseFloat(props.tax_amount).toFixed(2)}</h2>
                    </td>
                </tr>
                <tr className="tabletitle">
                    <td className="Rate">
                        <h2>Total</h2>
                    </td>
                    <td className="payment">
                        <h2>${parseFloat(props.total_amount_after_tax_delivery_charges).toFixed(2)}</h2>
                    </td>
                </tr>
                </Fragment> 
                }
                <br/>
                <OrderItemPrintReceiptPaidChangeFooter
                    ObjOrder={props}
                />
        </Fragment>
                
                    

                

    );
}



export default OrderItemPosPrint;