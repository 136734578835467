import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TabletExistingOrdersNotificationModalBody from './TabletExistingOrdersNotificationModalBody';
class TabletExistingOrdersNotificationModal extends React.Component {
    constructor(props) {
        super(props);
        /* this.state = {
            modal: props.showExistingTabletOrderNotificationModal
        }; */
        this.toggle = this.toggle.bind(this);
    }

    componentWillUnmount(){
        // console.log('componet unmount')
    }

    toggle() {
        
        this.props.handleOnChangeExistingTabletNotificationModalToggle(!this.props.showExistingTabletOrderNotificationModal);
        this.props.clearExistingTabletNotificationOrdersHistory();
        this.props.clearExistingTabletNotificationOrderLimits();
        
    }
    
    render() {
        /* console.log('this.state.modal in WebOrdersNotificationModal')
        console.log(this.state.modal)
        console.log('this.props.showExistingTabletOrderNotificationModal')
        console.log(this.props.showExistingTabletOrderNotificationModal) */
        // console.log('this.props.limitExstingOrders')
        // console.log('this.props.limitExstingOrders')
        // console.log(this.props.limitExstingOrders)
        
        return (
                <Modal isOpen={this.props.showExistingTabletOrderNotificationModal} toggle={this.toggle} className={this.props.className} size="lg">
                    <ModalHeader toggle={this.toggle}>Existing Kiosk Notification Orders</ModalHeader>
                    <ModalBody>
                        <TabletExistingOrdersNotificationModalBody 
                            order={this.props.order}
                            onClickHandleLoadMoreExistingTabletNotificationOrderItems={this.props.onClickHandleLoadMoreExistingTabletNotificationOrderItems}
                            limitTabletExistingNotificationOrders={this.props.limitTabletExistingNotificationOrders}
                            isloadingExistingTabletNotificationOrders={this.props.isloadingExistingTabletNotificationOrders}
                            handelOnClickTabletNewOrder={this.props.handelOnClickTabletNewOrder}
                        />
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.toggle}>Done</Button>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter> */}
                </Modal>
        );
    }
};

export default TabletExistingOrdersNotificationModal;