import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ExistingOrdersModalBody from './ExistingOrdersModalBody';
class ExistingOrdersModal extends React.Component {
    constructor(props) {
        super(props);
        /* this.state = {
            modal: props.showExistingOrdersModal
        }; */
        this.toggle = this.toggle.bind(this);
    }

    componentWillUnmount(){
        // console.log('componet unmount')
        // this.props.clearExistingOrderHistory();
        // this.props.clearExistingOrderLimits();
    }

    toggle() {
        /* this.setState({
            modal: !this.props.showExistingOrdersModal
            // modal: !this.state.modal
        }); */
        this.props.handleOnChangeExistingOrderModalToggle(!this.props.showExistingOrdersModal);
        this.props.clearExistingOrderHistory();
        this.props.clearExistingOrderLimits();
        /* this.setState(prevState => ({
            modal: !prevState.modal
        })); */
    }
    
    render() {
        /* console.log('this.state.modal in ExistingOrdersModal')
        console.log(this.state.modal)
        console.log('this.props.showExistingOrdersModal')
        console.log(this.props.showExistingOrdersModal) */
        // console.log('this.props.limitExstingOrders')
        // console.log('this.props.limitExstingOrders')
        // console.log(this.props.limitExstingOrders)
        
        return (
                <Modal isOpen={this.props.showExistingOrdersModal} toggle={this.toggle} className={this.props.className} size="lg">
                    <ModalHeader toggle={this.toggle}>Existing Orders</ModalHeader>
                    <ModalBody>
                        <ExistingOrdersModalBody 
                            order={this.props.order}
                            onClickHandleLoadMoreExistingOrderItems={this.props.onClickHandleLoadMoreExistingOrderItems}
                            limitExstingOrders={this.props.limitExstingOrders}
                            isloadingExistingOrders={this.props.isloadingExistingOrders}
                            handelOnClickExistingOrder={this.props.handelOnClickExistingOrder}
                        />
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.toggle}>Done</Button>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter> */}
                </Modal>
        );
    }
};

export default ExistingOrdersModal;