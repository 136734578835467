import React,{Fragment} from 'react'
import { connect } from 'react-redux';
import io from "socket.io-client";
import {BASE_URL} from '../utils/misc';

var socket;
// const Header = (props) => {
class Header extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            endpoint: BASE_URL // Update 5000 with port on which backend-my-app/server.js is running.
            // endpoint: "http://jubzi.com/" // Update 5000 with port on which backend-my-app/server.js is running.
            // endpoint: "http://localhost:5000/" // Update 5000 with port on which backend-my-app/server.js is running.
            // endpoint: "http://localhost:3001/" // Update 3001 with port on which backend-my-app/server.js is running.
        };
        // socket = io(this.state.endpoint,{});
        // console.log('this.state.endpoint')
        // console.log(this.state.endpoint)
        const  connectionOptions =  {
            "force new connection" : true,
            "reconnection": true,
            "reconnectionDelay": 2000,                  //starts with 2 secs delay, then 4, 6, 8, until 60 where it stays forever until it reconnects
            "reconnectionDelayMax" : 60000,             //1 minute maximum delay between connections
            "reconnectionAttempts": "Infinity",         //to prevent dead clients, having the user to having to manually reconnect after a server restart.
            "timeout" : 10000,                           //before connect_error and connect_timeout are emitted.
            "transports" : ["websocket"]                //forces the transport to be only websocket. Server needs to be setup as well/
        }
        socket = io(this.state.endpoint,connectionOptions);
        // socket = io(this.state.endpoint,{ reconnection:true,reconnectionDelay : 1500,reconnect: true ,transports: ['websocket']});
        // socket = io(this.state.endpoint,{transports: ['websocket'],});
        // console.log('socket in constructor')
        // console.log(socket)
    }
      
   
    
    render (){
     
        return (
            <Fragment>
            </Fragment>
        )
    }
}




function mapStateToProps(state){
    return {
        // is_resturant_open:state.is_resturant_open
    }
}
// console.log('socket in header');
// console.log(socket)
export  { socket };
// export default Header;
export default connect(mapStateToProps,{})(Header);
// export default connect(mapStateToProps, { fetchResturantTax,removeCartItem,removeCartSubItem })(withRouter(Cart));


