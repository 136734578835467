import {
    ADD_TO_CART_USER,
    GET_CART_ITEMS_USER,
    REMOVE_CART_ITEM_USER,
    REMOVE_INGREDIENT_FROM_MENU_ITEM_CART,
    REMOVE_CART_SUB_ITEM_USER,
    REMOVE_INGREDIENT_FROM_COMBO_ITEM_CART,
    CLEAR_CART,
    ADD_COMBO_ITEM_TO_MENU_ITEM,
    ADD_INGREDIENT_TO_MENU_ITEM_CART,
    ADD_INGREDIENT_TO_MENU_ITEM_COMBO_ITEM_CART,
    ADD_NOTES_WITH_EXTRA_CHARGE,
	ADD_NOTES_WITH_EXTRA_CHARGE_TO_CART_MENU_ITEM,
    ADD_NOTES_WITH_EXTRA_CHARGE_TO_CART_MENU_ITEM_COMBO_ITEM,
    REMOVE_CART_ITEM_NOTES,
    ADD_REFUNDED_ITEM_TO_CART,
    FILL_CART_WITH_EXISTING_ORDER,
    ADD_INCLUDED_AND_BOTH_INGREDIENTS_TO_MENU_ITEM,
    ADD_INCLUDED_AND_BOTH_INGREDIENTS_TO_COMBO_MENU_ITEM,
} from '../actions/types';
 

export default function(state=[],action){
    switch(action.type){
        case ADD_TO_CART_USER:{
            let items = state.slice();
            let menuItems = items.filter((item, index) => item.item_type === "menu_item");
            let notesItems = items.filter((item, index) => item.item_type === "notes");
            let refundedItems = items.filter((item, index) => item.item_type === "refunded_item");
            let newState = [
                // ...item.ingredients,
                ...menuItems,
                action.payload,
                ...notesItems,
                ...refundedItems,
            ]
            return newState;
            /* return [...state, 
                action.payload,
            ] */
        }
        case CLEAR_CART:
            return action.payload;
        case GET_CART_ITEMS_USER:
            return [...state,action.payload ]
        case REMOVE_CART_ITEM_USER:{
            /* console.log('action.payload in REMOVE_CART_ITEM_USER')
            console.log(action.payload)
            console.log('state is')
            console.log(state) */
            // menu_item_id
            // timestamp
            // Removes the item from cart array without directly mutating the state.
            // The Array.prototype.filter() method prevents us from mutating the array

            const itemIndex = state.findIndex(i => i.menu_item_id === action.payload.menu_item_id &&  i.timestamp === action.payload.timestamp);
            // console.log('itemIndex in REMOVE_CART_ITEM_USER')
            // console.log(itemIndex)
            // console.log('state.filter((item, index) => index !== itemIndex)')
            // console.log(state.filter((item, index) => index !== itemIndex))
            return state.filter((item, index) => index !== itemIndex);
        }
        case ADD_INCLUDED_AND_BOTH_INGREDIENTS_TO_MENU_ITEM:{
            let objMenuItem = action.payload.objMenuItem;
            let Ingredients = action.payload.Ingredients;
            let items = state.slice();
            const itemIndex = items.findIndex(item => item.menu_item_id === objMenuItem.menu_item_id &&  item.timestamp === objMenuItem.timestamp);
            items[itemIndex].includedBothIngredients = Ingredients;  
            return items;
        }   
        case ADD_COMBO_ITEM_TO_MENU_ITEM:{
            let menu_item_id = action.payload.menu_item_id;
            let timestamp = action.payload.timestamp;
            let comboItem = action.payload.comboItem;
            let items = state.slice();
            const itemIndex = items.findIndex(item => item.menu_item_id === menu_item_id &&  item.timestamp === timestamp);
            items[itemIndex].totalPrice = (parseFloat(items[itemIndex].totalPrice) + parseFloat(comboItem.combo_item_price)).toFixed(2);  
            items[itemIndex].selectedComboItems.push(comboItem);
            return items;
        }
        case ADD_INCLUDED_AND_BOTH_INGREDIENTS_TO_COMBO_MENU_ITEM:{
            let menu_item_id = action.payload.menu_item_id;
            let timestamp = action.payload.timestamp;
            let comboItem = action.payload.comboItem;
            let Ingredients = action.payload.Ingredients;
            let items = state.slice();
            const itemIndex = items.findIndex(item => item.menu_item_id === menu_item_id &&  item.timestamp === timestamp);
            const comboItemIndex = items[itemIndex].selectedComboItems.findIndex(cmitem => cmitem.selectedComboItemId === comboItem.selectedComboItemId );
            items[itemIndex].selectedComboItems[comboItemIndex].includedBothIngredients = Ingredients;
            return items;
        }
        case ADD_INGREDIENT_TO_MENU_ITEM_COMBO_ITEM_CART:{
            let menu_item_id = action.payload.menu_item_id;
            let timestamp = action.payload.timestamp;
            let combo_menu_item_id = action.payload.combo_menu_item_id;
            let ingredient = action.payload.ingredient;
            let selectedOnReceiptItemType = action.payload.selectedOnReceiptItemType;
            let selectedOnReceiptMenuItemComboItemIngredient = action.payload.selectedOnReceiptMenuItemComboItemIngredient;
            console.log('selectedOnReceiptItemType')
            console.log(selectedOnReceiptItemType)
            let items = state.slice();
            const itemIndexi = items.findIndex(item => item.menu_item_id === menu_item_id &&  item.timestamp === timestamp);
            const comboItemIndex = items[itemIndexi].selectedComboItems.findIndex(cmitem => cmitem.selectedComboItemId === combo_menu_item_id );
            if(selectedOnReceiptItemType ==='combo_item_ingredient'){
                const insert = (arr, index, newItem) => [
                    // part of the array before the specified index
                    ...arr.slice(0, index),
                    // inserted item
                    newItem,
                    // part of the array after the specified index
                    ...arr.slice(index)
                ]
                if(ingredient.is_note){
                    let ingredients = [
                            ...items[itemIndexi].selectedComboItems[comboItemIndex].ingredients,
                            ingredient,
                        ]
                    items[itemIndexi].selectedComboItems[comboItemIndex].ingredients = ingredients;
                }else{
                    const selectedonReceiptComboItemIngredientIndex = items[itemIndexi].selectedComboItems[comboItemIndex].ingredients.findIndex(ing => ing._id === selectedOnReceiptMenuItemComboItemIngredient._id &&  ing.ingredient_category_type_blue === selectedOnReceiptMenuItemComboItemIngredient.ingredient_category_type_blue &&  ing.combo_item_ingredient_timestamp === selectedOnReceiptMenuItemComboItemIngredient.combo_item_ingredient_timestamp);
                    // console.log('selectedonReceiptComboItemIngredientIndex')
                    // console.log(selectedonReceiptComboItemIngredientIndex)
                    // console.log(items[itemIndexi].selectedComboItems[comboItemIndex].ingredients.slice(0, selectedonReceiptComboItemIngredientIndex))
                    // console.log(items[itemIndexi].selectedComboItems[comboItemIndex].ingredients.slice(selectedonReceiptComboItemIngredientIndex))
                    //  we are adding 1 because we want to insert a item after a index
                    let selectedonReceiptComboItemIngredientIndexAfterIndex = selectedonReceiptComboItemIngredientIndex + 1;
                    const result = insert(items[itemIndexi].selectedComboItems[comboItemIndex].ingredients, selectedonReceiptComboItemIngredientIndexAfterIndex, ingredient)
                    items[itemIndexi].selectedComboItems[comboItemIndex].ingredients = result;
                }
            }else{
                let notesIngredients = items[itemIndexi].selectedComboItems[comboItemIndex].ingredients.filter((item, index) => item.is_note === true);
                let itemIngredients = items[itemIndexi].selectedComboItems[comboItemIndex].ingredients.filter((item, index) => item.is_note === false);
                let ingredients = [
                        // ...item.ingredients,
                        ...itemIngredients,
                        ingredient,
                        ...notesIngredients
                    ]
                items[itemIndexi].selectedComboItems[comboItemIndex].ingredients = ingredients;
                // items[itemIndexi].selectedComboItems[comboItemIndex].ingredients.push(ingredient);
            }

            if((ingredient.ingredientType === 'extras_charged' && ingredient.is_checked === true) || (ingredient.ingredientType === 'both' && ingredient.is_checked === true)){
                items[itemIndexi].totalPrice = (parseFloat(items[itemIndexi].totalPrice) + parseFloat(ingredient.price)).toFixed(2);  
                items[itemIndexi].selectedComboItems[comboItemIndex].ingredientTotal = (parseFloat(items[itemIndexi].selectedComboItems[comboItemIndex].ingredientTotal) + parseFloat(ingredient.price)).toFixed(2);  
            }
            // console.log('next items')
            // console.log(items)
            return items;
        }
        case REMOVE_INGREDIENT_FROM_MENU_ITEM_CART:{
            let {menuItem,ingredient} = action.payload;
            // New code
            console.log("state in REMOVE_INGREDIENT_FROM_MENU_ITEM_CART");
            console.log("state in REMOVE_INGREDIENT_FROM_MENU_ITEM_CART");
            console.log(state)
            console.log("ingredient.timestamp in REMOVE_INGREDIENT_FROM_MENU_ITEM_CART");
            console.log("ingredient.timestamp in REMOVE_INGREDIENT_FROM_MENU_ITEM_CART");
            console.log(ingredient.timestamp)
            
            const nextState = state.map(item => {
                if (item.menu_item_id !== menuItem.menu_item_id) {
                // if (item.menu_item_id !== menuItem.menu_item_id &&  item.timestamp !== menuItem.timestamp) {
                        // not our item, return it as is
                    return item;
                }
                if(item.timestamp !== menuItem.timestamp){
                    return item;
                }
                let ingredientTotal,totalPrice = 0;
                if((ingredient.ingredientType === 'extras_charged' && ingredient.is_checked === true) || (ingredient.ingredientType === 'both' && ingredient.is_checked === true)){
                    ingredientTotal = parseFloat(parseFloat(item.ingredientTotal) - parseFloat(ingredient.price)).toFixed(2);
                    totalPrice = (parseFloat(item.totalPrice) - parseFloat(ingredient.price)).toFixed(2);  
                }else if(ingredient.is_note  && ingredient.is_paid_notes){
                    ingredientTotal = parseFloat(parseFloat(item.ingredientTotal) - parseFloat(ingredient.price)).toFixed(2);
                    totalPrice = (parseFloat(item.totalPrice) - parseFloat(ingredient.price)).toFixed(2);  
                }else{
                    ingredientTotal = item.ingredientTotal
                    totalPrice = item.totalPrice;
                }
                // let ingredients = item.ingredients.filter((ingre, index) => (ingre._id !== ingredient._id));
                // let ingredients = item.ingredients.filter((ingre, index) => ((ingre._id !== ingredient._id && ingre.ingredient_category_type_blue !== ingredient.ingredient_category_type_blue)));
                
                /* const ingredients = item.ingredients.map(ingre => {
                    if (ingre._id === ingredient._id && ingre.ingredient_category_type_blue === ingredient.ingredient_category_type_blue) {
                        // not our item, return it as is
                        // return false;
                    }
                    return ingre;
                }); */
                let ingredients = [];
                if(ingredient.is_note){
                    ingredients= item.ingredients.filter(function(ingre) {
                        if (ingre.timestamp === ingredient.timestamp) 
                            return false;
                        return true;
                    });
                }else{
                    ingredients= item.ingredients.filter(function(ingre) {
                        // if (ingre._id === ingredient._id && ingre.ingredient_category_type_blue === ingredient.ingredient_category_type_blue) 
                        if (ingre._id === ingredient._id && ingre.menu_item_ingredient_timestamp === ingredient.menu_item_ingredient_timestamp && ingre.ingredient_category_type_blue === ingredient.ingredient_category_type_blue) 
                            return false;
                        return true;
                    });
                }
                console.log('ingredients in REMOVE_INGREDIENT_FROM_MENU_ITEM_CART')
                console.log('ingredients in REMOVE_INGREDIENT_FROM_MENU_ITEM_CART')
                console.log(ingredients)

                // this is our relevant item, return a new copy of it with modified fields
                return {
                    ...item,
                    ingredientTotal: ingredientTotal,
                    totalPrice: totalPrice,
                    // ingredientTotal: ingredient.price,
                    ingredients: ingredients
                    /* ingredients: [
                        ...item.ingredients,
                        ingredient
                    ] */
                }
            });
            // console.log('nextState')
            // console.log(nextState)
            return nextState;
        }
        case REMOVE_CART_SUB_ITEM_USER:{
            
            let {product,subProduct} = action.payload;
            let items = state.slice();
            const itemIndexi = items.findIndex(item => item.menu_item_id === product.menu_item_id &&  item.timestamp === product.timestamp);
            if(items[itemIndexi].selectedComboItems.length > 0){
                const subItemIndex = items[itemIndexi].selectedComboItems.findIndex(i => i.selectedComboItemId === subProduct.selectedComboItemId);
                    // console.log('subItemIndex')
                    // console.log(subItemIndex)
                    // console.log('state.filter((item, index) => index !== subItemIndex)')
                    // console.log(state.filter((item, index) => index !== subItemIndex))
                let subItemPrice = (parseFloat(subProduct.ingredientTotal) +parseFloat(subProduct.combo_item_price)).toFixed(2)
                items[itemIndexi].totalPrice = (parseFloat(items[itemIndexi].totalPrice) - parseFloat(subItemPrice)).toFixed(2);  
                items[itemIndexi].selectedComboItems = items[itemIndexi].selectedComboItems.filter((item, index) => index !== subItemIndex);
            }
            // This finds the item that is already available in the cart array and updates 
            // the item's quantity without mutating directly the State
            // Always make a copy of the state and return a new state.hat will trigger a re-render
            // menu_item_id
            // timestamp
            // Removes the item from cart array without directly mutating the state.
            // The Array.prototype.filter() method prevents us from mutating the array

            // const itemIndex = state.findIndex(i => i.menu_item_id === action.payload.menu_item_id &&  i.timestamp === action.payload.timestamp);
            // console.log('itemIndex')
            // console.log(itemIndex)
            return items;
            // console.log('state.filter((item, index) => index !== itemIndex)')
            // console.log(state.filter((item, index) => index !== itemIndex))
            // return state.filter((item, index) => index !== itemIndex);
        }
        case REMOVE_INGREDIENT_FROM_COMBO_ITEM_CART:{
            let {menuItem,comboItem,ingredient} = action.payload;
            let items = state.slice();
            const itemIndexi = items.findIndex(item => item.menu_item_id === menuItem.menu_item_id &&  item.timestamp === menuItem.timestamp);
            if(items[itemIndexi].selectedComboItems.length > 0){
                const subItemIndex = items[itemIndexi].selectedComboItems.findIndex(i => i.selectedComboItemId === comboItem.selectedComboItemId);
                let subItemIngredientIndex = '';
                if(ingredient.is_note){
                    subItemIngredientIndex = items[itemIndexi].selectedComboItems[subItemIndex].ingredients.findIndex(ingre => ingre.timestamp === ingredient.timestamp );
                }else{
                    subItemIngredientIndex = items[itemIndexi].selectedComboItems[subItemIndex].ingredients.findIndex(ingre => ingre._id === ingredient._id && ingre.ingredient_category_type_blue === ingredient.ingredient_category_type_blue);
                }

                if((ingredient.ingredientType === 'extras_charged' && ingredient.is_checked === true) || (ingredient.ingredientType === 'both' && ingredient.is_checked === true)){
                    items[itemIndexi].selectedComboItems[subItemIndex].ingredientTotal = parseFloat(parseFloat(items[itemIndexi].selectedComboItems[subItemIndex].ingredientTotal) - parseFloat(ingredient.price)).toFixed(2);
                    items[itemIndexi].totalPrice = (parseFloat(items[itemIndexi].totalPrice) - parseFloat(ingredient.price)).toFixed(2);  
                }else if(ingredient.is_note  && ingredient.is_paid_notes){
                    items[itemIndexi].selectedComboItems[subItemIndex].ingredientTotal = parseFloat(parseFloat(items[itemIndexi].selectedComboItems[subItemIndex].ingredientTotal) - parseFloat(ingredient.price)).toFixed(2);
                    items[itemIndexi].totalPrice = (parseFloat(items[itemIndexi].totalPrice) - parseFloat(ingredient.price)).toFixed(2);  
                }
                
                    // console.log('subItemIndex')
                    // console.log(subItemIndex)
                    // console.log('state.filter((item, index) => index !== subItemIndex)')
                    // console.log(state.filter((item, index) => index !== subItemIndex))
                items[itemIndexi].selectedComboItems[subItemIndex].ingredients = items[itemIndexi].selectedComboItems[subItemIndex].ingredients.filter((item, index) => index !== subItemIngredientIndex);
            }
           
            return items;
   
        }
        case ADD_INGREDIENT_TO_MENU_ITEM_CART:{
            // let menu_item_id = action.payload.menu_item_id;
            // let ingredient = action.payload.ingredient;
            // let timestamp = action.payload.timestamp;
            // console.log('eee')
            const { menu_item_id, ingredient , timestamp , selectedOnReceiptItemType , selectedOnReceiptMenuItemIngredient  } = action.payload;
            
            // console.log('timestamp')
            // console.log(timestamp)
            // console.log('menu_item_id')
            // console.log(menu_item_id)
            // New code
            const nextState = state.map(item => {
                if (item.menu_item_id !== menu_item_id) {
                    // not our item, return it as is
                    return item;
                }
                // console.log('(item.menu_item_id !== menu_item_id &&  item.timestamp !== timestamp)')
                // console.log((item.menu_item_id !== menu_item_id &&  item.timestamp !== timestamp))
                // the above and condition was not working so i have to add seperate conditions
                if(item.timestamp !== timestamp){
                    return item;
                }
                // console.log('item')
                // console.log(item)
                // console.log(item.timestamp )
                let ingredientTotal,totalPrice = 0;
                if((ingredient.ingredientType === 'extras_charged' && ingredient.is_checked === true) || (ingredient.ingredientType === 'both' && ingredient.is_checked === true)){
                    ingredientTotal = parseFloat(parseFloat(item.ingredientTotal) + parseFloat(ingredient.price)).toFixed(2);
                    totalPrice = (parseFloat(item.totalPrice) + parseFloat(ingredient.price)).toFixed(2);  
                }else{
                    ingredientTotal = item.ingredientTotal
                    totalPrice = item.totalPrice;
                }
                let ingredients = [];
                // console.log('selectedOnReceiptItemType')
                // console.log('selectedOnReceiptItemType')
                // console.log('selectedOnReceiptItemType')
                console.log('selectedOnReceiptItemType')
                console.log(selectedOnReceiptItemType)
                if(selectedOnReceiptItemType ==='menu_item_ingredient'){
                    const insert = (arr, index, newItem) => [
                        // part of the array before the specified index
                        ...arr.slice(0, index),
                        // inserted item
                        newItem,
                        // part of the array after the specified index
                        ...arr.slice(index)
                    ]
                    const selectedonReceiptIngredientIndex = item.ingredients.findIndex(ing => ing._id === selectedOnReceiptMenuItemIngredient._id &&  ing.ingredient_category_type_blue === selectedOnReceiptMenuItemIngredient.ingredient_category_type_blue &&  ing.menu_item_ingredient_timestamp === selectedOnReceiptMenuItemIngredient.menu_item_ingredient_timestamp);
                    // console.log('selectedonReceiptIngredientIndex')
                    // console.log(selectedonReceiptIngredientIndex)
                    // console.log(item.ingredients.slice(0, selectedonReceiptIngredientIndex))
                    // console.log(item.ingredients.slice(selectedonReceiptIngredientIndex))
                    // console.log('ingredients.is_note')
                    // console.log('ingredients.is_note')
                    // console.log('ingredients.is_note')
                    // console.log(ingredients.is_note)
                    if(ingredient.is_note){
                        ingredients = [
                            ...item.ingredients,
                            ingredient,
                        ]
                    }else{
                        //  we are adding 1 because we want to insert a item after a index
                        let selectedonReceiptIngredientIndexAfterIndex = selectedonReceiptIngredientIndex + 1;
                        const result = insert(item.ingredients, selectedonReceiptIngredientIndexAfterIndex, ingredient)
                        ingredients = result;
                    }
                }else{
                    // console.log('I am in else')
                    // console.log('I am in else')
                    // console.log('I am in else')
                    let itemIngredients = item.ingredients.filter((item, index) => item.is_note === false);
                    let notesIngredients = item.ingredients.filter((item, index) => item.is_note === true);
                    // let itemOriginalIncludedBothIngredients = item.ingredients.filter((item, index) => item.is_note === false && item.is_checked === false && item.isOriginalIncludedBothIngredient);
                    // let objOriginalIncludedBothIngredientIndex = item.ingredients.findIndex(item => item.is_note === false && item.is_checked === false && item.isOriginalIncludedBothIngredient && item.ingredient_id === ingredient.ingredient_id);
                    // let objOriginalIncludedBothIngredient = item.ingredients.find(item => item.is_note === false && item.is_checked === false && item.isOriginalIncludedBothIngredient && item.ingredient_id === ingredient.ingredient_id);
                    // console.log('item.ingredients')
                    // console.log(item.ingredients)
                    // console.log('notesIngredients')
                    // console.log(notesIngredients)
                    // console.log('itemIngredients')
                    // console.log(itemIngredients)
                    // console.log('ingredient in cart_reducer')
                    // console.log('ingredient in cart_reducer')
                    // console.log(ingredient)
                    // console.log('itemOriginalIncludedBothIngredients')
                    // console.log(itemOriginalIncludedBothIngredients)
                    // console.log('objOriginalIncludedBothIngredient')
                    // console.log(objOriginalIncludedBothIngredient)
                    // if(objOriginalIncludedBothIngredient !== undefined && itemOriginalIncludedBothIngredients.length > 0 && ingredient.ingredient_category_type_blue === 'no'  ){
                    //     itemIngredients.splice(objOriginalIncludedBothIngredientIndex, 1);
                    //     console.log('itemIngredients.length')
                    //     console.log(itemIngredients.length)
                    //     objOriginalIncludedBothIngredient.is_checked = true;
                    //     ingredients = [
                    //         // ...item.ingredients,
                    //         ...itemIngredients,
                    //         objOriginalIncludedBothIngredient,
                    //         ...notesIngredients
                    //     ]
                    // }else{
                    //     ingredients = [
                    //         // ...item.ingredients,
                    //         ...itemIngredients,
                    //         ingredient,
                    //         ...notesIngredients
                    //     ]
                    // }
                    ingredients = [
                        // ...item.ingredients,
                        ...itemIngredients,
                        ingredient,
                        ...notesIngredients
                    ]
                    // console.log('after ingredients')
                    // console.log(ingredients)
                }
                // this is our relevant item, return a new copy of it with modified fields
                return {
                    ...item,
                    ingredientTotal: ingredientTotal,
                    totalPrice: totalPrice,
                    ingredients:ingredients
                    // ingredientTotal: ingredient.price,
                    
                }
            });
            // console.log('nextState')
            // console.log(nextState)
            return nextState;
            // old code
            /* let items1 = state.slice();
            const itemIndexi1 = items1.findIndex(item => item.menu_item_id === menu_item_id &&  item.timestamp === timestamp);
            if((ingredient.ingredientType === 'extras_charged' && ingredient.is_checked === true) || (ingredient.ingredientType === 'both' && ingredient.is_checked === true)){
                items1[itemIndexi1].ingredientTotal = parseFloat(parseFloat(items1[itemIndexi1].ingredientTotal) + parseFloat(ingredient.price)).toFixed(2);
            }
            console.log('i am in ADD_INGREDIENT_TO_MENU_ITEM_CART')
            console.log('items1[itemIndexi1].ingredientTotal')
            console.log(items1[itemIndexi1].ingredientTotal)
            items1[itemIndexi1].ingredients.push(ingredient);
            console.log('items1')
            console.log(items1)
            console.log('state')
            console.log(state)
            return items1; */
        }
        case ADD_NOTES_WITH_EXTRA_CHARGE:{
            let {notes,extra_charges,timestamp} = action.payload;
            let obj = {};
            obj.notes = notes;
            obj.timestamp = timestamp;
            obj.item_type = 'notes';
            if(extra_charges === 'no'){
                obj.extra_charges = 0;
				obj.totalPrice = 0;
				obj.is_paid_notes = false;
            }else{
                obj.extra_charges = extra_charges;
				obj.totalPrice = extra_charges;
				obj.is_paid_notes = true;
            }
            obj.is_note = true;
            let items = state.slice();
            let menuItems = items.filter((item, index) => item.item_type === "menu_item");
            let notesItems = items.filter((item, index) => item.item_type === "notes");
            let refundedItems = items.filter((item, index) => item.item_type === "refunded_item");
            let newState = [
                // ...item.ingredients,
                ...menuItems,
                ...notesItems,
                obj,
                ...refundedItems,
            ]
            return newState;
            /* return [...state, 
                obj
            ] */   
        }
        case ADD_NOTES_WITH_EXTRA_CHARGE_TO_CART_MENU_ITEM:{
            const { menu_item_id, ingredient , timestamp , selectedOnReceiptItemType , selectedOnReceiptMenuItemIngredient  } = action.payload;
            
            
            // New code
            const nextState = state.map(item => {
                if (item.menu_item_id !== menu_item_id) {
                // if ((item.menu_item_id !== menu_item_id &&  item.timestamp !== timestamp)) {
                        // not our item, return it as is
                    // console.log('item in return')
                    // console.log(item)
                    return item;
                }
                // console.log('(item.menu_item_id !== menu_item_id &&  item.timestamp !== timestamp)')
                // console.log((item.menu_item_id !== menu_item_id &&  item.timestamp !== timestamp))
                // the above and condition was not working so i have to add seperate conditions
                if(item.timestamp !== timestamp){
                    return item;
                }
                // console.log('item')
                // console.log(item)
                // console.log(item.timestamp )
                let ingredientTotal,totalPrice = 0;
                if(ingredient.is_note  && ingredient.is_paid_notes){
                    ingredientTotal = parseFloat(parseFloat(item.ingredientTotal) + parseFloat(ingredient.price)).toFixed(2);
                    totalPrice = (parseFloat(item.totalPrice) + parseFloat(ingredient.price)).toFixed(2);  
                }else{
                    ingredientTotal = item.ingredientTotal;
                    totalPrice = item.totalPrice;
                }
                let ingredients = [];
                // console.log('selectedOnReceiptItemType')
                // console.log('selectedOnReceiptItemType')
                // console.log('selectedOnReceiptItemType')
                // console.log('selectedOnReceiptItemType')
                // console.log(selectedOnReceiptItemType)
                if(selectedOnReceiptItemType ==='menu_item_ingredient'){
                    const insert = (arr, index, newItem) => [
                        // part of the array before the specified index
                        ...arr.slice(0, index),
                        // inserted item
                        newItem,
                        // part of the array after the specified index
                        ...arr.slice(index)
                    ]

                    if(ingredient.is_note){
                        ingredients = [
                            ...item.ingredients,
                            ingredient,
                        ]
                    }else{
                        const selectedonReceiptIngredientIndex = item.ingredients.findIndex(ing => ing._id === selectedOnReceiptMenuItemIngredient._id &&  ing.ingredient_category_type_blue === selectedOnReceiptMenuItemIngredient.ingredient_category_type_blue);
                        //  we are adding 1 because we want to insert a item after a index
                        let selectedonReceiptIngredientIndexAfterIndex = selectedonReceiptIngredientIndex + 1;
                        const result = insert(item.ingredients, selectedonReceiptIngredientIndexAfterIndex, ingredient)
                        ingredients = result;
                    }
                }else{
                    ingredients = [
                        ...item.ingredients,
                        ingredient
                    ]
                }
                // this is our relevant item, return a new copy of it with modified fields
                return {
                    ...item,
                    ingredientTotal: ingredientTotal,
                    totalPrice: totalPrice,
                    ingredients:ingredients
                    // ingredientTotal: ingredient.price,
                    
                }
            }); 
            return nextState;
		}
		case ADD_NOTES_WITH_EXTRA_CHARGE_TO_CART_MENU_ITEM_COMBO_ITEM:{
            const { menu_item_id, ingredient , timestamp , selectedOnReceiptItemType , selectedOnReceiptMenuItemIngredient,combo_menu_item_id,selectedOnReceiptMenuItemComboItemIngredient  } = action.payload;
			
            let items = state.slice();
            const itemIndexi = items.findIndex(item => item.menu_item_id === menu_item_id &&  item.timestamp === timestamp);
            const comboItemIndex = items[itemIndexi].selectedComboItems.findIndex(cmitem => cmitem.selectedComboItemId === combo_menu_item_id );
            if(selectedOnReceiptItemType ==='combo_item_ingredient'){
                const insert = (arr, index, newItem) => [
                    // part of the array before the specified index
                    ...arr.slice(0, index),
                    // inserted item
                    newItem,
                    // part of the array after the specified index
                    ...arr.slice(index)
                ]
                if(ingredient.is_note){
                    let ingredients = [
                            ...items[itemIndexi].selectedComboItems[comboItemIndex].ingredients,
                            ingredient,
                        ]
                    items[itemIndexi].selectedComboItems[comboItemIndex].ingredients = ingredients;
                }else{
                    const selectedonReceiptComboItemIngredientIndex = items[itemIndexi].selectedComboItems[comboItemIndex].ingredients.findIndex(ing => ing._id === selectedOnReceiptMenuItemComboItemIngredient._id &&  ing.ingredient_category_type_blue === selectedOnReceiptMenuItemComboItemIngredient.ingredient_category_type_blue);
                    // console.log('selectedonReceiptComboItemIngredientIndex')
                    // console.log(selectedonReceiptComboItemIngredientIndex)
                    // console.log(items[itemIndexi].selectedComboItems[comboItemIndex].ingredients.slice(0, selectedonReceiptComboItemIngredientIndex))
                    // console.log(items[itemIndexi].selectedComboItems[comboItemIndex].ingredients.slice(selectedonReceiptComboItemIngredientIndex))
                    //  we are adding 1 because we want to insert a item after a index
                    let selectedonReceiptComboItemIngredientIndexAfterIndex = selectedonReceiptComboItemIngredientIndex + 1;
                    const result = insert(items[itemIndexi].selectedComboItems[comboItemIndex].ingredients, selectedonReceiptComboItemIngredientIndexAfterIndex, ingredient)
                    items[itemIndexi].selectedComboItems[comboItemIndex].ingredients = result;
                }    
            }else{
                let notesIngredients = items[itemIndexi].selectedComboItems[comboItemIndex].ingredients.filter((item, index) => item.is_note === true);
                let itemIngredients = items[itemIndexi].selectedComboItems[comboItemIndex].ingredients.filter((item, index) => item.is_note === false);
                // console.log('notesIngredients in else')
                // console.log(notesIngredients)
                // console.log('itemIngredients in else')
                // console.log(itemIngredients)
                let ingredients = [];
                if(ingredient.is_note){
                    ingredients = [
                        // ...item.ingredients,
                        ...itemIngredients,
                        ...notesIngredients,
                        ingredient,
                    ]
                }else{
                    ingredients = [
                        // ...item.ingredients,
                        ...itemIngredients,
                        ingredient,
                        ...notesIngredients
                    ]
                }
                // console.log('ingredients')
                // console.log(ingredients)
                items[itemIndexi].selectedComboItems[comboItemIndex].ingredients = ingredients;
                // items[itemIndexi].selectedComboItems[comboItemIndex].ingredients.push(ingredient);
            }

			if(ingredient.is_note  && ingredient.is_paid_notes){
                items[itemIndexi].totalPrice = (parseFloat(items[itemIndexi].totalPrice) + parseFloat(ingredient.price)).toFixed(2);  
                items[itemIndexi].selectedComboItems[comboItemIndex].ingredientTotal = (parseFloat(items[itemIndexi].selectedComboItems[comboItemIndex].ingredientTotal) + parseFloat(ingredient.price)).toFixed(2);  
            }
            return items;
        }
        case REMOVE_CART_ITEM_NOTES:{
            
            // timestamp
            // Removes the item from cart array without directly mutating the state.
            // The Array.prototype.filter() method prevents us from mutating the array

            const itemIndex = state.findIndex(i => i.timestamp === action.payload.timestamp);
            // console.log('itemIndex in REMOVE_CART_ITEM_USER')
            // console.log(itemIndex)
            // console.log('state.filter((item, index) => index !== itemIndex)')
            // console.log(state.filter((item, index) => index !== itemIndex))
            return state.filter((item, index) => index !== itemIndex);
        }
        case ADD_REFUNDED_ITEM_TO_CART:{
            let {item,is_note,extra_charges,timestamp} = action.payload;
            let obj = {};
            
            if(item.item_type === 'menu_item'){
                obj = {...item.menu_item};
                // obj.refuned_item_type = 'menu_item';
            }else if(item.item_type === "combo_item"){
                obj = {...item};
                // obj.refuned_item_type = 'combo_item';
            }else if(item.item_type === "menu_item_ingredient"){
                obj = {...item};
                // obj.refuned_item_type = 'combo_item_ingredient';
            }else if(item.item_type === "combo_item_ingredient"){
                obj = {...item};
                // obj.refuned_item_type = 'combo_item_ingredient';
            }else if(item.item_type === "notes"){
                obj = {...item};
                // obj.refuned_item_type = 'combo_item_ingredient';
            }
            obj.timestamp = timestamp;
            obj.refuned_item_type = item.item_type;
            obj.item_type = 'refunded_item';
            let items = state.slice();
            let menuItems = items.filter((item, index) => item.item_type === "menu_item");
            let notesItems = items.filter((item, index) => item.item_type === "notes");
            let refundedItems = items.filter((item, index) => item.item_type === "refunded_item");
            let newState = [
                // ...item.ingredients,
                ...menuItems,
                ...notesItems,
                ...refundedItems,
                obj
            ]
            return newState;
            /* return [...state, 
                obj
            ]    */
        }
        case FILL_CART_WITH_EXISTING_ORDER:
            return action.payload;
        default:
            return state;
    }
}