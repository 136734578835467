import React,{Fragment} from 'react';
import OrderItemPos from './OrderItemPos';

const OrderPosItemBlock = (props) => {

    const renderOrders = () => (
        props.list ? 
            props.list.map(card=>(
                <OrderItemPos
                    key={card._id}
                    handelOnClickPosOrderPrint={props.handelOnClickPosOrderPrint}
                    {...card}
                />
            ))
        :null
    )

    return (
        <Fragment>
            {props.list ?
                props.list.length === 0 ?
                    <div className="col-12 no_result">
                       <p className="text-center"> You have not placed any orders yet.</p>
                    </div>
                :null
            :null}
            { renderOrders(props.list)}

        </Fragment>
    );
};

export default OrderPosItemBlock;