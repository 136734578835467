import React,{Fragment} from 'react';
// import {formatExistingOrderDate,formatExistingOrderDateTimeOnly,formatExistingOrderDateTime,isDateMatches} from '../../../utils/general';
import PosRenderSelectedDeliveryType from './PosRenderSelectedDeliveryType';
import PosCartItemBlock from './PosCartItemBlock';
import {formatExistingOrderDateTime} from '../../../utils/general';
import FoodCartPaidChangeFooter from './FoodCartPaidChangeFooter';
import { calculateTotalIncludingFee, calculateFinalTotalOfOrderWithOutObjOrder, calculateFinalTotalOfOrder } from '../../../utils/helpers/OrdersPages/OrdersHelpers';
// import RenderDeliveryType from './RenderDeliveryType';
const OrderItemPos = (props) => {
    // const todayDateTime = new Date();
    // console.log('props in OrderItemPos')
    // console.log(props)
    return (
        <div className="col-md-3 bs1">
            <div className="food-cart-box">

                {/* <!-- food-cart-head --> */}
                <div className="food-cart-head bg-white mb-3">
                    
                    <div className="food-info-head">
                        <div className="row">
                            <div className="col-6">
                                <PosRenderSelectedDeliveryType 
                                    selectedOrderType={props.delivery_type}
                                />
                                {
                                    (props.objCustomer !== undefined && props.objCustomer !== null) && Object.keys(props.objCustomer).length > 0 ?
                                        <p>{props.objCustomer.fullname}</p>
                                    :null
                                }
                            </div>
                            <div className="col-6 text-right">
                                {
                                    props.ticket_no !== undefined ?
                                        <p>Ticket # {props.ticket_no}</p>
                                    :null
                                }
                                {
                                    props.order_type === 'regular' ?
                                        props.delivery_type === 'pickup' ?
                                            <p>{formatExistingOrderDateTime(props.createdAt)}</p>
                                        : <p>{formatExistingOrderDateTime(props.order_completion_datetime)}</p>
                                    :null
                                }

                            </div>
                            <div className="col-12">
                                {
                                    Object.keys(props.objCustomer).length > 0 ?
                                    <Fragment>
                                        <p>{props.objCustomer.address}</p>
                                        <p>{props.objCustomer.phone_no}</p>
                                    </Fragment>
                                    :null
                                }
                                {
                                    props.delivery_type === 'delivery' && Object.keys(props.objCustomer).length > 0  && props.objCustomer.appartment_unit_number !== '' ?
                                    <p>{props.appartment_unit_number}</p>
                                    : null
                                }
                                {
                                    (Object.keys(props.objCustomer).length > 0 && (props.objCustomer.notes && props.objCustomer.notes !== '')) ?
                                        <p>{props.objCustomer.notes}</p>
                                    :null
                                }
                                
                            </div>
                        </div>
                    </div>
                    <PosCartItemBlock
                        products={props.order_details}
                        type="cart"
                    />
                    
                    
                </div>
                {/* <!-- food-cart-head --> */}

                {/* <!-- food-cart-total --> */}
                <div className="food-cart-total bg-white mb-3 py-2">
                    <div className="col-8 offset-4">
                        <ul>
                            {
                            props.is_created_before_card_processing_fee === false ?
                            <Fragment>
                                <li className="d-flex align-items-center justify-content-between">
                                    <h6>Discount</h6>
                                    <p>${parseFloat(props.discount_amount).toFixed(2)}</p>
                                </li>
                                <li className="d-flex align-items-center justify-content-between">
                                    <h6>Subtotal</h6>
                                    <p>${parseFloat(props.subTotal).toFixed(2)}</p>
                                </li>
                                <li className="d-flex align-items-center justify-content-between">
                                    <h6>Tax</h6>
                                    <p>${(!props._id.includes('temp_')) ? 
                                            <Fragment> {parseFloat(props.taxAmount).toFixed(2)} </Fragment> :  
                                            <Fragment> {parseFloat(props.taxAmount).toFixed(2)} </Fragment>
                                    }</p>
                                </li>
                                <li className="d-flex align-items-center justify-content-between">
                                    <h6>Total</h6>
                                    <h5 className={`${parseFloat(props.total).toFixed(2) < 0 ? 'text-danger' : ''}`}>${parseFloat(props.total).toFixed(2)}</h5>
                                </li>
                                {
                                    props.delivery_type === 'delivery' ?
                                    <li className="d-flex align-items-center justify-content-between mt10">
                                        <h6>Delivery Fee</h6>
                                        <p>${parseFloat(props.delivery_amount).toFixed(2)}</p>
                                    </li>
                                    : null
                                }
                                {
                                    props.delivery_type === 'delivery' && props.delivery_payment_method === 'credit' ?
                                    <li className="d-flex align-items-center justify-content-between">
                                        <h6>Card processing fee</h6>
                                        <p>${parseFloat(props.card_processing_fee).toFixed(2)}</p>
                                    </li>
                                    : null
                                }
                                {
                                    props.delivery_type === 'delivery' ?
                                    <li className="d-flex align-items-center justify-content-between">
                                        <h6>Total including fee</h6>
                                        <p>${calculateFinalTotalOfOrder(props,props.delivery_payment_method ,props.card_processing_fee)}</p>
                                    </li>
                                    : null
                                }
                                
                                
                            </Fragment>
                            :
                            <Fragment>
                                {
                                    props.delivery_type === 'delivery' ?
                                    <li className="d-flex align-items-center justify-content-between">
                                        <h6>Delivery Fee</h6>
                                        <p>${parseFloat(props.delivery_amount).toFixed(2)}</p>
                                    </li>
                                    : null
                                }
                                <li className="d-flex align-items-center justify-content-between">
                                    <h6>Discount</h6>
                                    <p>${parseFloat(props.discount_amount).toFixed(2)}</p>
                                </li>
                                <li className="d-flex align-items-center justify-content-between">
                                    <h6>Subtotal</h6>
                                    <p>${parseFloat(props.total_amount).toFixed(2)}</p>
                                </li>
                                <li className="d-flex align-items-center justify-content-between">
                                    <h6>Tax</h6>
                                    <p>${(!props._id.includes('temp_')) ? 
                                            <Fragment> {parseFloat(props.tax_amount).toFixed(2)} </Fragment> :  
                                            <Fragment> {parseFloat(props.taxAmount).toFixed(2)} </Fragment>
                                    }</p>
                                </li>
                                <li className="d-flex align-items-center justify-content-between">
                                    <h6>Total</h6>
                                    <h5 className={`${parseFloat(props.total_amount_after_tax_delivery_charges).toFixed(2) < 0 ? 'text-danger' : ''}`}>${parseFloat(props.total_amount_after_tax_delivery_charges).toFixed(2)}</h5>
                                </li>
                            </Fragment> 
                            }

                            <br/>
                            
                            <FoodCartPaidChangeFooter
                                ObjOrder={props}
                            />
                        </ul>
                    </div>
                    <div className="col-12 text-center">
                        <button className={`btn btn-light-success text-white btnPrint`} onClick={()=> props.handelOnClickPosOrderPrint(props)} >
                            Print
                        </button>
                    </div>
                </div>
                {/* <!-- food-cart-total --> */}

                

            </div>
        </div>
    );
}



export default OrderItemPos;