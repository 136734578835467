import { addTimeToCurrentDateTime } from "../general";
import moment from 'moment';

export function ShowDeliveryPickupTimeOnReceipt(objCustomer) {
    // let date = new Date(datetime);
    let order_completion_datetime = null;
    if(objCustomer.timeSelectionRadioBox === "have_ready_in"){
       order_completion_datetime = addTimeToCurrentDateTime(objCustomer.drp_default_time);
    }else if(objCustomer.timeSelectionRadioBox === "other_time"){
       order_completion_datetime = new Date(objCustomer.time_picker_time);
    }
    let formated_date = moment(order_completion_datetime).format("hh:mm A"); 
    // formated_date = date.getDate() + '-' + (date.getMonth()+1) + '-' +  date.getFullYear();
    return formated_date;
}