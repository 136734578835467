import React,{Fragment} from 'react';
import OrderPosItemBlock from './OrderPosItemBlock'; 
import LoadingSpinner from '../../../utils/LoadingSpinner';

const LoadMorePosOrderItems = (props) => {
    return (
        <Fragment>
            <div className="row ">
                <OrderPosItemBlock
                    list={props.Orders}
                    handelOnClickPosOrderPrint={props.handelOnClickPosOrderPrint}
                />
            </div>
            {
                props.isloadingPosOrders ? <LoadingSpinner subClass={'mt10 mb10'} /> :null
            }
            {
                props.total_pos_orders > 0 && props.total_pos_orders > props.Orders.length ?
                    <div>
                        <div className="text-center mt20">
                            <button className="btn btn-light-success text-white btnPrint btnLoadMore"  onClick={()=> props.onClickHandleLoadMorePosOrderItems()}>Load More Orders</button>
                        </div> 
                    </div>
                :null
            }
            

    
        </Fragment>
    );
};

export default LoadMorePosOrderItems;