import React from 'react';
import { Link } from 'react-router-dom';
import eigthPng from '../../../../common/images/icons/8.png';
import ninthPng from '../../../../common/images/icons/9.png';
import tenthPng from '../../../../common/images/icons/10.png';
import eleventhPng from '../../../../common/images/icons/11.png';
import twelevthPng from '../../../../common/images/icons/12.png';
import thirteehnthPng from '../../../../common/images/icons/13.png';
import fourtheenthPng from '../../../../common/images/icons/14.png';
import fifteenthPng from '../../../../common/images/icons/15.png';
import sixthennthPng from '../../../../common/images/icons/16.png';
import DiscountModal from './DiscountModal/DiscountModal';
import DoneModal from './DoneModal/DoneModal';
import CashModal from './CashModal/CashModal';
import MistakeExistingOrdersModal from './MistakeExistingOrdersModal/MistakeExistingOrdersModal';
import IsNewOrExistingMistakeSelectionModal from './IsNewOrExistingMistakeSelectionModal/IsNewOrExistingMistakeSelectionModal';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class FoodNavigation extends React.Component {
// const FoodNavigation = (props) => {
    // console.log('props.objCustomer in FoodNavigation')
    // console.log(props.objCustomer)
    // console.log(props.showDiscountModal)
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          dropdownOpen: false
        };
    }
    toggle() {
        this.setState({
          dropdownOpen: !this.state.dropdownOpen
        });
    }
    render() {
        const props = this.props;
            return (    
                // <!-- food-navigation -->
                <div className="food-navigation text-center text-white">
                    
                    <DiscountModal 
                        handleOnChangeDiscountModalToggle={props.handleOnChangeDiscountModalToggle}
                        showDiscountModal={props.showDiscountModal}
                        total={props.total}
                        discount_percentage={props.discount_percentage}
                        discount_amount_entered={props.discount_amount_entered}
                        discount_amount={props.discount_amount}
                    />
                    <DoneModal 
                        handleOnChangeDoneModalToggle={props.handleOnChangeDoneModalToggle}
                        showDoneModal={props.showDoneModal}
                        isShowLoader={props.isShowLoader}
                        submitDoneOrder={props.submitDoneOrder}
                        handelOnClickPrint={props.handelOnClickPrint}
                        handelOnClickNoKitchen={props.handelOnClickNoKitchen}  
                        existing_order_id={props.existing_order_id}  
                    />
                    <CashModal
                        cart={props.cart}  
                        orderResponse={props.orderResponse}  
                        handleOnChangeCashModalToggle={props.handleOnChangeCashModalToggle}
                        showCashModal={props.showCashModal}
                        changeAmount={props.changeAmount}
                        calculateChangeAmount={props.calculateChangeAmount}
                        handelOnClickPrint={props.handelOnClickPrint}
                        handelOnClickNoKitchen={props.handelOnClickNoKitchen}
                        handelOnClickChanegModalPrint={props.handelOnClickChanegModalPrint}
                        submitDoneOrder={props.submitDoneOrder}
                        existing_order_id={props.existing_order_id}
                        isShowLoader={props.isShowLoader}  
                    />
                    <IsNewOrExistingMistakeSelectionModal 
                        showIsNewExistingMistakeSelectionModal={props.showIsNewExistingMistakeSelectionModal}
                        handleOnChangeNewOrExistingMistakeModalToggle={props.handleOnChangeNewOrExistingMistakeModalToggle}
                        handleOnClickMistakeTypeSelection={props.handleOnClickMistakeTypeSelection}
                    />
                    <MistakeExistingOrdersModal
                        showMistakeExistingOrdersModal={props.showMistakeExistingOrdersModal}
                        order={props.order}
                        onClickHandleLoadMoreMistakeExistingOrderItems={props.onClickHandleLoadMoreMistakeExistingOrderItems}
                        limitMistakeExstingOrders={props.limitMistakeExstingOrders}
                        isloadingMistakeExistingOrders={props.isloadingMistakeExistingOrders}
                        handleOnChangeMistakeExistingOrderModalToggle={props.handleOnChangeMistakeExistingOrderModalToggle}
                        handelOnClickMistakeExistingOrder={props.handelOnClickMistakeExistingOrder}
                        clearMistakeExistingOrderHistory={props.clearMistakeExistingOrderHistory}
                        clearMistakeExistingOrderLimits={props.clearMistakeExistingOrderLimits}
                    />
                    <ButtonDropdown  isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                        <DropdownToggle className="btn btn-none-bg d-flex align-items-center btnMenuDropDownToggle btnMenu">
                            Menu
                        </DropdownToggle>
                        <DropdownMenu right>
                            <Link to="/kitchen" target="_blank"><DropdownItem>Kitchen Display Window 1</DropdownItem></Link>
                            <DropdownItem onClick={() => props.openPresentationUrl()}>Kitchen Display Window 2</DropdownItem>
                            <Link to="/pos-orders" target="_blank"><DropdownItem>POS Orders</DropdownItem></Link>
                            <Link to="/map" target="_blank"><DropdownItem>Map</DropdownItem></Link>
                            <a href={`https://${process.env.REACT_APP_API_DOMAIN}.${process.env.REACT_APP_FULL_DOMAIN}/admin/login`} target="_blank" rel="noopener noreferrer"><DropdownItem>Admin Pannel</DropdownItem></a>
                            <DropdownItem onClick={() => props.updateSystem()}>Update System</DropdownItem>
                            <DropdownItem divider />
                            <Link to="/logout" ><DropdownItem>Logout</DropdownItem></Link>
                        </DropdownMenu>
                    </ButtonDropdown>
                    <button className={`btn btn-light-warning text-white p-2 w-100 h-100 mb-3 d-flex align-items-center justify-content-center ${props.existing_order_id === '' && (props.selectedOrderType === 'pickup' || props.selectedOrderType === 'delivery') ? 'btnDoneActive flash-button' : ''}`} onClick={()=> props.handleOnClickDoneOrder()}>
                        <div>
                            <p>
                                <img src={eleventhPng} alt="icons" className="mw-100 mb-2" />
                            </p>
                            <p>
                                Save
                            </p>
                        </div>
                    </button>
                    <button
                        className="btn btn-orange text-white p-2 w-100 h-100 mb-3 d-flex align-items-center justify-content-center" onClick={()=> props.handleOnClickDiscountMenu()}>
                        <div>
                            <p>
                                <img src={eigthPng} alt="icons" className="mw-100 mb-2" />
                            </p>
                            <p>
                                Discount
                            </p>
                        </div>
                    </button>
                    <button
                        className="btn btn-pink text-white p-2 w-100 h-100 mb-3 d-flex align-items-center justify-content-center" onClick={()=> props.handleOnClickVoidMenu(true,true)}>
                        <div>
                            <p>
                                <img src={ninthPng} alt="icons" className="mw-100 mb-2" />
                            </p>
                            <p>
                                Void
                            </p>
                        </div>
                    </button>
                    <button
                        className={`btn btn-light-danger text-white p-2 w-100 h-100 mb-3 d-flex align-items-center justify-content-center ${props.isRefundActive  ? 'btnRefundActive flash-button' : ''}`} onClick={()=> props.handelOnClickRefundMenu()}>
                        <div>
                            <p>
                                <img src={tenthPng} alt="icons" className="mw-100 mb-2" />
                            </p>
                            <p>
                                Refund
                            </p>
                        </div>
                    </button>
                    <button
                        className={`btn btn-light-success text-white p-2 w-100 h-100 mb-3 d-flex align-items-center justify-content-center ${props.isMistakeActive  ? 'btnMistakeActive flash-button' : ''}`} onClick={()=> props.handelOnClickMistakeMenu()}>
                        <div>
                            <p>
                                <img src={twelevthPng} alt="icons" className="mw-100 mb-2" />
                            </p>
                            <p>
                                Mistake
                            </p>
                        </div>
                    </button>
                    <button
                        className="btn btn-light-blue text-white p-2 w-100 h-100 mb-3 d-flex align-items-center justify-content-center btnCash" onClick={()=> props.handelOnClickCashMenu()}>
                        <div>
                            <p>
                                <img src={thirteehnthPng} alt="icons" className="mw-100 mb-2" />
                            </p>
                            <p>
                                Cash
                            </p>
                        </div>
                    </button>
                    <button
                        className="btn btn-blue text-white p-2 w-100 h-100 mb-3 d-flex align-items-center justify-content-center" onClick={()=> props.handelOnClickDebitCardMenu()}>
                        <div>
                            <p>
                                <img src={fourtheenthPng} alt="icons" className="mw-100 mb-2" />
                            </p>
                            <p>
                                Debit card
                            </p>
                        </div>
                    </button>
                    <button
                        className="btn btn-muted text-white p-2 w-100 h-100 mb-3 d-flex align-items-center justify-content-center" onClick={()=> props.handelOnClickCreditCardMenu()}>
                        <div>
                            <p>
                                <img src={fifteenthPng} alt="icons" className="mw-100 mb-2" />
                            </p>
                            <p>
                                Credit card
                            </p>
                        </div>
                    </button>
                    <button
                        className="btn btn-light-danger text-white p-2 w-100 h-100 mb-3 d-flex align-items-center justify-content-center" onClick={()=> props.handelOnClickGiftCardMenu()}>
                        <div>
                            <p>
                                <img src={sixthennthPng} alt="icons" className="mw-100 mb-2" />
                            </p>
                            <p>
                                Gift card
                            </p>
                        </div>
                    </button>
                </div>
            );
    }
};

export default FoodNavigation;