import axios from 'axios';
import {
	BASE_URL,
	POS_ORDER_SERVER
} from '../misc';
import localForage from 'localforage';
import {
	checkInternetConnection
} from '../general';

export async function syncNewOrders() {
	// If there is any new unsynced customers, then sync with backend
	const isConnected = await checkInternetConnection();
	// eslint-disable-next-line
	// isConnected? console.log('Online') : console.log('Offline');

	if (isConnected) {
		// If there is any new orders, then sync with backend
		let addedOrders = await localForage.getItem('new-orders');
		addedOrders = JSON.parse(addedOrders);
		// console.log('addedOrders in sync')
		// console.log(addedOrders)
		if (addedOrders !== null && addedOrders.length > 0) {
			let unsyncedOrders = []; // to store any unsuccessful sync customers
			await Promise.all(addedOrders.map(async (orderData) => {
				try {
					/* await fetcher.http({
					path: '/api/customers/create',
					method: 'POST',
					body: orderData
					}); */
					const res = await axios.post(`${BASE_URL}${POS_ORDER_SERVER}/save-order`, orderData);
					console.log('res in sync')
					console.log(res)
				} catch (e) {
					// eslint-disable-next-line
					// console.log(`Error - could not be synced - ${orderData} ${e}`);
					console.log(`Error - could not be synced -  ${e}`);
					unsyncedOrders.push(orderData);
				}
			}));
			// eslint-disable-next-line
			// console.log("UNSYNCED ERROR - syncNewCustomers", JSON.stringify(unsyncedOrders));

			if (unsyncedOrders.length > 0) {
				// await new OfflineStorage('new-orders').setItem(unsyncedOrders);
				await localForage.setItem('new-orders', JSON.stringify(unsyncedOrders))
			} else {
				// await new OfflineStorage('new-orders').setItem([]);
				await localForage.setItem('new-orders', JSON.stringify([]))
			}

			// const { dispatch } = store;
			// bindActionCreators(customerActions, dispatch);
			// let action = customerActions.fetchCustomers();
			// dispatch(action);
		}
	}
}

export async function syncEditedOrders() {
	// If there is any new unsynced customers, then sync with backend
	const isConnected = await checkInternetConnection();
	// eslint-disable-next-line
	// isConnected? console.log('Online') : console.log('Offline');

	if (isConnected) {
		// If there is any new customers, then sync with backend
		let editedOrders = await localForage.getItem('edit-orders');
		editedOrders = JSON.parse(editedOrders);
		// console.log('editedOrders in sync')
		// console.log(editedOrders)
		if (editedOrders !== null && editedOrders.length > 0) {
			let unsyncedOrders = []; // to store any unsuccessful sync customers
			await Promise.all(editedOrders.map(async (orderData) => {
				try {
					/* await fetcher.http({
					path: '/api/customers/create',
					method: 'POST',
					body: orderData
					}); */
					const res = await axios.post(`${BASE_URL}${POS_ORDER_SERVER}/edit-order`, orderData);
					console.log('res in sync edit')
					console.log(res)
				} catch (e) {
					// eslint-disable-next-line
					// console.log(`Error - could not be synced - ${orderData} ${e}`);
					console.log(`Error - edited order could not be synced -  ${e}`);
					unsyncedOrders.push(orderData);
				}
			}));
			// eslint-disable-next-line
			// console.log("UNSYNCED ERROR - syncNewCustomers", JSON.stringify(unsyncedOrders));

			if (unsyncedOrders.length > 0) {
				// await new OfflineStorage('edit-orders').setItem(unsyncedOrders);
				await localForage.setItem('edit-orders', JSON.stringify(unsyncedOrders))
			} else {
				// await new OfflineStorage('edit-orders').setItem([]);
				await localForage.setItem('edit-orders', JSON.stringify([]))
			}

			// const { dispatch } = store;
			// bindActionCreators(customerActions, dispatch);
			// let action = customerActions.fetchCustomers();
			// dispatch(action);
		}
	}
}

export async function syncOrdersSatusChanged() {
	// If there is any new unsynced customers, then sync with backend
	const isConnected = await checkInternetConnection();
	// eslint-disable-next-line
	// isConnected? console.log('Online') : console.log('Offline');

	if (isConnected) {
		// If there is any new customers, then sync with backend
		let posKitchenOrderStatusChange = await localForage.getItem('pos-kitchen-order-status-change');
		posKitchenOrderStatusChange = JSON.parse(posKitchenOrderStatusChange);
		// console.log('posKitchenOrderStatusChange in sync')
		// console.log(posKitchenOrderStatusChange)
		if (posKitchenOrderStatusChange !== null && posKitchenOrderStatusChange.length > 0) {
			let unsyncedOrders = []; // to store any unsuccessful sync customers
			await Promise.all(posKitchenOrderStatusChange.map(async (orderData) => {
				try {
					/* await fetcher.http({
					path: '/api/customers/create',
					method: 'POST',
					body: orderData
					}); */
					const res = await axios.post(`${BASE_URL}${POS_ORDER_SERVER}/change-kitchen-order-status-offline`, orderData);
					console.log('res in sync status order')
					console.log(res)
				} catch (e) {
					// eslint-disable-next-line
					// console.log(`Error - could not be synced - ${orderData} ${e}`);
					console.log(`Error - status order could not be synced -  ${e}`);
					unsyncedOrders.push(orderData);
				}
			}));
			// eslint-disable-next-line
			// console.log("UNSYNCED ERROR - syncNewCustomers", JSON.stringify(unsyncedOrders));

			if (unsyncedOrders.length > 0) {
				await localForage.setItem('pos-kitchen-order-status-change', JSON.stringify(unsyncedOrders))
			} else {
				await localForage.setItem('pos-kitchen-order-status-change', JSON.stringify([]))
			}

			// const { dispatch } = store;
			// bindActionCreators(customerActions, dispatch);
			// let action = customerActions.fetchCustomers();
			// dispatch(action);
		}
	}
}

export async function syncDeletedOrders() {
	// If there is any new unsynced customers, then sync with backend
	const isConnected = await checkInternetConnection();
	// eslint-disable-next-line
	// isConnected? console.log('Online') : console.log('Offline');

	if (isConnected) {
		// Delete the orders, then sync with backend
		// If there is any new customers, then sync with backend
		let deletedOrders = await localForage.getItem('delete-orders');
		deletedOrders = JSON.parse(deletedOrders);
		// console.log('deletedOrders in sync')
		// console.log(deletedOrders)
		if (deletedOrders !== null && deletedOrders.length > 0) {
			let unsyncedOrders = []; // to store any unsuccessful sync customers
			await Promise.all(deletedOrders.map(async (orderData) => {
				try {
					
					const res = await axios.post(`${BASE_URL}${POS_ORDER_SERVER}/delete-order-by-oid`, {order_id:orderData.order_id});
					console.log('res in sync')
					console.log(res)
				} catch (e) {
					// eslint-disable-next-line
					// console.log(`Error - could not be synced - ${orderData} ${e}`);
					console.log(`Error - could not be synced in deleted order-  ${e}`);
					unsyncedOrders.push(orderData);
				}
			}));
			// eslint-disable-next-line
			// console.log("UNSYNCED ERROR - syncNewCustomers", JSON.stringify(unsyncedOrders));

			if (unsyncedOrders.length > 0) {
				// await new OfflineStorage('delete-orders').setItem(unsyncedOrders);
				await localForage.setItem('delete-orders', JSON.stringify(unsyncedOrders))
			} else {
				// await new OfflineStorage('delete-orders').setItem([]);
				await localForage.setItem('delete-orders', JSON.stringify([]))
			}

			// const { dispatch } = store;
			// bindActionCreators(customerActions, dispatch);
			// let action = customerActions.fetchCustomers();
			// dispatch(action);
		}
	}
}

/* export async function syncEditedCustomers(){
    // If there is any edited, unsynced customers, then sync with backend
    // await new OfflineStorage('edit-customers').setItem([]);
    const isConnected = await checkInternetConnection();
    // eslint-disable-next-line
    // isConnected? console.log('Online') : console.log('Offline');
    if(isConnected){
      // If there is any new customers, then sync with backend
      const editedCustomers = await new OfflineStorage('edit-customers').getItem();
      if(editedCustomers.length > 0){
        // eslint-disable-next-line
        // console.log("ALL EDITED CUSTOMERS - ", JSON.stringify(editedCustomers));
        let unsyncedCustomers = []; // to store any unsuccessful sync customers
        await Promise.all(editedCustomers.map(async (customerData)=>{
          try {
            await fetcher.http({
              path: '/api/customers/edit',
              method: 'PUT',
              body: customerData
            });
          } catch (e) {
            // eslint-disable-next-line
            // console.log(`Error - could not be synced - ${JSON.stringify(e)} - \n${JSON.stringify(customerData)} `);
            unsyncedCustomers.push(customerData);
          }
        }));
        // eslint-disable-next-line
        // console.log("UNSYNCED ERROR - syncEditedCustomers", JSON.stringify(unsyncedCustomers));
    
        if(unsyncedCustomers.length > 0){
          await new OfflineStorage('edit-customers').setItem(unsyncedCustomers);
        }else{
          await new OfflineStorage('edit-customers').setItem([]);
        }
      }
    }
  } */