import React,{Fragment} from 'react';
const DiscountModalBody = (props)  => {
    
    return (
        <Fragment>
            <form>
                <div className="form-group">
                    <label htmlFor="discount_percentage">Enter discount percentage</label>
                    <input type="number" className="form-control" value={props.discount_percentage} onChange={props.handleChangeDiscountPercentage} name="discount_percentage" id="discount_percentage"  placeholder="Enter discount %"/>
                </div>
                <div className="form-group">
                    <label htmlFor="discount_amount">Enter discount amount</label>
                    <input type="number" className="form-control" value={props.discount_amount} onChange={props.handleChangeDiscountAmount} name="discount_amount" id="discount_amount"  placeholder="Enter discount amount"/>
                </div>
            </form>
        </Fragment>
    );
}

export default DiscountModalBody;