import React,{Fragment} from 'react';
import OrderReceiptPopupRenderComboItemIngredients from './OrderReceiptPopupRenderComboItemIngredients';
const OrderReceiptPopupCartItemSubBlock = ({
        mainProduct,
        products,
    }) => {

    // console.log(products)
    // console.log(products)
    const renderItems = () => (
        products ?
            products.map((product,index)=>{
                if(product.is_note){
                    return <tr className="service serviceSub serviceSubTr" key={index}>
                                <td className="tableitem">
                                    <p className="itemtext itemTextName">With {product.notes }</p>
                                </td>
                                <td className="Rate Price">
                                    <p className="itemtext Price">
                                        {/* {
                                            product.is_paid_notes ? <Fragment> ${(parseFloat(product.extra_charges)).toFixed(2) } </Fragment> : null
                                        } */}
                                    </p>
                                </td>
                            </tr>
                    
                    
                }else{
                    return  <Fragment key={index}>
                                <tr className="service serviceSub serviceSubTr" >
                                    <td className="tableitem">
                                        <p className="itemtext itemTextName">With {product.name }</p>
                                    </td>
                                    <td className="Rate Price">
                                        <p className="itemtext Price">
                                            ${(parseFloat(product.combo_item_price)).toFixed(2) }
                                            {/* {
                                                product.is_paid_notes ? <Fragment> ${(parseFloat(product.extra_charges)).toFixed(2) } </Fragment> : null
                                            } */}
                                        </p>
                                    </td>
                                </tr>
                                {
                                    product.ingredients.length > 0 ? OrderReceiptPopupRenderComboItemIngredients(mainProduct,product,product.ingredients) : null
                                    // product.ingredients.length > 0 ? OrderReceiptPopupRenderComboItemIngredients(mainProduct,product,product.ingredients,removeIngredientFromCartComboItem) : null
                                }
                            </Fragment>
                    
                    
                }
            })

        :null
    )


    return (
        <Fragment>
            {renderItems()}
        </Fragment>
    );
};

export default OrderReceiptPopupCartItemSubBlock;