import React, { Component } from 'react';
import { connect } from 'react-redux';
import { auth,isResturantOpen } from '../actions/user_actions';
// import { isResturantOpen } from '../actions/user_actions';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { Spinner } from 'reactstrap';


export default function(ComposedClass,reload,checkAUth = null,adminRoute = null){
    class AuthenticationCheck extends Component {
        _isMounted = false;
        state = {
            loading: true
        }

        componentDidMount(){
            this._isMounted = true;
            // this.props.dispatch(isResturantOpen()).then(response =>{
                this.props.dispatch(auth()).then(response =>{
                    /* console.log('before making state false')
                    this.setState({loading:false})
                    console.log('after making state false') */
                    // console.log('response')
                    // console.log(response)
                // this.props.dispatch(auth()).then(response =>{
                    // let user = this.props.user.userData;is_resturant_open
                    // let is_resturant_open = this.props.user.is_resturant_open.is_resturant_open;
                    // let cart = this.props.cart;
                    // console.log('this.props.user.userData')
                    // console.log(this.props.user.userData)
                    let user = this.props.user.userData;
                    // console.log('is_resturant_open')
                    // console.log(is_resturant_open)
                    /* if(!is_resturant_open){
                        if(reload){
                            // this.setState({loading:false})
                            this.props.history.push('/resturant-closed')
                        }
                    } */
                    // console.log('user.isAuth')
                    // console.log(user.isAuth)
                    // console.log('checkAUth')
                    // console.log(checkAUth)
                    if(checkAUth && !user.isAuth){
                        // console.log('i am in if of checkAUth')
                        if(reload){
                            // this.setState({loading:false})
                            // console.log('i am in reload of login')
                            this.props.history.push('/login')
                        }
                    }else if(checkAUth && user.isAuth){
                        // console.log('i am in else if of checkAUth')
                        // console.log('reload')
                        // console.log('reload')
                        // console.log('reload')
                        // console.log('reload')
                        // console.log(reload)
                        // console.log(reload)
                        // console.log(reload)
                        // if user is already logged in and vists the login and register page then we redirect him to home page
                        if(reload === false){
                            // this.setState({loading:false})
                            this.props.history.push('/');
                        }
                    }
                    /* if(!user.isAuth){
                        if(reload){
                            this.props.history.push('/register_login')
                        }
                    } else{
                        if(adminRoute && !user.isAdmin){
                            this.props.history.push('/user/dashboard')
                        } else{
                            if(reload === false){
                                this.props.history.push('/user/dashboard')
                            }
                        }
                    } */
                    if (this._isMounted) {
                        // console.log('setting lading false')
                        this.setState({loading:false})
                    }
                });
            // });
        }
        componentWillUnmount() {
            this._isMounted = false;
        }


        render() {
            // console.log('this.state.loading in render')
            // console.log(this.state.loading)
            if(this.state.loading){
                return (
                    <div className="main_loader">
                        <Spinner color="primary" />
                        {/* <CircularProgress style={{color:'#2196F3'}} thickness={7}/>  */}
                    </div>
                )
            }
            // console.log('after state loading in render')
            // console.log('this.state.loading in render')
            // console.log(this.state.loading)
            return (
               <ComposedClass {...this.props}  />
            );
        }
    }

    function mapStateToProps(state){
        return {
            user: state.user,
            // order: state.order,
            // is_resturant_open:state.is_resturant_open
        }
    }

    return connect(mapStateToProps)(AuthenticationCheck)
}


