import axios from 'axios';
import {
    ADD_TO_CART_USER,
    GET_CART_ITEMS_USER,
    REMOVE_CART_ITEM_USER,
    REMOVE_INGREDIENT_FROM_MENU_ITEM_CART,
    REMOVE_INGREDIENT_FROM_COMBO_ITEM_CART,
    REMOVE_CART_SUB_ITEM_USER,
    CLEAR_CART,
    ADD_COMBO_ITEM_TO_MENU_ITEM,
    ADD_INGREDIENT_TO_MENU_ITEM_CART,
    ADD_INGREDIENT_TO_MENU_ITEM_COMBO_ITEM_CART,
    ADD_NOTES_WITH_EXTRA_CHARGE,
    ADD_NOTES_WITH_EXTRA_CHARGE_TO_CART_MENU_ITEM,
    ADD_NOTES_WITH_EXTRA_CHARGE_TO_CART_MENU_ITEM_COMBO_ITEM,
    REMOVE_CART_ITEM_NOTES,
    ADD_REFUNDED_ITEM_TO_CART,
    FILL_CART_WITH_EXISTING_ORDER,
    ADD_INCLUDED_AND_BOTH_INGREDIENTS_TO_MENU_ITEM,
    ADD_INCLUDED_AND_BOTH_INGREDIENTS_TO_COMBO_MENU_ITEM,
} from './types';

export const clearCart = () => async dispatch => {
    dispatch({ type: CLEAR_CART, payload: [] });
};

export const addToCart = (product) => async dispatch => {
    /* console.log('selectedCstateomboItems in meny action')
    console.log(selectedCstateomboItems)
    const data = {
        list:selectedCstateomboItems
    }
    const res = await axios.post(`/api/fetch-multiple-menu-items`,data); */
    
    // dispatch({ type: ADD_TO_CART_USER, payload: res.data });
    dispatch({ type: ADD_TO_CART_USER, payload: product });
};

export const addIncludedAndBothIngredientsToMenuItemCart = (objMenuItem,Ingredients) => async dispatch => {
    
    dispatch({
        type: ADD_INCLUDED_AND_BOTH_INGREDIENTS_TO_MENU_ITEM,
        payload: {
            objMenuItem,
            Ingredients,
        }
    });
};

export const addIncludedAndBothIngredientsToComboMenuItemCart = (menu_item_id,timestamp,comboItem,Ingredients) => async dispatch => {
    
    
    dispatch({
        type: ADD_INCLUDED_AND_BOTH_INGREDIENTS_TO_COMBO_MENU_ITEM,
        payload: {
            menu_item_id,
            timestamp,
            comboItem,
            Ingredients,
        }
    });
};
export const addComboItemToMenuItemCart = (menu_item_id,timestamp,comboItem) => async dispatch => {
    
    dispatch({
        type: ADD_COMBO_ITEM_TO_MENU_ITEM,
        payload: {
            menu_item_id,
            timestamp,
            comboItem,
        }
    });
};
export const addIngredientToMenuItemCartA = (menu_item_id,timestamp,ingredient,ingredient_type,selectedMenuItemIngredientType,selectedOnReceiptItemType,selectedOnReceiptMenuItemIngredient) => async dispatch => {
    
    dispatch({
        type: ADD_INGREDIENT_TO_MENU_ITEM_CART,
        payload: {
            menu_item_id,
            timestamp,
            ingredient,
            ingredient_type,
            ingredient_category_type_blue: selectedMenuItemIngredientType,
            selectedOnReceiptItemType,
            selectedOnReceiptMenuItemIngredient,
        }
    });
};

export const addIngredientToMenuItemComboItemCart = (menu_item_id,timestamp,combo_menu_item_id,ingredient,ingredient_type,selectedMenuItemIngredientType,selectedOnReceiptItemType,selectedOnReceiptMenuItemComboItemIngredient) => async dispatch => {
    
    dispatch({
        type: ADD_INGREDIENT_TO_MENU_ITEM_COMBO_ITEM_CART,
        payload: {
            menu_item_id,
            timestamp,
            combo_menu_item_id,
            ingredient,
            ingredient_type,
            ingredient_category_type_blue: selectedMenuItemIngredientType,
            selectedOnReceiptItemType,
            selectedOnReceiptMenuItemComboItemIngredient,
        }
    });
};

export const addNotesWithExtraChargeToCart = (notes,extra_charges,timestamp) => async dispatch => {
    
    dispatch({
        type: ADD_NOTES_WITH_EXTRA_CHARGE,
        payload: {
            notes,
            extra_charges,
            timestamp,
        }
    });
};

export const addNotesWithExtraChargeToCartMenuItem = (ingredient, selectedItemType,selectedOnReceiptItemType,selectedOnReceiptMenuItemIngredient, menu_item_id,timestamp) => async dispatch => {
    
    dispatch({
        type: ADD_NOTES_WITH_EXTRA_CHARGE_TO_CART_MENU_ITEM,
        payload: {
            ingredient,
            selectedItemType,
            selectedOnReceiptItemType,
            menu_item_id,
            timestamp,
            selectedOnReceiptMenuItemIngredient,
        }
    });
};

export const addNotesWithExtraChargeToCartMenuItemComboItem = (ingredient, selectedItemType,selectedOnReceiptItemType,selectedOnReceiptMenuItemIngredient, menu_item_id,timestamp,combo_menu_item_id,selectedOnReceiptMenuItemComboItemIngredient) => async dispatch => {
    
    dispatch({
        type: ADD_NOTES_WITH_EXTRA_CHARGE_TO_CART_MENU_ITEM_COMBO_ITEM,
        payload: {
            ingredient,
            selectedItemType,
            selectedOnReceiptItemType,
            menu_item_id,
            timestamp,
            selectedOnReceiptMenuItemIngredient,
            combo_menu_item_id,
            selectedOnReceiptMenuItemComboItemIngredient
        }
    });
};

export function addToCart_old(_id){

    const request = axios.post( `/addToCart?productId=${_id}`)
    .then(response => response.data)

    return {
        type: ADD_TO_CART_USER,
        payload: request
    }
}

export function getCartItems(cartItems, userCart){

    const request = axios.get(`/articles_by_id?id=${cartItems}&type=array`)
                    .then(response => {
     
                        userCart.forEach(item=>{
                            response.data.forEach((k,i)=>{
                                if(item.id === k._id){
                                    response.data[i].quantity = item.quantity;
                                }
                            })
                        })
                        return response.data;
                    })
                 

    return {
        type: GET_CART_ITEMS_USER,
        payload: request
    }

}



export const removeCartItem = (product) => async dispatch => {
    dispatch({ type: REMOVE_CART_ITEM_USER, payload: product });
};

export const removeIngredientFromCartMenuItem = (menuItem,ingredient) => async dispatch => {
    dispatch({ type: REMOVE_INGREDIENT_FROM_MENU_ITEM_CART, payload: {menuItem,ingredient} });
};

export const removeIngredientFromCartComboItem = (menuItem,comboItem,ingredient) => async dispatch => {
    dispatch({ type: REMOVE_INGREDIENT_FROM_COMBO_ITEM_CART, payload: {menuItem,comboItem,ingredient} });
};

export const removeCartSubItem = (product,subProduct) => async dispatch => {
    dispatch({ type: REMOVE_CART_SUB_ITEM_USER, payload: {product,subProduct} });
};

export const removeNotesFromCart = (product) => async dispatch => {
    dispatch({ type: REMOVE_CART_ITEM_NOTES, payload: product });
};

export function removeCartItem_old(id){

    const request = axios.get(`/removeFromCart?_id=${id}`)
                    .then(response => {

                        response.data.cart.forEach(item=>{
                            response.data.cartDetail.forEach((k,i)=>{
                                if(item.id === k._id){
                                    response.data.cartDetail[i].quantity = item.quantity;
                                }
                            })
                        })
                            return response.data;
                    })

    return {
        type: REMOVE_CART_ITEM_USER,
        payload: request
    }

}

export const addRefundedItemToCart = (item,is_note,extra_charges,timestamp) => async dispatch => {
    
    dispatch({
        type: ADD_REFUNDED_ITEM_TO_CART,
        payload: {
            item,
            is_note,
            extra_charges,
            timestamp,
        }
    });
};

export const addExistingOrderToCart = (order) => async dispatch => {
    dispatch({ type: FILL_CART_WITH_EXISTING_ORDER, payload: order });
};