import axios from 'axios';
import {
    GET_MENU_ITEMS_BY_CATEGORY_ID,
    FETCH_MENU_ITEM_BY_ID,
    FETCH_MULTIPLE_MENU_ITEM_BY_ID,
    FETCH_MULTIPLE_MENU_ITEM_INGREDIENTS_BY_ID,
    CLEAR_MENU_ITEMS,
    FETCH_MENU_ITEMS,
    FETCH_INGREDIENTS,
    FETCH_INGREDIENT_MENU_ITEMS,
} from './types';
import { BASE_URL } from '../components/utils/misc';
import { checkInternetConnection } from '../components/utils/general';
import localForage from 'localforage';
// import { PRODUCT_SERVER } from '../components/utils/misc';




export function getMenuItemsByCategoryId_promise(skip, limit,menuCategoryId, previousState = []){
    const data = {
        limit,
        skip,
        menuCategoryId
    }

    // const request = axios.post(`${PRODUCT_SERVER}/shop`,data)
    const request = axios.post(`/api/get-menu-items-by-category-id`,data)
                .then(response => {
                    let newState = [
                        ...previousState,
                        ...response.data.MenuItems
                    ];
                    return {
                        total_menu_items: response.data.total_menu_items,
                        MenuItems: newState
                    }
                });

    return {
        type: GET_MENU_ITEMS_BY_CATEGORY_ID,
        payload: request
    }

}



export const fetchMenuItemById = (menu_item_id) => async dispatch => {
    const res = await axios.get(`/api/fetch-menu-item-by-id?id=${menu_item_id}`);
    
    dispatch({ type: FETCH_MENU_ITEM_BY_ID, payload: res.data });
};



export const fetchMultipleMenuItemsByIds = (selectedCstateomboItems) => async dispatch => {
    // console.log('selectedCstateomboItems in meny action')
    // console.log(selectedCstateomboItems)
    const data = {
        list:selectedCstateomboItems
    }
    const res = await axios.post(`/api/fetch-multiple-menu-items`,data);
    
    dispatch({ type: FETCH_MULTIPLE_MENU_ITEM_BY_ID, payload: res.data });
};

export const fetchMultipleMenuItemsIngredientsByIds = (selectedCstateomboItems) => async dispatch => {
    const data = {
        list:selectedCstateomboItems
    }
    const res = await axios.post(`/api/fetch-multiple-menu-item-ingredients`,data);
    
    dispatch({ type: FETCH_MULTIPLE_MENU_ITEM_INGREDIENTS_BY_ID, payload: res.data });
};

export const clearMenuItems = () => async dispatch => {
    dispatch({ type: CLEAR_MENU_ITEMS, payload: '' });
};

export const fetchMenuItems = () => async dispatch => {
    let isConnected = await checkInternetConnection();
    if(isConnected){
        const res = await axios.get(`${BASE_URL}/api/fetch-menu-items`);
        localForage.setItem('fetch-menu-items', res.data)
        // await localForage.setItem('fetch-menu-categories', res.data)
        dispatch({ type: FETCH_MENU_ITEMS, payload: res.data });
    }else{
        const menuItems = await localForage.getItem('fetch-menu-items');
        dispatch({ type: FETCH_MENU_ITEMS, payload:menuItems });
    }  
    // if not connection then fetch from localStorage     
};

export const fetchIngredients = () => async dispatch => {
    let isConnected = await checkInternetConnection();
    if(isConnected){
        const res = await axios.get(`${BASE_URL}/api/fetch-ingredients`);
        localForage.setItem('fetch-ingredients', res.data)
        // await localForage.setItem('fetch-menu-categories', res.data)
        dispatch({ type: FETCH_INGREDIENTS, payload: res.data });
    }else{
        const menuIngredients = await localForage.getItem('fetch-ingredients');
        dispatch({ type: FETCH_INGREDIENTS, payload:menuIngredients });
    }  
    // if not connection then fetch from localStorage     
};

export const fetchIngredientMenuItems = () => async dispatch => {
    let isConnected = await checkInternetConnection();
    if(isConnected){
        const res = await axios.get(`${BASE_URL}/api/fetch-ingredient-menu-items`);
        localForage.setItem('fetch-ingredient-menu-items', res.data)
        // await localForage.setItem('fetch-menu-categories', res.data)
        dispatch({ type: FETCH_INGREDIENT_MENU_ITEMS, payload: res.data });
    }else{
        const menuIngredients = await localForage.getItem('fetch-ingredient-menu-items');
        dispatch({ type: FETCH_INGREDIENT_MENU_ITEMS, payload:menuIngredients });
    }  
    // if not connection then fetch from localStorage     
};