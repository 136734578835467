import React,{Fragment} from 'react';
// import trashRedPng from '../../../../../common/images/icons/trash-red.png';
import CartItemSubBlock from './CartItemSubBlock.js' 
import RenderMenuItemIngredients from './RenderMenuItemIngredients';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import { Link } from 'react-router-dom';
const CartItemBlock = ({
        products,
        removeItem,
        removeSubItemFromCart,
        removeIngredientFromCartMenuItem,
        removeIngredientFromCartComboItem,
        handleOnClickSelectReceiptMenuItem,
        selectedOnReceiptMenuItemId,
        selectedOnReceiptMenuItemTimeStamp,
        handleOnClickSelectReceiptMenuItemComboItem,
        selectedOnReceiptItemType,
        selectedOnReceiptMenuItemIngredientId,
        selectedOnReceiptMenuItemIngredient,
        handleOnClickSelectReceiptMenuItemIngredient,
        handleOnClickSelectReceiptComboItemIngredient,
        selectedOnReceiptMenuItemComboItemId,
        selectedOnReceiptMenuItemComboItemIngredientId,
        selectedOnReceiptMenuItemComboItemIngredient
    }) => {
    
    const handleOnClickMenuItem = (product,type) =>{
        if(type === 'menu_item'){
            handleOnClickSelectReceiptMenuItem(product,type);
        }else if(type === 'notes' || type === 'refunded_item'){
            console.log('prdouct in notes')
            console.log(product)
            handleOnClickSelectReceiptMenuItem(product,type);
        }
        /* let buttons = [];
        if(type === 'menu_item'){
            const objButton = {
                label: 'SELECT ITEM',
                onClick: () => {
                    handleOnClickSelectReceiptMenuItem(product);
                }
            }
            buttons.push(objButton);
        }
        const objDeleteButton =    {
                label: 'DELETE ITEM',
                onClick: () => {
                    removeItem(product,type)
                }
            }
        buttons.push(objDeleteButton);
        confirmAlert({
            // title: 'Confirm to submit',
            // message: 'Are you sure to do this.',
            buttons: buttons
        }); */
    }

    const renderItems = () => (
        products ?
            products.map(product=>{
                if(product.item_type === 'notes'){
                    return <ul className="food-info-list" key={product.timestamp}>
                            <li>
                                <h6 className="position-relative">
                                    <span className = {`menu-item-name ${(selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptItemType === 'menu_item_notes') ? "selected" : ""}  `} onClick = {() => handleOnClickMenuItem(product,'notes')}> {product.notes} </span>
                                    <span className="position-absolute menu-item-price">
                                        {
                                            product.is_paid_notes ? <Fragment> ${(parseFloat(product.extra_charges)).toFixed(2) } </Fragment> : null
                                        }
                                        {/* <img src={trashRedPng} alt="trash" className="mw-100 cursor-pointer trash-icon"  onClick={()=> removeItem(product,'notes')}  /> */}
                                    </span>
                                </h6>
                            </li>
                    </ul>
                }else if(product.item_type === 'refunded_item'){
                    return <ul className="food-info-list" key={product.timestamp}>
                                <li>
                                    <h6 className="position-relative">
                                        <span onClick = {() => handleOnClickMenuItem(product,'refunded_item')} className = {`menu-item-name ${(selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptItemType === 'refunded_item') ? "selected" : ""} `}> {product.item_type === "refunded_item" ? 'Refund ' : null}
                                            {
                                                product.refuned_item_type === 'notes' ? <Fragment>  {product.notes} </Fragment> : <Fragment>{product.is_size_selected ? `${product.size_name} ` : null}{product.is_special_modifier_selected ? `${product.special_modifier_name} ` : null}{product.name}</Fragment>
                                            }
                                        </span>
                                        <span className="position-absolute menu-item-price">
                                            {
                                                ((product.refuned_item_type !== 'notes' && product.price !== undefined && product.price !== null  && !isNaN(Number(product.price))) || product.is_paid_notes) ? <Fragment>  - ${(parseFloat(product.price)).toFixed(2) } </Fragment> : null
                                            }
                                            {/* <img src={trashRedPng} alt="trash" className="mw-100 cursor-pointer trash-icon"  onClick={()=> removeItem(product,'refunded_item')}  /> */}
                                        </span>
                                    </h6>
                                </li>
                            </ul>
                }else{
                    return <ul className="food-info-list" key={product.timestamp}>
                            <li>
                                <h6 className="position-relative">
                                    {/* <span className = {`menu-item-name ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptItemType === 'menu_item') ? "selected" : ""} `}
                                    onClick = {() => handleOnClickSelectReceiptMenuItem(product)} > {product.item_type === "refunded_item" ? 'Refund ' : null}{product.name} </span> */}
                                    <span className = {`menu-item-name ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptItemType === 'menu_item') ? "selected" : ""} `}
                                    onClick = {() => handleOnClickMenuItem(product,'menu_item')} > {product.item_type === "refunded_item" ? 'Refund ' : null} {product.is_size_selected ? `${product.size_name} ` : null} {product.is_special_modifier_selected ? `${product.special_modifier_name} ` : null} {product.name} </span>
                                    <span className="position-absolute menu-item-price">
                                    {product.item_type === "refunded_item" ? '-' : null}${(parseFloat(product.price)).toFixed(2) }
                                        {/* <img src={trashRedPng} alt="trash" className="mw-100 cursor-pointer trash-icon"  onClick={()=> removeItem(product,'menu_item')}  /> */}
                                    </span>
                                </h6>
                                {
                                    product.ingredients.length > 0 ? RenderMenuItemIngredients(product,product.ingredients,removeIngredientFromCartMenuItem,selectedOnReceiptMenuItemId,selectedOnReceiptMenuItemTimeStamp,selectedOnReceiptItemType,selectedOnReceiptMenuItemIngredientId,selectedOnReceiptMenuItemIngredient,handleOnClickSelectReceiptMenuItemIngredient,confirmAlert) : null
                                }
                                <CartItemSubBlock
                                    mainProduct={product}
                                    products={product.selectedComboItems}
                                    type="cart"
                                    removeSubItemFromCart={removeSubItemFromCart}
                                    removeIngredientFromCartComboItem={removeIngredientFromCartComboItem}
                                    handleOnClickSelectReceiptMenuItemComboItem={handleOnClickSelectReceiptMenuItemComboItem}
                                    selectedOnReceiptMenuItemId={selectedOnReceiptMenuItemId}
                                    selectedOnReceiptMenuItemTimeStamp={selectedOnReceiptMenuItemTimeStamp}
                                    selectedOnReceiptItemType={selectedOnReceiptItemType}
                                    selectedOnReceiptMenuItemComboItemId={selectedOnReceiptMenuItemComboItemId}
                                    selectedOnReceiptMenuItemComboItemIngredientId={selectedOnReceiptMenuItemComboItemIngredientId}
                                    selectedOnReceiptMenuItemComboItemIngredient={selectedOnReceiptMenuItemComboItemIngredient}
                                    handleOnClickSelectReceiptComboItemIngredient={handleOnClickSelectReceiptComboItemIngredient}
                                    confirmAlert={confirmAlert}
                                />
                                
                            </li>
                    </ul>
                }
            })

        :null
    )


    return (
        <div>
            {products ?
                        products.length === 0 ?
                            <div className="text-center mt20 mb20">
                                <h3>The cart is empty</h3>
                            </div>
                        :null
                    :null}
            {renderItems()}
        </div>
    );
};

export default CartItemBlock;