import {
    // GET_MENU_ITEMS_BY_CATEGORY_ID,
    FETCH_MULTIPLE_MENU_ITEM_BY_ID,
    FETCH_MULTIPLE_MENU_ITEM_INGREDIENTS_BY_ID,
    CLEAR_MENU_ITEMS,
    FETCH_MENU_ITEMS,
    FETCH_INGREDIENTS,
    FETCH_INGREDIENT_MENU_ITEMS,
} from '../actions/types';
 

/* export default function(state={},action){
    switch(action.type){
        case GET_MENU_ITEMS_BY_CATEGORY_ID:
        console.log('action.payload.total_menu_items')
        console.log(action.payload.total_menu_items)
        return {
                ...state,
                MenuItems: action.payload.MenuItems,
                total_menu_items: action.payload.total_menu_items
            }
        default:
            return state;
    }
} */

export default function(state=[],action){
    switch(action.type){
        case FETCH_MULTIPLE_MENU_ITEM_BY_ID:
            // console.log('action.payload in FETCH_MULTIPLE_MENU_ITEM_BY_ID')
            // console.log(action.payload)
            return {
                    ...state,
                    MenuItemsMultiple: action.payload.MenuItemsMultiple,
                    // total_menu_items: action.payload.total_menu_items
                }
        case FETCH_MULTIPLE_MENU_ITEM_INGREDIENTS_BY_ID:
            // console.log('action.payload in FETCH_MULTIPLE_MENU_ITEM_INGREDIENTS_BY_ID')
            // console.log(action.payload)
            return {
                    ...state,
                    MenuItemsIngredientsMultiple: action.payload.MenuItemsIngredientsMultiple,
                    // total_menu_items: action.payload.total_menu_items
                }
        case CLEAR_MENU_ITEMS:
            return {...state, MenuItems: action.payload,total_menu_items: 0 }
        case FETCH_MENU_ITEMS:
            return {...state, MenuItemsAll: action.payload.MenuItems }
        case FETCH_INGREDIENTS:
            return {...state, IngredientsAll: action.payload.Ingredients }
        case FETCH_INGREDIENT_MENU_ITEMS:
            return {...state, IngredientMenuItemsAll: action.payload.IngredientMenuItems }
        default:
            return state;
    }
}