import React,{Fragment} from 'react';
const MenuItemSize = (props) => {

    // console.log('props in menusize')
    // console.log('props in menusize')
    // console.log('props in menusize')
    // console.log(props)
    const sizes = props.Sizes;
    return (
        <Fragment>
            {
                (sizes[0] && sizes[0].size_one && sizes[0].price_one) ?
                <button className={`btn btn-danger btn-circle btn-xl btn-block py-2 mb-3`} onClick={()=>props.handleOnClickMenuCategoryMenuItemSize(props.menuCategoryItem,sizes[0],sizes[0].size_one,sizes[0].price_one)}>
                    {sizes[0].size_one}
                </button>
            : null 
            }
            {
                (sizes[1] && sizes[1].size_two && sizes[1].price_two) ?
                <button className={`btn btn-danger btn-circle btn-xl btn-block py-2 mb-3`} onClick={()=>props.handleOnClickMenuCategoryMenuItemSize(props.menuCategoryItem,sizes[1],sizes[1].size_two,sizes[1].price_two)}>
                    {sizes[1].size_two}
                </button>
            : null 
            }
            {
                (sizes[2] && sizes[2].size_three && sizes[2].price_three) ?
                <button className={`btn btn-danger btn-circle btn-xl btn-block py-2 mb-3`} onClick={()=>props.handleOnClickMenuCategoryMenuItemSize(props.menuCategoryItem,sizes[2],sizes[2].size_three,sizes[2].price_three)}>
                    {sizes[2].size_three}
                </button>
            : null 
            }
            {
                (sizes[3] && sizes[3].size_four && sizes[3].price_four) ?
                <button className={`btn btn-danger btn-circle btn-xl btn-block py-2 mb-3`} onClick={()=>props.handleOnClickMenuCategoryMenuItemSize(props.menuCategoryItem,sizes[3],sizes[3].size_four,sizes[3].price_four)}>
                    {sizes[3].size_four}
                </button>
            : null 
            }
            {
                (sizes[4] && sizes[4].size_five && sizes[4].price_five) ?
                <button className={`btn btn-danger btn-circle btn-xl btn-block py-2 mb-3`} onClick={()=>props.handleOnClickMenuCategoryMenuItemSize(props.menuCategoryItem,sizes[4],sizes[4].size_five,sizes[4].price_five)}>
                    {sizes[4].size_five}
                </button>
            : null 
            }
            {
                (sizes[5] && sizes[5].size_six && sizes[5].price_six) ?
                <button className={`btn btn-danger btn-circle btn-xl btn-block py-2 mb-3`} onClick={()=>props.handleOnClickMenuCategoryMenuItemSize(props.menuCategoryItem,sizes[5],sizes[5].size_six,sizes[5].price_six)}>
                    {sizes[5].size_six}
                </button>
            : null 
            }

            
        </Fragment>
    );
};

export default MenuItemSize;