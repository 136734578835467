import React,{Fragment,Component} from 'react';
import printImgPng from '../../../../../../assests/img/btn-icons/print-img.png';
import noKitchenImgPng from '../../../../../../assests/img/btn-icons/no-kitchen-img.png';
import cancelImgPng from '../../../../../../assests/img/btn-icons/cancel-img.png';
import checkMarkImgPng from '../../../../../../assests/img/btn-icons/check-mark-img.png';
import lineImgPng from '../../../../../../assests/img/btn-icons/line-img.png';

// import { Button } from 'reactstrap';
// import OrderReceiptPopup from '../../OrderReceiptPopup/OrderReceiptPopup';
class CashModalBody extends Component {
// const CashModalBody = (props)  => {
    render() {
        // const exitButtonStyle = {
        //     width: '125px',
        // };
        // console.log('this.props.hideButtons in mod');
        // console.log('this.props.hideButtons in mod');
        // console.log(this.props.hideButtons);
        return (
            <Fragment>
                    <div className="text-center txtChangeAmountModal">
                        $<span className="txtChangeAmount">{this.props.changeAmount}</span>  <br/>
                        Change
                    </div>
                    {
                        this.props.hideButtons === false ?
                        <div className="row CashModalBtns">
                            <div className="col">
                                <button
                                      onClick={() => this.props.toggle()}  className="btn btn-light-danger text-white p-2 w-100 h-100 mb-3 d-flex align-items-center justify-content-center" >
                                        <div>
                                            <p>
                                                <img src={cancelImgPng} alt="icons" className="mw-100 mb-2" />
                                            </p>
                                            <p>
                                                Cancel
                                            </p>
                                        </div>
                                </button>
                            </div>
                            {
                                this.props.existing_order_id === '' && this.props.isShowLoader === false ? 
                                    <div className="col">
                                        <button 
                                                ref={this.props.btnNoKitchen} onClick={()=> this.props.customToggle('no_kitchen')} className="btn btn-light-danger text-white p-2 w-100 h-100 mb-3 d-flex align-items-center justify-content-center" >
                                            <img src={lineImgPng} alt="icons" className="mw-100 mb-2 lineImg" />
                                                <div>
                                                    <p>
                                                        <img src={noKitchenImgPng} alt="icons" className="mw-100 mb-2" />
                                                    </p>
                                                    <p>
                                                        No Kitchen
                                                    </p>
                                                </div>
                                        </button>
                                    </div>
                                : null
                            }
                            <div className="col">
                                <button
                                        ref={this.props.btnPrint} onClick={()=> this.props.customToggle('print')} className="btn btn-light-danger text-white p-2 w-100 h-100 mb-3 d-flex align-items-center justify-content-center" >
                                        <img src={lineImgPng} alt="icons" className="mw-100 mb-2 lineImg" />
                                        <div>
                                            <p>
                                                <img src={printImgPng} alt="icons" className="mw-100 mb-2" />
                                            </p>
                                            <p>
                                                Print
                                            </p>
                                        </div>
                                </button>
                            </div>
                            
                            <div className="col">
                                <button
                                    ref={this.props.btnExit}  onClick={() => this.props.customToggle('exist')}  className="btn btn-light-danger text-white p-2 w-100 h-100 mb-3 d-flex align-items-center justify-content-center" >
                                    <img src={lineImgPng} alt="icons" className="mw-100 mb-2 lineImg" />
                                        <div>
                                            <p>
                                                <img src={checkMarkImgPng} alt="icons" className="mw-100 mb-2 pl-18" />
                                            </p>
                                            <p>
                                                Done
                                            </p>
                                        </div>
                                </button>
                            </div>
                            
                        </div>
                        : null
                    }
                    {/* {
                    this.props.hideButtons === false ?
                        <Fragment>
                            {
                                this.props.existing_order_id === '' && this.props.isShowLoader === false ? 
                                    <div className="text-center">
                                        <button ref={this.props.btnNoKitchen} color="warning" className="btnNotPadModalButton btn btn-warning" onClick={()=> this.props.customToggle('no_kitchen')}>No Kitchen</button>
                                    </div>
                                : null
                            }
                            <div className="text-center">
                                <button ref={this.props.btnPrint} color="warning" className="btnNotPadModalButton btn btn-warning" onClick={()=> this.props.customToggle('print')}>Print Receipt</button>
                            </div>
                            <div className="text-center">
                                <button ref={this.props.btnExit} color="warning" style={exitButtonStyle} className="btnNotPadModalButton btn btn-warning" onClick={() => this.props.customToggle('exist')}>Exit</button>
                            </div>
                        </Fragment>
                      : null
                    } */}
            </Fragment>
        );
    }
// }
}

export default CashModalBody;