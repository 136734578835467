import React, { Component } from 'react';
import { BrowserRouter, Switch, Route   } from 'react-router-dom'
import { connect } from 'react-redux';
// import * as actions from './actions';
import Header from './components/layout/Header';
// Pages
import POS from './components/Pages/POS/POS';
import Kitchen from './components/Pages/Kitchen/Kitchen';
import Test from './components/Pages/Kitchen/Test';
import PosOrders from './components/Pages/PosOrders/PosOrders';
import PrintPosOrder from './components/Pages/PrintPosOrder/PrintPosOrder';
import Login from './components/Pages/Auth/Login';
import Logout from './components/Pages/Auth/Logout';
// import Auth from './hoc/auth';
import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// minified version is also included
import 'react-toastify/dist/ReactToastify.min.css';
import * as syncService from './components/utils/syncService/syncService';
import {  fetchTodayExistingOrders,fetchTodayMistakeOrders,fetchAllKitchenOrders,fetchPosTodayOrders } from './actions/order_actions';
import localForage from 'localforage';
import Auth from './hoc/auth';
import axios from 'axios';
import KitchenCarousel from './components/Pages/KitchenCarousel/KitchenCarousel';
import DeliveryOrdersGoogleMaps from './components/Pages/DeliveryOrdersGoogleMaps/DeliveryOrdersGoogleMaps';
import { NoInternetModal } from './components/common/modals/NoInternetModal/NoInternetModal';
import orderNotificationAudio from './components/common/audio/moonless.mp3';
// console.log('localStorage.getItem()')
// console.log('localStorage.getItem()')
// console.log('localStorage.getItem()')
// console.log(localStorage.getItem('token'))
axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
let token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common['Authorization'] = token;
} else {
  axios.defaults.headers.common['Authorization'] = null;
  
}
class App extends Component {
  /* state = {
    isOnline = false
  } */
  state = {
    showNoInternetModalModal : false,
    play: false,
  }
  audio = new Audio(orderNotificationAudio);
  componentDidMount() {
    // window.addEventListener('offline', function(event){
    window.addEventListener('offline', () => {
      // console.log("You lost connection.");
      // console.log("You lost connection.");
      // console.log("You lost connection.");
      // console.log("You lost connection.");
      // console.log("You lost connection.");
      console.log("You lost connection.");
      // this.syncOfflineData();
      this.setState({showNoInternetModalModal:true});
      this.playAudio();
    });
    
    window.addEventListener('online', () => {
      // window.addEventListener('online', function(event){
      // console.log("You are now back online.");
      // console.log("You are now back online.");
      // console.log("You are now back online.");
      // console.log("You are now back online.");
      // console.log("You are now back online.");
      console.log("You are now back online.");
      this.setState({showNoInternetModalModal:false});
      this.pauseAudio();
      this.syncOfflineData();
    });
    this.syncOfflineData();
    window.localForage = localForage;
    window.App = this;
    // window.addEventListener('offline',this.syncOfflineData());
    // window.addEventListener('online',this.syncOfflineData());
  }

  componentWillUnmount() {
    window.removeEventListener('offline',() => {
      this.syncOfflineData()
    });
    window.removeEventListener('online',() => {
      this.syncOfflineData()
    });
  }
  handleCloseShowNoInternetModalModal = () => {
    console.log('handleCloseShowNoInternetModalModal') 
    console.log('handleCloseShowNoInternetModalModal') 
    console.log('handleCloseShowNoInternetModalModal') 
    console.log('handleCloseShowNoInternetModalModal') 
    this.pauseAudio();
    this.setState({ showNoInternetModalModal: false })
  }
  // playNotificationAudioPlay = () => {
  //   // console.log('this.audio')
  //   // console.log('this.audio')
  //   // console.log(this.audio)
  //   this.setState({ play: true }, () => {
  //     if(this.state.play){
  //         this.audio.loop = true
  //         this.audio.load();
  //         // this.audio.play();
  //         this.playAudio();
  //     }else{
  //         this.audio.loop = false;
  //         this.audio.load();
  //         this.audio.pause()
  //     }
  //     // this.state.play ? this.audio.play() : this.audio.pause();
  //   });
  // }
  // toggleNotificationAudioPlay = () => {
  //   // console.log('this.audio')
  //   // console.log('this.audio')
  //   // console.log(this.audio)
  //   this.setState({ play: !this.state.play }, () => {
  //       if(this.state.play){
  //           this.audio.loop = true
  //           this.audio.load();
  //           // this.audio.play();
  //           this.playAudio();
  //       }else{
  //           this.audio.loop = false;
  //           this.audio.load();
  //           this.audio.pause()
  //       }
  //       // this.state.play ? this.audio.play() : this.audio.pause();
  //   });
  // }
  playAudio = () =>{
    this.setState({ play: true }, () => {
      this.audio.loop = true
      this.audio.load();
      this.audio.play();
    })
      // const audioPromise = this.audio.play()
      // if (audioPromise !== undefined) {
      // audioPromise
      //     .then(_ => {
      //         // autoplay started
      //         // console.log('i am in prmose');
      //         // console.log(_)
      //         // console.log(_)
      //         // console.log(_)
      //     })
      //     .catch(err => {
      //         // catch dom exception
      //         console.info('err in playing audio')
      //         console.info(err)
      //     })
      // }
  }
  pauseAudio = () =>{
    console.log('pauseAudio')
    console.log('pauseAudio')
    console.log('pauseAudio')
    console.log('pauseAudio')
    this.setState({ play: false }, () => {
      this.audio.loop = false;
      this.audio.load();
      this.audio.pause()
    });
  }

  syncOfflineData = async () => {
    // console.log('i am in sycn service');
    // console.log('i am in sycn service');
    // console.log('i am in sycn service');
    // console.log('i am in sycn service');
    // console.log('start syncService.syncNewOrders()')
    await syncService.syncNewOrders();
    // console.log('end syncService.syncNewOrders()')
    // console.log('start syncService.syncEditedOrders()')
    await syncService.syncEditedOrders();
    // console.log('end syncService.syncEditedOrders()')
    // console.log('start syncService.syncOrdersSatusChanged()')
    await syncService.syncOrdersSatusChanged();
    // console.log('end syncService.syncOrdersSatusChanged()')
    // console.log('start syncService.syncDeletedOrders()')
    await syncService.syncDeletedOrders();
    // console.log('end syncService.syncDeletedOrders()')
    // console.log('Now sync other data');
    this.props.fetchTodayExistingOrders(
      'pos',
      'regular',
      'done',
      new Date(),
      1000,
      1000,
      []
      // this.props.order.todayExistingOrders
    ).then(response => {
      
    });
    this.props.fetchTodayMistakeOrders(
      'pos',
      'mistake',
      'done',
      new Date(),
      1000,
      1000,
      []
      // this.props.order.todayMistakeExistingOrders
    ).then(response => {});
    this.props.fetchAllKitchenOrders(
      'pos',
      10000,
      10000,
      []
      // this.props.order.allPosKitchenOrders
    ).then(response => {
    });
    this.props.fetchPosTodayOrders(
      'pos',
      new Date(),
      1000,
      1000,
      []
      // this.props.order.todayPosOrders
    ).then(response => {
    });
    // await syncService.syncDeletedCustomers();
    // await syncService.syncNewSales();
    // await syncService.syncEditedSales();
  }
  render() {
    const AuthPage = Auth(Test,true,true);
    return (
      <BrowserRouter>
          <Header />
          <ToastContainer />
          <NoInternetModal
            showNoInternetModalModal={this.state.showNoInternetModalModal} 
            handleCloseShowNoInternetModalModal={this.handleCloseShowNoInternetModalModal}
          />
          <Switch>
            {/* <Route exact path='/' component={POS}/>
            <Route exact path='/kitchen' component={Kitchen} />
            <Route exact path='/test' component={AuthPage} />
            <Route exact path='/pos-orders' component={PosOrders} />
            <Route exact path='/print-pos-order/:order_id' component={PrintPosOrder} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/logout' component={Logout} /> */}
            <Route exact path='/' component={Auth(POS,true,true)}/>
            <Route exact path='/map' component={DeliveryOrdersGoogleMaps} />
            <Route exact path='/kitchen' component={KitchenCarousel} />
            <Route exact path='/kitchen-old-without-carousel' component={Kitchen} />
            {/* <Route exact path='/kitchen-carousel' component={KitchenCarousel} /> */}
            {/* <Route exact path='/kitchen' component={Auth(Kitchen,true,true)} /> */}
            <Route exact path='/test' component={AuthPage} />
            <Route exact path='/pos-orders' component={Auth(PosOrders,true,true)} />
            <Route exact path='/print-pos-order/:order_id' component={Auth(PrintPosOrder,true,true)} />
            <Route exact path='/login' component={Auth(Login,false,true)} />
            <Route exact path='/logout' component={Logout} />
          </Switch>
      </BrowserRouter>
    );
  }
}
const mapStateToProps = (state) => {
  // console.log('state in POS')
  // console.log(state)
  return {
      // To see why i have commented this
      // https://stackoverflow.com/questions/52171004/react-redux-infinite-loop-when-dispatch
      // Be careful with mapStateToProps, you should only select the part of the store you're interested in, otherwise you performance problems could occur
      //     const mapStateToProps = state => ({auth: state.auth});

      // A little explanation how react-redux connect works,

      //     each time there is a modification in the store (from the reducers), the mapStateToProps functions of all the connected components are executed
      //     if the one prop in the returned object is different from the previous one (the operator === is used) then the component is re-rendered otherwise it does nothing.

      // In your example, as you select all the props of the store, your component will be re-rendered for each modification in the store
      // order : state.order,
  };
}
export default connect(mapStateToProps,{
  fetchTodayExistingOrders,
  fetchTodayMistakeOrders,
  fetchAllKitchenOrders,
  fetchPosTodayOrders,
} )(App);
// export default connect(null, actions)(App);
// export default App;