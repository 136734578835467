import React from 'react';
const OrderReceiptPopupRenderMenuItemIngredients = (product, ingredients) => {
    // console.log('product')
    // console.log(product.name)
    // console.log('ingredients')
    // console.log(ingredients)
    // console.log(ingredients.length) 
    // ingredients = ingredients.filter(i => i.is_checked === true);
    // console.log('ingredients after')
    // console.log(ingredients)
    if(ingredients.length > 0){
        // let rowLen =ingredients.length;
        // return images[0].url
        let ret = ingredients.map((ingredient,index) => {
            // console.log('ingredient.name')
            // console.log(ingredient.name)
            // console.log('ingredient.ingredient_category_type_blue')
            // console.log(ingredient.ingredient_category_type_blue)
            // console.log('ingredient.ingredientType')
            // console.log(ingredient.ingredientType)
            /* if (owned_game.game_id === game.game_id) {
              return renderOwned
            } else {
              return renderPrice
            } */
            /* if (rowLen === index + 1) {
                // last one
                return <li key={index}> {ingredient.name}  </li>;
            } else { */
                // not last one
                if(ingredient.added_from === 'web'){
                    if(ingredient.ingredientType === "extras_charged" && ingredient.is_checked === true){
                        // coding for the web ingredinats listing
                        // return <p className="ingredient" key={index}> <span className={`menu-item-ingredient ${(selectedOnReceiptMenuItemId === product.menu_item_id && selectedOnReceiptMenuItemTimeStamp === product.timestamp && selectedOnReceiptMenuItemIngredientId === ingredient._id && selectedOnReceiptMenuItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptItemType === 'menu_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickSelectReceiptMenuItemIngredient(product,ingredient)}> Only {ingredient.name} </span> <span className="float-right">${parseFloat(ingredient.price).toFixed(2)} {trashButton}</span>  </p>;
                        return <tr className="service" key={index}>
                                    <td className="tableitem" > 
                                        <p className="itemtext itemTextName ingName">Add {ingredient.name}</p>  
                                    </td>
                                    <td className="tableitem" > 
                                        <p className="itemtext ingPrice">${parseFloat(ingredient.price).toFixed(2)}</p>  
                                    </td>
                                </tr>
                    }else if(ingredient.ingredientType === "included" && ingredient.is_checked === false){
                        // coding for the web ingredinats listing
                        return <tr className="service" key={index}>
                                    <td className="tableitem" > 
                                        <p className="itemtext itemTextName ingName">No {ingredient.name}</p>  
                                    </td>
                                    <td className="tableitem" > 
                                        <p className="itemtext ingPrice"></p>  
                                    </td>
                                </tr>
                    }else if(ingredient.ingredientType === "both" && ingredient.is_checked === false){
                        // coding for the web ingredinats listing
                        return <tr className="service" key={index}>
                                    <td className="tableitem" > 
                                        <p className="itemtext itemTextName ingName">No {ingredient.name}</p>  
                                    </td>
                                    <td className="tableitem" > 
                                        <p className="itemtext ingPrice"></p>  
                                    </td>
                                </tr>
                    }else if(ingredient.ingredientType === "optional_free" && ingredient.is_checked === true){
                        // coding for the web ingredinats listing
                        return  <tr className="service" key={index}>
                                    <td className="tableitem" > 
                                        <p className="itemtext itemTextName ingName">Add {ingredient.name}</p>  
                                    </td>
                                    <td className="tableitem" > 
                                        <p className="itemtext ingPrice"></p>  
                                    </td>
                                </tr>
                    }
                }else{
                    if(ingredient.ingredient_category_type_blue === "no" && ingredient.is_checked === true){
                        if(ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged'){
                            return  <tr className="service" key={index}>
                                        <td className="tableitem" > 
                                            <p className="itemtext itemTextName ingName">No {ingredient.name}</p>  
                                        </td>
                                        <td className="tableitem" > 
                                            <p className="itemtext ingPrice">${parseFloat(ingredient.price).toFixed(2)}</p>  
                                        </td>
                                    </tr>
                        }else{
                            return  <tr className="service" key={index}>
                                        <td className="tableitem" > 
                                            <p className="itemtext itemTextName ingName"> No {ingredient.name}</p>  
                                        </td>
                                        <td className="tableitem" > 
                                            <p className="itemtext ingPrice"></p>  
                                        </td>
                                    </tr>
                        }
                    }else if(ingredient.ingredient_category_type_blue === "extra" && ingredient.is_checked === true){
                        if(ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged'){
                            return <tr className="service" key={index}>
                                        <td className="tableitem" > 
                                            <p className="itemtext itemTextName ingName">Extra {ingredient.name}</p>  
                                        </td>
                                        <td className="tableitem" > 
                                            <p className="itemtext ingPrice">${parseFloat(ingredient.price).toFixed(2)}</p>  
                                        </td>
                                    </tr>
                        }else{
                            return  <tr className="service" key={index}>
                                        <td className="tableitem" > 
                                            <p className="itemtext itemTextName ingName">Extra {ingredient.name}</p>  
                                        </td>
                                        <td className="tableitem" > 
                                            <p className="itemtext ingPrice"></p>  
                                        </td>
                                    </tr>
                            
                        }
                    }else if(ingredient.ingredient_category_type_blue === "add" && ingredient.is_checked === true){
                        if(ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged'){
                            return  <tr className="service" key={index}>
                                        <td className="tableitem" > 
                                            <p className="itemtext itemTextName ingName">Add {ingredient.name}</p>  
                                        </td>
                                        <td className="tableitem" > 
                                            <p className="itemtext ingPrice">${parseFloat(ingredient.price).toFixed(2)}</p>  
                                        </td>
                                    </tr>
                        }else{
                            return  <tr className="service" key={index}>
                                        <td className="tableitem" > 
                                            <p className="itemtext itemTextName ingName">Add {ingredient.name}</p>  
                                        </td>
                                        <td className="tableitem" > 
                                            <p className="itemtext ingPrice"></p>  
                                        </td>
                                    </tr>
                        }
                    }else if(ingredient.ingredient_category_type_blue === "light" && ingredient.is_checked === true){
                        if(ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged'){
                            return  <tr className="service" key={index}>
                                        <td className="tableitem" > 
                                            <p className="itemtext itemTextName ingName">Light {ingredient.name}</p>  
                                        </td>
                                        <td className="tableitem" > 
                                            <p className="itemtext ingPrice">${parseFloat(ingredient.price).toFixed(2)}</p>  
                                        </td>
                                    </tr>
                        }else{
                            return  <tr className="service" key={index}>
                                        <td className="tableitem" > 
                                            <p className="itemtext itemTextName ingName">Light {ingredient.name}</p>  
                                        </td>
                                        <td className="tableitem" > 
                                            <p className="itemtext ingPrice"></p>  
                                        </td>
                                    </tr>
                        }
                    }else if(ingredient.ingredient_category_type_blue === "only" && ingredient.is_checked === true){
                        if(ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged'){
                            return <tr className="service" key={index}>
                                        <td className="tableitem" > 
                                            <p className="itemtext itemTextName ingName">Only {ingredient.name}</p>  
                                        </td>
                                        <td className="tableitem" > 
                                            <p className="itemtext ingPrice">${parseFloat(ingredient.price).toFixed(2)}</p>  
                                        </td>
                                    </tr>
                            
                        }else{
                            return  <tr className="service" key={index}>
                                        <td className="tableitem" > 
                                            <p className="itemtext itemTextName ingName">Only {ingredient.name}</p>  
                                        </td>
                                        <td className="tableitem" > 
                                            <p className="itemtext ingPrice"></p>  
                                        </td>
                                    </tr>
                        }
                    }else if(ingredient.ingredient_category_type_blue === "well_done" && ingredient.is_checked === true){
                        if(ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged'){
                            return <tr className="service" key={index}>
                                        <td className="tableitem" > 
                                            <p className="itemtext itemTextName ingName">Well Done {ingredient.name}</p>  
                                        </td>
                                        <td className="tableitem" > 
                                            <p className="itemtext ingPrice">${parseFloat(ingredient.price).toFixed(2)}</p>  
                                        </td>
                                    </tr>
                        }else{
                            return <tr className="service" key={index}>
                                        <td className="tableitem" > 
                                            <p className="itemtext itemTextName ingName">Well Done {ingredient.name}</p>  
                                        </td>
                                        <td className="tableitem" > 
                                            <p className="itemtext ingPrice"></p>  
                                        </td>
                                    </tr>
                        }
                    }else if (ingredient.ingredient_category_type_blue === "put_on_side" && ingredient.is_checked === true) {
                        if (ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged') {
                            return <tr className="service" key={index}>
                                <td className="tableitem" >
                                    <p className="itemtext itemTextName ingName">Put {ingredient.name} on side</p>
                                </td>
                                <td className="tableitem" >
                                    <p className="itemtext ingPrice">${parseFloat(ingredient.price).toFixed(2)}</p>
                                </td>
                            </tr>
                        } else {
                            return <tr className="service" key={index}>
                                <td className="tableitem" >
                                    <p className="itemtext itemTextName ingName"> Put {ingredient.name} on side</p>
                                </td>
                                <td className="tableitem" >
                                    <p className="itemtext ingPrice"></p>
                                </td>
                            </tr>
                        }
                    }else if(ingredient.is_note){
                        if(ingredient.is_paid_notes){
                            return <tr className="service" key={index}>
                                        <td className="tableitem" > 
                                            <p className="itemtext itemTextName ingName">{ingredient.notes}</p>  
                                        </td>
                                        <td className="tableitem" > 
                                            <p className="itemtext ingPrice">${parseFloat(ingredient.extra_charges).toFixed(2)}</p>  
                                        </td>
                                    </tr>
                            
                        }else{
                            return  <tr className="service" key={index}>
                                        <td className="tableitem" > 
                                            <p className="itemtext itemTextName ingName">{ingredient.notes}</p>  
                                        </td>
                                        <td className="tableitem" > 
                                            <p className="itemtext ingPrice"></p>  
                                        </td>
                                    </tr>
                        }
                    }
                }
                // return <span key={index}> {ingredient.name} , </span>;
            // }
            
        })
        return ret;
        
    } else {
        // return '/images/image_not_availble.png'
        return null;
    }
}

export default OrderReceiptPopupRenderMenuItemIngredients;