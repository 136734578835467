import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import KitchenHead from './components/KitchenHead';
import LoadMorePosKitchenOrderItems from './components/LoadMorePosKitchenOrderItems';
import { fetchKitchenOrders,fetchAllKitchenOrders,clearKitchenOrderHistory,changeKitchenOrderStatus,addNewPosOrderToKitchenDisplay,updtaePosOrderToKitchenDisplay  } from '../../../actions/order_actions';
import { fetchPosComboItemColor  } from '../../../actions/settings_actions';
import LoadingSpinner from '../../utils/LoadingSpinner';
import LoadingCircle from '../../common/components/LoadingCircle';
import { socket } from '../../layout/Header';
class Test extends Component {
    _isMounted = false;
    constructor(props) {
        super(props)
    }
    state = {
        limit:8,
        skip:0,
        isloadingPosKitchenOrders: false,
        isShowLoader:false,
        showKitchenOrderMenuOptionsModal:false,
        comboItemColor:'#42e137', 
        selectedOrder:{},
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        console.log('i am in componentDidMount')
        if (this._isMounted) {
            this.setState({isloadingPosKitchenOrders:true});
        }
        /* this.props.fetchPosComboItemColor().then(response => {
            // console.log('this.props.objPosComboItemColor in resp');
            // console.log(this.props.objPosComboItemColor);
            if (this._isMounted) {
                this.setState({comboItemColor:this.props.objPosComboItemColor.pos_combo_item_color});
            }
            // console.log(this.props.objtax);
        }); */
        if (this._isMounted) {
            this.props.fetchAllKitchenOrders(
                'pos',
                this.state.skip,
                this.state.limit,
                this.props.order.allPosKitchenOrders
            ).then(response => {
            });
            /* this.props.fetchKitchenOrders(
                'pos',
                this.state.skip,
                this.state.limit,
                this.props.order.posKitchenOrders
            ).then(response => {
                console.log('this.props.order');
                console.log(this.props.order);
                if (this._isMounted) {
                    // this.setState({isloadingPosKitchenOrders:false});
                }
            }); */
        }
        
        
        window.Test = this;
    }
    
    
    render() {
       
        /* console.log('order.posKitchenOrders')
        console.log(order.posKitchenOrders)
        console.log('order.allPosKitchenOrders')
        console.log(order.allPosKitchenOrders) */
        // console.log(order.posKitchenOrders.sort((a, b) => a.order_completion_datetime.localeCompare(b.order_completion_datetime)))
        return (
            <div className="bg-dark">
                
                
            </div>
            
        
        )
    }
}

function mapStateToProps(state) {
    return {
        order : state.order,
        objPosComboItemColor : state.settings.objPosComboItemColor
    };
}

// export default Home
export default connect(mapStateToProps, {fetchKitchenOrders,fetchAllKitchenOrders,clearKitchenOrderHistory,fetchPosComboItemColor,changeKitchenOrderStatus,addNewPosOrderToKitchenDisplay,updtaePosOrderToKitchenDisplay})(withRouter(Test));
