import React,{Fragment} from 'react';
// import seventheenPng from '../../../../../common/images/icons/17.png'
// import eitheenPng from '../../../../../common/images/icons/18.png'
import MenuCategory from './MenuCategory';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
/* import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; */
/* const $ = require('jquery');
window.jQuery = window.$ = $;
const OwlCarousel = require('react-owl-carousel');
require('owl.carousel/dist/assets/owl.carousel.css');
require('owl.carousel/dist/assets/owl.theme.default.css');
window.jQuery = $;
window.$ = $;
global.jQuery = $; */
const settings = {
    /* className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,
    rows: 2,
    slidesPerRow: 2 */
    // For one row
    /* infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    rows: 1, */
    // New
    vertical:false,
    accessibility: true,
    centerMode: true,
    centerPadding: '0',
    infinite: false,
    variableWidth: false,
    speed: 200,
    focusOnSelect: true,
    // slidesToShow: 7,
    // slidesToScroll: 7,
    rows: 2, 
    slidesPerRow: 7
};
class MenuCategories extends React.Component{
    render(){
        const MenuCategories = this.props.MenuCategories;
        const selectedMenuCategoryId = this.props.selectedMenuCategoryId;
        // console.log('selectedMenuCategoryId in MenuCategories')
        // console.log(selectedMenuCategoryId)
        const renderCategories = (MenuCategories) => (
            MenuCategories ? 
                MenuCategories.map(card=>(
                    <div key={card._id}>
                        <MenuCategory
                            key={card._id}
                            {...card}
                            handleOnClickMenuCategory={this.props.handleOnClickMenuCategory}
                            // isRefundActive={this.props.isRefundActive}
                            selectedMenuCategoryId={selectedMenuCategoryId}
                            // propData={...this.props}
                        />
                    </div>
                ))
            :null
        )
        let showLoop = MenuCategories.length > 8;
        // console.log('showLoop')
        // console.log('showLoop')
        // console.log(showLoop)
        return (
            <Fragment>
                <div id="" className="food-menus-menu w-100">
                    {/* <div className="row"> */}
                        <Slider {...settings}>
                            {MenuCategories ?
                                MenuCategories.length === 0 ?
                                    <div className="no_result">
                                        Sorry, no results
                                        </div>
                                    : null
                                : null}
                            {renderCategories(MenuCategories)}
                            {/* <div>
                                <h3>1st</h3>
                            </div>
                            <div>
                                <h3>2</h3>
                            </div>
                            <div>
                                <h3>3</h3>
                            </div>
                            <div>
                                <h3>4</h3>
                            </div>
                            <div>
                                <h3>5</h3>
                            </div>
                            <div>
                                <h3>6</h3>
                            </div>
                            <div>
                                <h3>7</h3>
                            </div>
                            <div>
                                <h3>8</h3>
                            </div>
                            <div>
                                <h3>9</h3>
                            </div>
                            <div>
                                <h3>10</h3>
                            </div>
                            <div>
                                <h3>11</h3>
                            </div>
                            <div>
                                <h3>12</h3>
                            </div>
                            <div>
                                <h3>13</h3>
                            </div>
                            <div>
                                <h3>14</h3>
                            </div>
                            <div>
                                <h3>15</h3>
                            </div>
                            <div>
                                <h3>16</h3>
                            </div> */}
                        </Slider>
                        {/* <OwlCarousel
                            className="owl-theme"
                            // loop={true}showLoop
                            loop={showLoop}
                            items={9}
                            autoplay={false}
                            autoplayTimeout={3000}
                            autoplayHoverPause={false}
                            nav={true}
                            navElement={'div'}
                            navText={[`<img src=${seventheenPng}>`,`<img src=${eitheenPng}>`]}
                            dots={false}
                            responsive={{
                                0:{
                                    items:4
                                },
                                600:{
                                    items:3
                                },
                                1000:{
                                    items:7
                                }
                            }}
                        >
                        
                            {MenuCategories ?
                                MenuCategories.length === 0 ?
                                    <div className="no_result">
                                        Sorry, no results
                                    </div>
                                :null
                            :null}
                            { renderCategories(MenuCategories)}
                            
                        </OwlCarousel> */}
                    {/* </div> */}
                </div>
                   
            </Fragment>
        )
    }
};

export default MenuCategories;