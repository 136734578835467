import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import MistakeExistingOrdersModalBody from './MistakeExistingOrdersModalBody';
class MistakeExistingOrdersModal extends React.Component {
    constructor(props) {
        super(props);
        /* this.state = {
            modal: props.showMistakeExistingOrdersModal
        }; */
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        /* this.setState({
            modal: !this.props.showMistakeExistingOrdersModal
            // modal: !this.state.modal
        }); */
        this.props.handleOnChangeMistakeExistingOrderModalToggle(!this.props.showMistakeExistingOrdersModal)
        this.props.clearMistakeExistingOrderHistory();
        this.props.clearMistakeExistingOrderLimits();
        /* this.setState(prevState => ({
            modal: !prevState.modal
        })); */
    }
    
    render() {
        /* console.log('this.state.modal in MistakeExistingOrdersModal')
        console.log(this.state.modal)
        console.log('this.props.showMistakeExistingOrdersModal')
        console.log(this.props.showMistakeExistingOrdersModal) */
        
        return (
                <Modal isOpen={this.props.showMistakeExistingOrdersModal} toggle={this.toggle} className={this.props.className} size="lg">
                    <ModalHeader toggle={this.toggle}>Mistake Existing Orders</ModalHeader>
                    <ModalBody>
                        <MistakeExistingOrdersModalBody 
                            order={this.props.order}
                            onClickHandleLoadMoreMistakeExistingOrderItems={this.props.onClickHandleLoadMoreMistakeExistingOrderItems}
                            limitMistakeExstingOrders={this.props.limitMistakeExstingOrders}
                            isloadingMistakeExistingOrders={this.props.isloadingMistakeExistingOrders}
                            handelOnClickMistakeExistingOrder={this.props.handelOnClickMistakeExistingOrder}
                        />
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.toggle}>Done</Button>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter> */}
                </Modal>
        );
    }
};

export default MistakeExistingOrdersModal;