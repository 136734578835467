// SERVER ROUTES
export const BASE_URL = (process.env.NODE_ENV === 'production') ? process.env.REACT_APP_BASE_URL_PRODUCTION : process.env.REACT_APP_BASE_URL;
// export const BASE_URL = (process.env.NODE_ENV === 'production') ? process.env.REACT_APP_BASE_URL_PRODUCTION : process.env.REACT_APP_BASE_URL;
// export const ORIGIN_ADDRESS = (process.env.NODE_ENV === 'production') ? '6082 Tecumseh Rd E, Windsor, ON N8T 1E3, Canada' : '6082 Tecumseh Rd E, Windsor, ON N8T 1E3, Canada';
// export const ORIGIN_ADDRESS = (process.env.NODE_ENV === 'production') ? '6082 Tecumseh Rd E, Windsor, ON N8T 1E3, Canada' : 'Forks n Knives Crisp n Grill, Susan Road, Faisalabad, Pakistan';
export const ORIGIN_ADDRESS = (process.env.NODE_ENV === 'production') ? '6082 Tecumseh Rd E, Windsor, ON N8T 1E3, Canada' : '6082 Tecumseh Rd E, Windsor, ON N8T 1E3, Canada';
// export const USER_SERVER = '/api/users';
export const USER_SERVER = '/api/pos/users';
export const PRODUCT_SERVER = '/api/product';
export const ORDER_SERVER = '/api/orders';
export const POS_ORDER_SERVER = '/api/pos/orders';
export const TABLET_ORDER_SERVER = '/api/tablet/orders';
export const SITE_SERVER = '/api/site';
// export const S3_URL = 'https://s3.amazonaws.com/juzbi/';
export const S3_URL = process.env.REACT_APP_S3_URL;

// console.log('BASE_URL')
// console.log(BASE_URL)
// console.log('process.env.REACT_APP_BASE_URL_PRODUCTION')
// console.log(process.env.REACT_APP_BASE_URL_PRODUCTION)
// console.log('process.env.NODE_ENV')
// console.log(process.env.NODE_ENV)
// console.log(process.env.NODE_ENV)
// console.log(process.env.NODE_ENV)
// console.log(process.env.NODE_ENV)
// 'http://localhost:5000';
/* if(process.env.NODE_ENV == 'development'){
    export const BASE_URL = process.env.BASE_URL;
}else if(process.env.NODE_ENV == 'production'){
    export const BASE_URL = process.env.BASE_URL_PRODUCTION;
} */
