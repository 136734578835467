import React from 'react';
// import {formatExistingOrderDate,formatExistingOrderDateTimeOnly,formatExistingOrderDateTime,isDateMatches} from '../../../../../utils/general';
// import RenderDeliveryTypeMistake from './RenderDeliveryTypeMistake';
const OrderItemMistake = (props) => {


        // console.log('props in OrderItemMistake')
        // console.log(props)
        return (
                <tr className="cursor-pointer" onClick={()=> props.handelOnClickMistakeExistingOrder(props)}>
                    <td>
                        {props.phone_no}
                        {/* <RenderDeliveryTypeMistake   
                            selectedOrderType={props.delivery_type}
                        /> */}
                    </td>
                    <td>
                        {props.fullname}
                    </td>
                    {/* <td>
                        {formatExistingOrderDateTimeOnly(props.createdAt)} <br />
                        {
                                isDateMatches(props.createdAt,new Date()) === false ?
                                    <Fragment> {formatExistingOrderDateTime(props.createdAt)} </Fragment>
                                :null
                            }

                    </td> */}
                    <td>
                        ${parseFloat(props.final_total).toFixed(2)}
                    </td>
                    
                </tr>
        );
}



export default OrderItemMistake;