import React, { useState } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';

const MapsMenu = (props) => {
  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);

  return (
    <section className="kitchen-head">
        <div className="container-fluid bg-white">
            <div className="row">
                <div className="col-12 d-flex justify-content-end">
                    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle className="btn btn-none-bg d-flex align-items-center btnMenuDropDownToggle" caret>
                            Menu
                        </DropdownToggle>
                        <DropdownMenu right>
                            <Link to="/" target="_blank"><DropdownItem>POS</DropdownItem></Link>
                            <Link to="/pos-orders" target="_blank"><DropdownItem>POS Orders</DropdownItem></Link>
                            <Link to="/kitchen" target="_blank"><DropdownItem>Kitchen Display Window 1</DropdownItem></Link>
                            <a href={`https://${process.env.REACT_APP_API_DOMAIN}.${process.env.REACT_APP_FULL_DOMAIN}/admin/login`} target="_blank" rel="noopener noreferrer"><DropdownItem>Admin Pannel</DropdownItem></a>
                            {/* <DropdownItem header>Header</DropdownItem> */}
                            {/* <DropdownItem disabled>Action</DropdownItem> */}
                            <DropdownItem divider />
                            <Link to="/logout" ><DropdownItem>Logout</DropdownItem></Link>
                        </DropdownMenu>
                    </ButtonDropdown>
                </div>
            </div>
        </div>
    </section>
  );
}

export default MapsMenu;
