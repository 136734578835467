import React,{Fragment} from 'react';
import MenuItemSpecialModifier from './MenuItemSpecialModifier'
const MenuItemSpecialModifiers = (props) => {

    // console.log('props in MenuItemSpecialModifiers')
    // console.log(props)
    const SpecialModifiers = props.SpecialModifiers;
    const menuCategoryItem = props.menuCategoryItem;
    const renderMenuItemsSpecialModifiers = (menuCategoryItem,SpecialModifiers) => (
        // SpecialModifiers ? 
            // SpecialModifiers.slice(0,props.limitMenuItems).map((card, index)=>(
                <MenuItemSpecialModifier
                    // key={index}
                    // {...card}
                    SpecialModifiers={SpecialModifiers}
                    handleOnClickMenuCategoryMenuItemSpecialModifier={props.handleOnClickMenuCategoryMenuItemSpecialModifier}
                    selectedMenuCategoryItemId={props.selectedMenuCategoryItemId}
                    menuCategoryItem={menuCategoryItem}
                />
            // ))
        // :null
    )
    return (
            <Fragment>
                {SpecialModifiers ?
                    SpecialModifiers.length === 0 ?
                        <div className="no_result">
                            Sorry, no results
                        </div>
                    :null
                :null}
                { renderMenuItemsSpecialModifiers(menuCategoryItem,SpecialModifiers)}
                
            </Fragment>     
    );
};

export default MenuItemSpecialModifiers;