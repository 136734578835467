// export function calculateTotalIncludingFee(total,delivery_amount,card_processing_fee) {
//     let _total = parseFloat(parseFloat(total) + parseFloat(delivery_amount) + parseFloat(card_processing_fee)).toFixed(2);
//     return _total;
// }
// export function calculateFinalTotalOfOrder(objOrder,delivery_payment_method,card_processing_fee) {
//     let _total = 0;
//     if(objOrder.delivery_type === 'delivery' && delivery_payment_method === 'credit'){
//         _total = calculateTotalIncludingFee(objOrder.total,objOrder.delivery_amount,card_processing_fee);
//     }else if(objOrder.delivery_type === 'delivery'){
//         _total = parseFloat(parseFloat(objOrder.total) + parseFloat(objOrder.delivery_amount)).toFixed(2);
//     // }else if(objOrder.delivery_type === 'pickup' || objOrder.delivery_type === 'cash' ){
//     }else{
//         _total = objOrder.total;
//     }
//     return parseFloat(_total).toFixed(2);
// }
// export function calculateFinalTotalOfOrderWithOutObjOrder(total,delivery_amount,delivery_type,delivery_payment_method,card_processing_fee) {
//     let _total = 0;
//     if(delivery_type === 'delivery' && delivery_payment_method === 'credit'){
//         _total = calculateTotalIncludingFee(total,delivery_amount,card_processing_fee);
//     }else if(delivery_type === 'delivery'){
//         _total = parseFloat(parseFloat(total) + parseFloat(delivery_amount)).toFixed(2);
//     // }else if(delivery_type === 'pickup'){
//     }else{
//         _total = total;
//     }
//     return parseFloat(_total).toFixed(2);
// }
export function calculateTotalIncludingFee(total,delivery_amount,card_processing_fee,delivery_type,delivery_payment_method) {
    let _total
    if(card_processing_fee === false){
        if(delivery_type === 'delivery'){
            _total = parseFloat(parseFloat(total) + parseFloat(delivery_amount)).toFixed(2);
        }else if(delivery_type === 'pickup'){
            _total = parseFloat(total).toFixed(2);
        }
    }else if(delivery_type === 'delivery'){
        if(delivery_payment_method === 'credit'){
            _total = parseFloat(parseFloat(total) + parseFloat(delivery_amount) + parseFloat(card_processing_fee)).toFixed(2);
        }else if(delivery_payment_method === 'cash'){
            _total = parseFloat(parseFloat(total) + parseFloat(delivery_amount)).toFixed(2);
        }
    }else{
        _total = parseFloat(total).toFixed(2);
    }
    return _total;
}

export function calculateFinalTotalOfOrder(objOrder,delivery_payment_method,card_processing_fee) {
    let _total = 0;
    if(objOrder.delivery_type === 'delivery' && delivery_payment_method === 'credit'){
        _total = calculateTotalIncludingFee(objOrder.total,objOrder.delivery_amount,card_processing_fee,objOrder.delivery_type,delivery_payment_method);
    }else if(objOrder.delivery_type === 'delivery'){
        _total = parseFloat(parseFloat(objOrder.total) + parseFloat(objOrder.delivery_amount)).toFixed(2);
    }else{ 
        _total = objOrder.total;
    }
    return parseFloat(_total).toFixed(2);
}
export function calculateFinalTotalOfOrderWithOutObjOrder(total,delivery_amount,delivery_type,delivery_payment_method,card_processing_fee) {
    let _total = 0;
    if(delivery_type === 'delivery' && delivery_payment_method === 'credit'){
        _total = calculateTotalIncludingFee(total,delivery_amount,card_processing_fee,delivery_type,delivery_payment_method);
    }else if(delivery_type === 'delivery'){
        _total = parseFloat(parseFloat(total) + parseFloat(delivery_amount)).toFixed(2);
    // }else if(delivery_type === 'pickup'){
    }else{
        _total = total;
    }
    return parseFloat(_total).toFixed(2);
}





