import React from 'react';
import ReactDOM from 'react-dom';
// import $ from 'jquery';
import './index.css';
import '../src/assests/css/bootstrap.min.css';
// import '../src/assests/css/owl.carousel.min.css';
// import '../src/assests/css/owl.theme.default.min.css';
import '../src/assests/css/style.css';
import '../src/assests/css/responsive.css';
// import '../src/assests/js/plugins/jquery-3.2.1.slim.min.js';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from './reducers';
import promiseMiddleware from 'redux-promise';
// Development only axios helpers!
import axios from 'axios';
/* const $ = require('jquery');
console.log($)
console.log($)
console.log($)
console.log($)
console.log($) */
/* console.log(window.jQuery)
console.log(window.jQuery)
console.log(window.jQuery)
console.log(window.jQuery)
console.log(window.jQuery)
console.log(window.jQuery)
console.log(window.jQuery) */
/* window.axios = axios;
window.jQuery = $;
window.$ = $;
global.jQuery = $; */


function saveToLocalStorage(state) {
    try {
      const serializedState = JSON.stringify(state)
      localStorage.setItem('state', serializedState)
    } catch(e) {
      console.log(e)
    }
}

function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem('state')
        if (serializedState === null) return undefined
        return JSON.parse(serializedState)
    } catch(e) {
        console.log(e)
        return undefined
    }
}
const persistedState = loadFromLocalStorage();
// console.log('persistedState')
// console.log(persistedState)
let _cart = []
if(persistedState === undefined){
    _cart = [];
}else{
    _cart = persistedState.cart;
}
// console.log('reducers')
// console.log(reducers)
const store = createStore(reducers,
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),     
    {
        auth: { authenticated: localStorage.getItem('token') },
        // cart: _cart,
    }
    , applyMiddleware(promiseMiddleware,reduxThunk));

store.subscribe(() => saveToLocalStorage(store.getState()))

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
// serviceWorker.unregister();
