import React, { Component,Fragment } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchPosOrders,clearPosOrderHistory,fetchPosTodayOrders,deleteOrderFromPosOrdersPage  } from '../../../actions/order_actions';
import LoadingSpinner from '../../utils/LoadingSpinner';
import LoadMorePosOrderItems from './components/LoadMorePosOrderItems';
import { socket } from '../../layout/Header';
class PosOrders extends Component {
    _isMounted = false;
    state = {
        isloadingPosOrders: false,
        limit:8,
        skip:0,
        Orders:[],
    }
    componentWillUnmount(){
        this.props.clearPosOrderHistory();
        this._isMounted = false;
        socket.off("PosOrders-channel:orderDeleteFromPosOrdersPage");
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({isloadingPosOrders:true});
        this.props.fetchPosOrders(
            'pos',
            this.state.skip,
            this.state.limit,
            this.props.order.posOrders
        ).then(response => {
            // console.log('this.props.order');
            // console.log(this.props.order);
            if (this._isMounted) {
                this.setState({isloadingPosOrders:false});
            }
        });
        this.props.fetchPosTodayOrders(
            'pos',
            new Date(),
            1000,
            1000,
            this.props.order.todayPosOrders
        ).then(response => {
        });
        socket.on("kitchen-channel:orderDeleteFromPos",  (order_id) => {
            console.log('order_id in orderDeleteFromPos');
            console.log(order_id);
            // alert('order_id in orderDeleteFromPos')
            
            this.props.deleteOrderFromPosOrdersPage(
                order_id,
            ).then(response => {
                console.log('this.props.order');
                console.log(this.props.order);
            });
        });
        window.posOrder = this;
    }
    onClickHandleLoadMorePosOrderItems = () => {
        console.log('onClickHandleLoadMorePosOrderItems')
        this.setState({isloadingPosOrders:true});
        let skip = this.state.skip + this.state.limit;
        this.props.fetchPosOrders(
                'pos',
                skip,
                this.state.limit,
                this.props.order.posOrders
            ).then(response => {
            console.log('this.props.order');
            console.log(this.props.order);
            this.setState({skip,isloadingPosOrders:false});
        });
    }
    handelOnClickPosOrderPrint = (Order) =>{
        console.log('Order')
        console.log(Order)
        window.open(`print-pos-order/${Order._id}`, '_blank');
    }
    
    render() {
        const order = this.props.order
        if (Object.keys(order).length === 0 || order.posOrders === undefined) {
            return (
                <LoadingSpinner subClass={'mt10 mb10 text-center'} />
            )
        }
        // console.log('order.posOrders')
        // console.log(order.posOrders)
        return (
            <Fragment>
                <div className="container-fluid PosOrdersPage">
                    {/* <div className="row "> */}
                        {
                            order.posOrders !== 'undefined' && order.posOrders !== undefined ?
                                <LoadMorePosOrderItems
                                    limit={this.state.limit}
                                    isloadingPosOrders={this.state.isloadingPosOrders}
                                    total_pos_orders={order.total_pos_orders}
                                    Orders={order.posOrders}
                                    onClickHandleLoadMorePosOrderItems={this.onClickHandleLoadMorePosOrderItems}
                                    handelOnClickPosOrderPrint={this.handelOnClickPosOrderPrint}
                                />
                            :null
                        }
                       
                        
                        
                    {/* </div> */}
                    
                </div>
            </Fragment>
            
        
        )
    }
}

function mapStateToProps(state) {
    // console.log('state')
    // console.log(state)
    return {
        order : state.order,
    };
}

// export default Home
export default connect(mapStateToProps, {fetchPosOrders,clearPosOrderHistory,fetchPosTodayOrders,deleteOrderFromPosOrdersPage})(withRouter(PosOrders));
