import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import IsNewOrExistingTabletOrderNotificationSelectionModalBody from './IsNewOrExistingTabletOrderNotificationSelectionModalBody';
class IsNewOrExistingTabletOrderNotificationSelectionModal extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.props.handleOnChangeNewOrExistingTabletOrderNotificationSelectionModal(!this.props.showIsNewExistingTabletOrderNotificationSelectionModal)
    }
    
    render() {
        /* console.log('this.state.modal in IsNewOrExistingTabletOrderNotificationSelectionModal')
        console.log(this.state.modal)
        console.log('this.props.showIsNewExistingTabletOrderNotificationSelectionModal')
        console.log(this.props.showIsNewExistingTabletOrderNotificationSelectionModal) */
        // let ModalheaderTxt = "Select Kiosk Orders Type";
        
        return (
                <Modal isOpen={this.props.showIsNewExistingTabletOrderNotificationSelectionModal} toggle={this.toggle} className={this.props.className} >
                    {/* <ModalHeader toggle={this.toggle}>
                        {ModalheaderTxt}
                    </ModalHeader> */}
                    <ModalBody>
                        <IsNewOrExistingTabletOrderNotificationSelectionModalBody 
                            handleOnClickTabletOrderNotificationTypeSelection={this.props.handleOnClickTabletOrderNotificationTypeSelection}
                        />
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.toggle}>Done</Button>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter> */}
                </Modal>
        );
    }
};

export default IsNewOrExistingTabletOrderNotificationSelectionModal;