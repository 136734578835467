import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import WebOrdersNotificationModalBody from './WebOrdersNotificationModalBody';
class WebOrdersNotificationModal extends React.Component {
    constructor(props) {
        super(props);
        /* this.state = {
            modal: props.showWebOrdersNotificationModal
        }; */
        this.toggle = this.toggle.bind(this);
    }

    componentWillUnmount(){
        // console.log('componet unmount')
        // this.props.clearExistingOrderHistory();
        // this.props.clearWebExistingOrderLimits();
    }

    toggle() {
        
        this.props.handleOnChangeWebOrdersNotificationModalToggle(!this.props.showWebOrdersNotificationModal);
        this.props.clearWebExistingOrderHistory();
        this.props.clearWebExistingOrderLimits();
        
    }
    
    render() {
        return (
                <Modal isOpen={this.props.showWebOrdersNotificationModal} toggle={this.toggle} className={this.props.className} size="lg">
                    <ModalHeader toggle={this.toggle}>Notification Orders</ModalHeader>
                    <ModalBody>
                        <WebOrdersNotificationModalBody 
                            order={this.props.order}
                            onClickHandleLoadMoreWebExistingOrderItems={this.props.onClickHandleLoadMoreWebExistingOrderItems}
                            limitWebExstingOrders={this.props.limitWebExstingOrders}
                            isloadingWebExistingOrders={this.props.isloadingWebExistingOrders}
                            handelOnClickWebExistingOrder={this.props.handelOnClickWebExistingOrder}
                        />
                    </ModalBody>
                </Modal>
        );
    }
};

export default WebOrdersNotificationModal;