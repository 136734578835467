import React, { Component } from 'react';
import { Map, GoogleApiWrapper, InfoWindow, Marker  } from 'google-maps-react';
const mapStyles = {
  width: '100%',
  height: '100%',
};
export class MapContainer extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      showingInfoWindow: false,  //Hides or the shows the infoWindow
      activeMarker: {},          //Shows the active marker upon click
      selectedPlace: {}          //Shows the infoWindow to the selected place upon a marker
    };
    // this.state = {
    //   stores: [{lat: 47.49855629475769, lng: -122.14184416996333},
    //           {latitude: 47.359423, longitude: -122.021071},
    //           {latitude: 47.2052192687988, longitude: -121.988426208496},
    //           {latitude: 47.6307081, longitude: -122.1434325},
    //           {latitude: 47.3084488, longitude: -122.2140121},
    //           {latitude: 47.5524695, longitude: -122.0425407}]
    // }
  }
  onMarkerClick = (props, marker, e) =>{
    console.log('markerin onMarkerClick')
    console.log('markerin onMarkerClick')
    console.log(marker)
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  }
  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  displayMarkers = () => {
    return this.props.stores.map((store, index) => {
      return <Marker key={index} id={index} position={{
          lat: store.latitude,
          lng: store.longitude
        }}
        name={`${store.customer_name} ${store.address}`}
        customer_name={store.customer_name}
        address={store.address}
        onClick={this.onMarkerClick} 
        markerType='order'
        // onClick={() => console.log("You clicked me!")} 
      />
    })
    // return this.state.stores.map((store, index) => {
    //   return <Marker key={index} id={index} position={{
    //     lat: store.latitude,
    //     lng: store.longitude
    //   }}
    //   onClick={() => console.log("You clicked me!")} />
    // })
  }
  displayRestaurantMarker = (objRestaurantAddress) => {
    const google = this.props.google;
    return <Marker  position={{
          lat: objRestaurantAddress.restaurant_address_lat,
          lng: objRestaurantAddress.restaurant_address_lng
        }}
        name={'Sofos'}
        customer_name={'Sofos'}
        address={objRestaurantAddress.restaurant_address}
        onClick={this.onMarkerClick}
        markerType='restaurant' 
        icon={{
          url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
          // anchor: new google.maps.Point(32,32),
          scaledSize: new google.maps.Size(40,40)
        }}
        // onClick={() => console.log("You clicked me!")} 
    />
  }
  render() {
    const {objRestaurantAddress} = this.props;
    console.log('objRestaurantAddress in render')
    console.log('objRestaurantAddress in render')
    console.log(objRestaurantAddress)
    return (
        <Map
          google={this.props.google}
          zoom={11}
          style={mapStyles}
          initialCenter={{ lat: objRestaurantAddress.restaurant_address_lat, lng: objRestaurantAddress.restaurant_address_lng}}
          // initialCenter={{ lat: 47.444, lng: -122.176}}
        >
          {this.displayMarkers()}
          {this.displayRestaurantMarker(objRestaurantAddress)}
          <InfoWindow
            marker={this.state.activeMarker}
            onClose={this.onInfoWindowClose}
            visible={this.state.showingInfoWindow}>
            <div>
              {
                this.state.activeMarker.markerType === 'order' ?
                  <h3>Customer Name : {this.state.activeMarker.customer_name}</h3>
                :
                  <h3>Restaurant Name : {this.state.activeMarker.customer_name}</h3>
              }
              <h3>Address : {this.state.activeMarker.address}</h3>
              {/* <h1>{this.state.selectedPlace.name}</h1> */}
            </div>
          </InfoWindow>
        </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: (process.env.REACT_APP_GOOGLE_MAPS_API_KEY)
})(MapContainer)