import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { loginUser } from '../../../actions/user_actions';
import axios from 'axios';
import { checkInternetConnection } from '../../utils/general';

class Login extends React.Component {
    _isMounted = false;
    state = {
        isloading: false,
        loginError: false,
    }
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    onSubmitForm = async (fields) => {
        console.log('fields in submit')
        console.log(fields)
        if (this._isMounted) {
            this.setState({
                loginError: false,
                isloading:true,
            })
        }
        // alert('SUCCESS!! :-)\n\n' + JSON.stringify(fields, null, 4))
        let isConnected = await checkInternetConnection();
        if(isConnected){
            this.props.dispatch(loginUser(fields)).then(response =>{
                if(response.payload.loginSuccess){
                    // console.log(response.payload);
                    console.log('before push')
                    axios.defaults.headers.common['Authorization'] = response.payload.token;
                    localStorage.setItem('token', response.payload.token)
                    console.log(localStorage.getItem('token'))
                    console.log(localStorage.getItem('token'))
                    console.log(localStorage.getItem('token'))
                    console.log(localStorage.getItem('token'))
                    console.log(localStorage.getItem('token'))
                    console.log(localStorage.getItem('token'))
                    console.log('after push')
                    // we will have to reload the page here instead of push the page because
                    // for the current page their is no token inside axios
                    // when we will refresh the page then axios will be reinialized and it will
                    // add the new token inside its headers when sending ajax requests
                    this.props.history.push('/')
                }else{
                    if (this._isMounted) {
                        this.setState({
                            loginError: true,
                            isloading:false,
                        })
                    }
                }
            });
        }else{
            alert('Please connect to interent for login')
        }
    }
    render() {
        return (
            <div className="jumbotron">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <h2 className="text-center">Jubzi POS Login</h2>
                            <Formik
                                initialValues={{
                                    firstName: '',
                                    lastName: '',
                                    email: '',
                                    password: '',
                                    confirmPassword: ''
                                }}
                                validationSchema={Yup.object().shape({
                                    /* firstName: Yup.string()
                                        .required('First Name is required'),
                                    lastName: Yup.string()
                                        .required('Last Name is required'), */
                                    email: Yup.string()
                                        .email('Email is invalid')
                                        .required('Email is required'),
                                    password: Yup.string()
                                        .min(6, 'Password must be at least 6 characters')
                                        .required('Password is required'),
                                    // confirmPassword:  Yup.string()
                                    //     .oneOf([Yup.ref('password'), null], 'Passwords must match')
                                    //     .required('Confirm Password is required')
                                })}
                                onSubmit={this.onSubmitForm}
                                /* onSubmit={fields => {
                                    console.log('fields in submit')
                                    console.log(fields)
                                    this.setState({
                                        loginError: false,
                                        isloading:true,
                                    })
                                    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(fields, null, 4))
                                    this.props.dispatch(loginUser(fields)).then(response =>{
                                        if(response.payload.loginSuccess){
                                            // console.log(response.payload);
                                            this.props.history.push('/')
                                        }else{
                                            this.setState({
                                                loginError: true,
                                                isloading:false,
                                            })
                                        }
                                    });
                                }} */
                                render={({ errors, status, touched }) => (
                                    <Form>
                                        {/* <div className="form-group">
                                            <label htmlFor="firstName">First Name</label>
                                            <Field name="firstName" type="text" className={'form-control' + (errors.firstName && touched.firstName ? ' is-invalid' : '')} />
                                            <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="lastName">Last Name</label>
                                            <Field name="lastName" type="text" className={'form-control' + (errors.lastName && touched.lastName ? ' is-invalid' : '')} />
                                            <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                                        </div> */}
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password">Password</label>
                                            <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                            <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                        </div>
                                        {/* <div className="form-group">
                                            <label htmlFor="confirmPassword">Confirm Password</label>
                                            <Field name="confirmPassword" type="password" className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                                            <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                                        </div> */}
                                        { this.state.loginError ?
                                            <div className="alert alert-danger" role="alert">
                                                Please provide valid login credentials
                                            </div>
                                        :null}
                                        <div className="form-group">
                                            {/* <button type="submit" className="btn btn-primary mr-2">Register</button> */}
                                            {/* <button type="reset" className="btn btn-secondary">Reset</button> */}
                                            <button type="submit" className="btn btn-secondary">Login</button>
                                        </div>
                                    </Form>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

// export { App }; 
export default connect()(withRouter(Login));