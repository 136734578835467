import axios from 'axios';
import { FETCH_MENU_CATEGORIES,GET_MENU_ITEMS_BY_CATEGORY_ID,CLEAR_MENU_CATEGORY_MENU_ITEMS } from './types';
import { BASE_URL } from '../components/utils/misc';
import { checkInternetConnection } from '../components/utils/general';
import localForage from 'localforage';
import _ from 'lodash';
export const fetchMenuCatgories = () => async dispatch => {
    let isConnected = await checkInternetConnection();
    const categories = await localForage.getItem('fetch-menu-categories');
    // if the net is on and categores does not exist in localstorage then fetch it from server
    if(isConnected && categories === null){
        const res = await axios.get(`${BASE_URL}/api/fetch-menu-categories`);
        localForage.setItem('fetch-menu-categories', res.data)
        // await localForage.setItem('fetch-menu-categories', res.data)
        dispatch({ type: FETCH_MENU_CATEGORIES, payload: res.data });
    }else{
        // const categories = await localForage.getItem('fetch-menu-categories');
        // console.log('i a in essss')
        dispatch({ type: FETCH_MENU_CATEGORIES, payload:categories });
    }  
    // if not connection then fetch from localStorage     
};


/* export const getMenuItemsByCategoryId = (menuCategoryId) => async dispatch => {
    let isConnected = await checkInternetConnection();
    // console.log('isConnected')
    // console.log(isConnected)
    const Categories = await localForage.getItem('fetch-menu-categories');
    const objMenuItems = await localForage.getItem('fetch-menu-items');
    // console.log('Categories')
    // console.log(Categories)
    // console.log('objMenuItems')
    // console.log(objMenuItems)
    if(isConnected && Categories === null && objMenuItems === null){
        const response = await axios.get(`${BASE_URL}/api/get-menu-items-by-category-id-sorted?menucategoryid=${menuCategoryId}`);
        let res = {
            total_menu_items: response.data.total_menu_items,
            MenuItems: response.data.MenuItems
        }
        dispatch({ type: GET_MENU_ITEMS_BY_CATEGORY_ID, payload: res });
    }else{
        // const Categories = await localForage.getItem('fetch-menu-categories');
        // const objMenuItems = await localForage.getItem('fetch-menu-items');
        let objMenuCategory = Categories.MenuCategories.find(c => c._id === menuCategoryId);
        // console.log('objMenuCategory')
        // console.log(objMenuCategory)
        let MenuItems = objMenuItems.MenuItems;
        MenuItems = MenuItems.filter(c => c.menuCatgory === menuCategoryId);
        for (let index = 0; index < MenuItems.length; index++) {
            const element = MenuItems[index];
			let position = objMenuCategory.menu_items_order.findIndex(id => id == element._id);
			// console.log('position')
			// console.log(position)
			MenuItems[index].position = position;
		}
        MenuItems = _.sortBy(MenuItems, ['position']);
        let res = {
            total_menu_items: MenuItems.length,
            MenuItems: MenuItems
        }
        dispatch({ type: GET_MENU_ITEMS_BY_CATEGORY_ID, payload: res });
    }
}; */
export const getMenuItemsByCategoryId = (menuCategoryId) => async dispatch => {
    // let isConnected = await checkInternetConnection();
    // console.log('isConnected')
    // console.log(isConnected)
    const Categories = await localForage.getItem('fetch-menu-categories');
    const objMenuItems = await localForage.getItem('fetch-menu-items');
    // console.log('Categories')
    // console.log(Categories)
    // console.log('objMenuItems')
    // console.log(objMenuItems)
    if(Categories === null && objMenuItems === null){
    // if(isConnected && Categories === null && objMenuItems === null){
        const response = await axios.get(`${BASE_URL}/api/get-menu-items-by-category-id-sorted?menucategoryid=${menuCategoryId}`);
        let res = {
            total_menu_items: response.data.total_menu_items,
            MenuItems: response.data.MenuItems
        }
        dispatch({ type: GET_MENU_ITEMS_BY_CATEGORY_ID, payload: res });
    }else{
        // const Categories = await localForage.getItem('fetch-menu-categories');
        // const objMenuItems = await localForage.getItem('fetch-menu-items');
        let objMenuCategory = Categories.MenuCategories.find(c => c._id === menuCategoryId);
        // console.log('objMenuCategory')
        // console.log(objMenuCategory)
        let MenuItems = objMenuItems.MenuItems;
        MenuItems = MenuItems.filter(c => c.menuCatgory === menuCategoryId);
        for (let index = 0; index < MenuItems.length; index++) {
            const element = MenuItems[index];
			let position = objMenuCategory.menu_items_order.findIndex(id => id === element._id);
			// console.log('position')
			// console.log(position)
			MenuItems[index].position = position;
		}
        MenuItems = _.sortBy(MenuItems, ['position']);
        let res = {
            total_menu_items: MenuItems.length,
            MenuItems: MenuItems
        }
        dispatch({ type: GET_MENU_ITEMS_BY_CATEGORY_ID, payload: res });
    }
};


export const clearMenuCategoryMenuItems = () => async dispatch => {
    dispatch({ type: CLEAR_MENU_CATEGORY_MENU_ITEMS, payload: [] });
};
