import React,{Fragment} from 'react';
import LoadingSpinner from '../../../../../utils/LoadingSpinner';
import LoadMoreExistingOrderItems from './LoadMoreExistingOrderItems';
const ExistingOrdersModalBody = (props)  => {
    const order = props.order;
    /* console.log('order.total_existing_orders')
    console.log('order.total_existing_orders')
    console.log('order.total_existing_orders')
    console.log(order.total_existing_orders) */
    if (Object.keys(order).length === 0 || order.existingOrders === undefined) {
        return (
            <LoadingSpinner subClass={'mt10 mb10 text-center'} />
        )
    }else{

    }
    return (
        <Fragment>
            <div className="row">
                <div className="table-responsive">   
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Delivery Type</th>
                                <th>Name</th>
                                <th>Time</th>
                                <th>Total Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                order.existingOrders !== 'undefined' && order.existingOrders !== undefined ?
                                    <LoadMoreExistingOrderItems
                                        limit={props.limitExstingOrders}
                                        total_existing_orders={order.total_existing_orders}
                                        Orders={order.existingOrders}
                                        onClickHandleLoadMoreExistingOrderItems={props.onClickHandleLoadMoreExistingOrderItems}
                                        handelOnClickExistingOrder={props.handelOnClickExistingOrder}
                                    />
                                :null
                            }
                        </tbody>
                    </table>

                    {
                        props.isloadingExistingOrders ? <LoadingSpinner subClass={'mt10 mb10 text-center'} /> :null
                    }
                </div>
            </div>
        </Fragment>
    );
}

export default ExistingOrdersModalBody;