import React,{Fragment} from 'react';
import SauceIngredientItem from './SauceIngredientItem'
const SauceIngredients = (props) => {
    const menuItemWithIngredient = props.menuItemWithIngredient;
    // console.log('menuItemWithIngredient')
    // console.log(menuItemWithIngredient)
    const renderMenuItemWithIngredient = (Ingredients) => (
        Ingredients ? 
            Ingredients.slice(0,props.limitSauceIngredients).map((card,index)=>{
                if(card.ingredient_main_type === "sauces"){
                    return  <SauceIngredientItem 
                                    key={card._id}
                                    addIngredientToMenuItemCart={props.addIngredientToMenuItemCart}
                                    {...card}
                                    // handleOnClickMenuCategoryItem={props.handleOnClickMenuCategoryItem}
                                    // selectedMenuCategoryItemId={props.selectedMenuCategoryItemId}
                                />; 
                }
            })
        :null
    )
    return (
                <Fragment>
                    {menuItemWithIngredient ?
                        menuItemWithIngredient.Ingredients.length === 0 ?
                            <div className="no_result">
                                Sorry, no results
                            </div>
                        :null
                    :null}

                    { renderMenuItemWithIngredient(menuItemWithIngredient.Ingredients.filter(ingredient => ingredient.ingredient_main_type === "sauces"))}
                    {/* { renderMenuItemWithIngredient(menuItemWithIngredient.Ingredients)} */}
                    
                </Fragment>
    );
};

export default SauceIngredients;