import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import IsNewOrExistingMistakeSelectionModalBody from './IsNewOrExistingMistakeSelectionModalBody';
class IsNewOrExistingMistakeSelectionModal extends React.Component {
    constructor(props) {
        super(props);
        /* this.state = {
            modal: props.showIsNewExistingMistakeSelectionModal
        }; */
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        /* this.setState({
            modal: !this.props.showIsNewExistingMistakeSelectionModal
            // modal: !this.state.modal
        }); */
        this.props.handleOnChangeNewOrExistingMistakeModalToggle(!this.props.showIsNewExistingMistakeSelectionModal)
        /* this.setState(prevState => ({
            modal: !prevState.modal
        })); */
    }
    
    render() {
        /* console.log('this.state.modal in IsNewOrExistingMistakeSelectionModal')
        console.log(this.state.modal)
        console.log('this.props.showIsNewExistingMistakeSelectionModal')
        console.log(this.props.showIsNewExistingMistakeSelectionModal) */
        
        return (
                <Modal isOpen={this.props.showIsNewExistingMistakeSelectionModal} toggle={this.toggle} className={this.props.className} >
                    <ModalHeader toggle={this.toggle}>Select Mistake Type</ModalHeader>
                    <ModalBody>
                        <IsNewOrExistingMistakeSelectionModalBody 
                            handleOnClickMistakeTypeSelection={this.props.handleOnClickMistakeTypeSelection}
                        />
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.toggle}>Done</Button>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter> */}
                </Modal>
        );
    }
};

export default IsNewOrExistingMistakeSelectionModal;