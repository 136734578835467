import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logoutUser } from '../../../actions/user_actions';
import { showMsg,checkInternetConnection } from '../../utils/general';
import axios from 'axios';
class Logout extends React.Component {
    _isMounted = false;
    state = {
        isloading: false,
        loginError: false,
    }
    async  componentDidMount() {
        this._isMounted = true;
        console.log('logout')
        console.log('logout')
        console.log('logout')
        console.log('logout')
        let isConnected = await checkInternetConnection();
        if(isConnected){
            this.props.logoutUser().then(response => {
                console.log('this.props.user after logout')
                console.log('this.props.user after logout')
                console.log('this.props.user after logout')
                console.log('this.props.user after logout')
                console.log(this.props.user)
                if(this.props.user.logOutSuccess && this.props.user.logOutSuccess.success){
                    localStorage.removeItem('token')
                    showMsg("You have been successfully loged out.",'success');
                    this.props.history.push('/login')
                }else{
                    showMsg("Opps Something went wrong please try again.",'error');
                }
            });
        }else{
            localStorage.removeItem('token')
            axios.defaults.headers.common['Authorization'] = null;
            showMsg("You have been successfully loged out.",'success');
            this.props.history.push('/login')
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    
    render() {
        return (
            <div>
              <h1>Logging you out...</h1>  
            </div>
        )
    }
}
function mapStateToProps(state){
    return {
        user: state.user,
        // is_resturant_open:state.is_resturant_open
    }
}
// export { App }; 
export default connect(mapStateToProps,{logoutUser})(withRouter(Logout));