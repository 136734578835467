import React from 'react';
// import trashRedPng from '../../../../../common/images/icons/trash-red.png';
import {notesCommon} from '../../../../../utils/general'
const RenderComboItemIngredients = (mainProduct,product,ingredients,removeIngredientFromCartComboItem,selectedOnReceiptMenuItemId,selectedOnReceiptMenuItemTimeStamp,selectedOnReceiptItemType,selectedOnReceiptMenuItemComboItemId,selectedOnReceiptMenuItemComboItemIngredientId,selectedOnReceiptMenuItemComboItemIngredient,handleOnClickSelectReceiptComboItemIngredient,confirmAlert)  => {
    // console.log('product')
    // console.log(product.name)
    // console.log('ingredients')
    // console.log(ingredients)
    // console.log(ingredients.length) 
    // ingredients = ingredients.filter(i => i.is_checked === true);
    // console.log('ingredients after')
    // console.log(ingredients)
    const handleOnClickComboItemIngredient = (mainProduct,product,ingredient,type) =>{
        handleOnClickSelectReceiptComboItemIngredient(mainProduct,product,ingredient,type);
        /* let buttons = [];
        const objButton = {
            label: 'SELECT ITEM',
            onClick: () => {
                handleOnClickSelectReceiptComboItemIngredient(mainProduct,product,ingredient);
            }
        }
        buttons.push(objButton);
        
        const objDeleteButton =    {
                label: 'DELETE ITEM',
                onClick: () => {
                    removeIngredientFromCartComboItem(mainProduct,product,ingredient)
                }
            }
        buttons.push(objDeleteButton);
        confirmAlert({
            // title: 'Confirm to submit',
            // message: 'Are you sure to do this.',
            buttons: buttons
        }); */
    }
    if(ingredients.length > 0){
        // let rowLen =ingredients.length;
        // return images[0].url
        let ret = ingredients.map((ingredient,index) => {
            const trashButton = null;
            // const trashButton = <img src={trashRedPng} alt="trash" className="mw-100 cursor-pointer trash-icon"  onClick={()=> removeIngredientFromCartComboItem(mainProduct,product,ingredient)}  />;
            // console.log('ingredient.name')
            // console.log(ingredient.name)
            // console.log('ingredient.ingredient_category_type_blue')
            // console.log(ingredient.ingredient_category_type_blue)
            // console.log('ingredient.ingredientType')
            // console.log(ingredient.ingredientType)
            /* if (owned_game.game_id === game.game_id) {
              return renderOwned
            } else {
              return renderPrice
            } */
            /* if (rowLen === index + 1) {
                // last one
                return <li key={index}> {ingredient.name} </li>;
            } else { */
                // not last one
                if(ingredient.added_from === 'web'){
                    if(ingredient.ingredientType === "extras_charged" && ingredient.is_checked === true){
                        // coding for the web ingredinats listing
                        return <div className="ingredient position-relative" key={index}> <div className={`combo-item-ingredient ${(selectedOnReceiptMenuItemId === mainProduct.menu_item_id && selectedOnReceiptMenuItemTimeStamp === mainProduct.timestamp && selectedOnReceiptMenuItemComboItemId === product.selectedComboItemId && selectedOnReceiptMenuItemComboItemIngredientId === ingredient._id  && selectedOnReceiptItemType === 'combo_item_ingredient') ? "selected" : ""}  `} onClick = {() => handleOnClickComboItemIngredient(mainProduct,product,ingredient,'combo_item_ingredient')}> Add {ingredient.name} </div> <div className="position-absolute combo-item-ingredient-price">${parseFloat(ingredient.price).toFixed(2)} {trashButton}</div>  </div>;
                    }else if(ingredient.ingredientType === "included" && ingredient.is_checked === false){
                        // coding for the web ingredinats listing
                        return <div className="ingredient position-relative" key={index}> <div className={`combo-item-ingredient ${(selectedOnReceiptMenuItemId === mainProduct.menu_item_id && selectedOnReceiptMenuItemTimeStamp === mainProduct.timestamp && selectedOnReceiptMenuItemComboItemId === product.selectedComboItemId && selectedOnReceiptMenuItemComboItemIngredientId === ingredient._id  && selectedOnReceiptItemType === 'combo_item_ingredient') ? "selected" : ""}  `} onClick = {() => handleOnClickComboItemIngredient(mainProduct,product,ingredient,'combo_item_ingredient')}> No {ingredient.name} </div> <div className="position-absolute combo-item-ingredient-price">{trashButton}</div>  </div>;
                    }else if(ingredient.ingredientType === "both" && ingredient.is_checked === false){
                        // coding for the web ingredinats listing
                        return <div className="ingredient position-relative" key={index}> <div className={`combo-item-ingredient ${(selectedOnReceiptMenuItemId === mainProduct.menu_item_id && selectedOnReceiptMenuItemTimeStamp === mainProduct.timestamp && selectedOnReceiptMenuItemComboItemId === product.selectedComboItemId && selectedOnReceiptMenuItemComboItemIngredientId === ingredient._id  && selectedOnReceiptItemType === 'combo_item_ingredient') ? "selected" : ""}  `} onClick = {() => handleOnClickComboItemIngredient(mainProduct,product,ingredient,'combo_item_ingredient')}> No {ingredient.name} </div> <div className="position-absolute combo-item-ingredient-price">{trashButton}</div>  </div>;
                    }else if(ingredient.ingredientType === "optional_free" && ingredient.is_checked === true){
                        // coding for the web ingredinats listing
                        return <div className="ingredient position-relative" key={index}> <div className={`combo-item-ingredient ${(selectedOnReceiptMenuItemId === mainProduct.menu_item_id && selectedOnReceiptMenuItemTimeStamp === mainProduct.timestamp && selectedOnReceiptMenuItemComboItemId === product.selectedComboItemId && selectedOnReceiptMenuItemComboItemIngredientId === ingredient._id  && selectedOnReceiptItemType === 'combo_item_ingredient') ? "selected" : ""}  `} onClick = {() => handleOnClickComboItemIngredient(mainProduct,product,ingredient,'combo_item_ingredient')}> Add {ingredient.name} </div> <div className="position-absolute combo-item-ingredient-price">{trashButton}</div>  </div>;
                    }
                }else{
                    if(ingredient.ingredient_category_type_blue === "no" && ingredient.is_checked === true){
                        if(ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged'){
                            return <div className="ingredient position-relative" key={index}> <div className={`combo-item-ingredient ${(selectedOnReceiptMenuItemId === mainProduct.menu_item_id && selectedOnReceiptMenuItemTimeStamp === mainProduct.timestamp && selectedOnReceiptMenuItemComboItemId === product.selectedComboItemId && selectedOnReceiptMenuItemComboItemIngredientId === ingredient._id && selectedOnReceiptMenuItemComboItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemComboItemIngredient.combo_item_ingredient_timestamp === ingredient.combo_item_ingredient_timestamp && selectedOnReceiptItemType === 'combo_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickComboItemIngredient(mainProduct,product,ingredient,'combo_item_ingredient')}> No {ingredient.name} </div> <div className="position-absolute combo-item-ingredient-price">${parseFloat(ingredient.price).toFixed(2)} {trashButton}</div> </div>;
                        }else{
                            return <div className="ingredient position-relative" key={index}> <div className={`combo-item-ingredient ${(selectedOnReceiptMenuItemId === mainProduct.menu_item_id && selectedOnReceiptMenuItemTimeStamp === mainProduct.timestamp && selectedOnReceiptMenuItemComboItemId === product.selectedComboItemId && selectedOnReceiptMenuItemComboItemIngredientId === ingredient._id && selectedOnReceiptMenuItemComboItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemComboItemIngredient.combo_item_ingredient_timestamp === ingredient.combo_item_ingredient_timestamp && selectedOnReceiptItemType === 'combo_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickComboItemIngredient(mainProduct,product,ingredient,'combo_item_ingredient')}> No {ingredient.name} </div> <div className="position-absolute combo-item-ingredient-price">{trashButton}</div>  </div>;
                        }
                    }else if(ingredient.ingredient_category_type_blue === "extra" && ingredient.is_checked === true){
                        if(ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged'){
                            return <div className="ingredient position-relative" key={index}> <div className={`combo-item-ingredient ${(selectedOnReceiptMenuItemId === mainProduct.menu_item_id && selectedOnReceiptMenuItemTimeStamp === mainProduct.timestamp && selectedOnReceiptMenuItemComboItemId === product.selectedComboItemId && selectedOnReceiptMenuItemComboItemIngredientId === ingredient._id && selectedOnReceiptMenuItemComboItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemComboItemIngredient.combo_item_ingredient_timestamp === ingredient.combo_item_ingredient_timestamp && selectedOnReceiptItemType === 'combo_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickComboItemIngredient(mainProduct,product,ingredient,'combo_item_ingredient')}> Extra {ingredient.name} </div> <div className="position-absolute combo-item-ingredient-price">${parseFloat(ingredient.price).toFixed(2)} {trashButton}</div> </div>;
                        }else{
                            return <div className="ingredient position-relative" key={index}> <div className={`combo-item-ingredient ${(selectedOnReceiptMenuItemId === mainProduct.menu_item_id && selectedOnReceiptMenuItemTimeStamp === mainProduct.timestamp && selectedOnReceiptMenuItemComboItemId === product.selectedComboItemId && selectedOnReceiptMenuItemComboItemIngredientId === ingredient._id && selectedOnReceiptMenuItemComboItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemComboItemIngredient.combo_item_ingredient_timestamp === ingredient.combo_item_ingredient_timestamp && selectedOnReceiptItemType === 'combo_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickComboItemIngredient(mainProduct,product,ingredient,'combo_item_ingredient')}> Extra {ingredient.name} </div> <div className="position-absolute combo-item-ingredient-price">{trashButton}</div>  </div>;
                        }
                    }else if(ingredient.ingredient_category_type_blue === "add" && ingredient.is_checked === true){
                        if(ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged'){
                            return <div className="ingredient position-relative" key={index}> <div className={`combo-item-ingredient ${(selectedOnReceiptMenuItemId === mainProduct.menu_item_id && selectedOnReceiptMenuItemTimeStamp === mainProduct.timestamp && selectedOnReceiptMenuItemComboItemId === product.selectedComboItemId && selectedOnReceiptMenuItemComboItemIngredientId === ingredient._id && selectedOnReceiptMenuItemComboItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemComboItemIngredient.combo_item_ingredient_timestamp === ingredient.combo_item_ingredient_timestamp && selectedOnReceiptItemType === 'combo_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickComboItemIngredient(mainProduct,product,ingredient,'combo_item_ingredient')}> Add {ingredient.name} </div> <div className="position-absolute combo-item-ingredient-price">${parseFloat(ingredient.price).toFixed(2)} {trashButton}</div> </div>;
                        }else{
                            return <div className="ingredient position-relative" key={index}> <div className={`combo-item-ingredient ${(selectedOnReceiptMenuItemId === mainProduct.menu_item_id && selectedOnReceiptMenuItemTimeStamp === mainProduct.timestamp && selectedOnReceiptMenuItemComboItemId === product.selectedComboItemId && selectedOnReceiptMenuItemComboItemIngredientId === ingredient._id && selectedOnReceiptMenuItemComboItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemComboItemIngredient.combo_item_ingredient_timestamp === ingredient.combo_item_ingredient_timestamp && selectedOnReceiptItemType === 'combo_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickComboItemIngredient(mainProduct,product,ingredient,'combo_item_ingredient')}> Add {ingredient.name} </div> <div className="position-absolute combo-item-ingredient-price">{trashButton}</div>  </div>;
                        }
                    }else if(ingredient.ingredient_category_type_blue === "light" && ingredient.is_checked === true){
                        if(ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged'){
                            return <div className="ingredient position-relative" key={index}> <div className={`combo-item-ingredient ${(selectedOnReceiptMenuItemId === mainProduct.menu_item_id && selectedOnReceiptMenuItemTimeStamp === mainProduct.timestamp && selectedOnReceiptMenuItemComboItemId === product.selectedComboItemId && selectedOnReceiptMenuItemComboItemIngredientId === ingredient._id && selectedOnReceiptMenuItemComboItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemComboItemIngredient.combo_item_ingredient_timestamp === ingredient.combo_item_ingredient_timestamp && selectedOnReceiptItemType === 'combo_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickComboItemIngredient(mainProduct,product,ingredient,'combo_item_ingredient')}> Light {ingredient.name} </div> <div className="position-absolute combo-item-ingredient-price">${parseFloat(ingredient.price).toFixed(2)} {trashButton}</div> </div>;
                        }else{
                            return <div className="ingredient position-relative" key={index}> <div className={`combo-item-ingredient ${(selectedOnReceiptMenuItemId === mainProduct.menu_item_id && selectedOnReceiptMenuItemTimeStamp === mainProduct.timestamp && selectedOnReceiptMenuItemComboItemId === product.selectedComboItemId && selectedOnReceiptMenuItemComboItemIngredientId === ingredient._id && selectedOnReceiptMenuItemComboItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemComboItemIngredient.combo_item_ingredient_timestamp === ingredient.combo_item_ingredient_timestamp && selectedOnReceiptItemType === 'combo_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickComboItemIngredient(mainProduct,product,ingredient,'combo_item_ingredient')}> Light {ingredient.name} </div> <div className="position-absolute combo-item-ingredient-price">{trashButton}</div>  </div>;
                        }
                    }else if(ingredient.ingredient_category_type_blue === "only" && ingredient.is_checked === true){
                        if(ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged'){
                            return <div className="ingredient position-relative" key={index}> <div className={`combo-item-ingredient ${(selectedOnReceiptMenuItemId === mainProduct.menu_item_id && selectedOnReceiptMenuItemTimeStamp === mainProduct.timestamp && selectedOnReceiptMenuItemComboItemId === product.selectedComboItemId && selectedOnReceiptMenuItemComboItemIngredientId === ingredient._id && selectedOnReceiptMenuItemComboItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemComboItemIngredient.combo_item_ingredient_timestamp === ingredient.combo_item_ingredient_timestamp && selectedOnReceiptItemType === 'combo_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickComboItemIngredient(mainProduct,product,ingredient,'combo_item_ingredient')}> Only {ingredient.name} </div> <div className="position-absolute combo-item-ingredient-price">${parseFloat(ingredient.price).toFixed(2)} {trashButton}</div> </div>;
                        }else{
                            return <div className="ingredient position-relative" key={index}> <div className={`combo-item-ingredient ${(selectedOnReceiptMenuItemId === mainProduct.menu_item_id && selectedOnReceiptMenuItemTimeStamp === mainProduct.timestamp && selectedOnReceiptMenuItemComboItemId === product.selectedComboItemId && selectedOnReceiptMenuItemComboItemIngredientId === ingredient._id && selectedOnReceiptMenuItemComboItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemComboItemIngredient.combo_item_ingredient_timestamp === ingredient.combo_item_ingredient_timestamp && selectedOnReceiptItemType === 'combo_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickComboItemIngredient(mainProduct,product,ingredient,'combo_item_ingredient')}> Only {ingredient.name} </div> <div className="position-absolute combo-item-ingredient-price">{trashButton}</div>  </div>;
                        }
                    }else if(ingredient.ingredient_category_type_blue === "well_done" && ingredient.is_checked === true){
                        if(ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged'){
                            return <div className="ingredient position-relative" key={index}> <div className={`combo-item-ingredient ${(selectedOnReceiptMenuItemId === mainProduct.menu_item_id && selectedOnReceiptMenuItemTimeStamp === mainProduct.timestamp && selectedOnReceiptMenuItemComboItemIngredientId === ingredient._id && selectedOnReceiptMenuItemComboItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemComboItemIngredient.combo_item_ingredient_timestamp === ingredient.combo_item_ingredient_timestamp && selectedOnReceiptItemType === 'combo_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickComboItemIngredient(mainProduct,product,ingredient,'combo_item_ingredient')}> Well Done {ingredient.name} </div> <div className="position-absolute combo-item-ingredient-price">${parseFloat(ingredient.price).toFixed(2)} {trashButton}</div> </div>;
                        }else{
                            return <div className="ingredient position-relative" key={index}> <div className={`combo-item-ingredient ${(selectedOnReceiptMenuItemId === mainProduct.menu_item_id && selectedOnReceiptMenuItemTimeStamp === mainProduct.timestamp && selectedOnReceiptMenuItemComboItemIngredientId === ingredient._id && selectedOnReceiptMenuItemComboItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemComboItemIngredient.combo_item_ingredient_timestamp === ingredient.combo_item_ingredient_timestamp && selectedOnReceiptItemType === 'combo_item_ingredient') ? "selected" : ""} `} onClick = {() => handleOnClickComboItemIngredient(mainProduct,product,ingredient,'combo_item_ingredient')}> Well Done {ingredient.name} </div> <div className="position-absolute combo-item-ingredient-price">{trashButton}</div>  </div>;
                        }
                    } else if (ingredient.ingredient_category_type_blue === "put_on_side" && ingredient.is_checked === true) {
                        if (ingredient.ingredientType === "both" || ingredient.ingredientType === 'extras_charged') {
                            return <div className="ingredient position-relative" key={index}> <div className={`combo-item-ingredient ${(selectedOnReceiptMenuItemId === mainProduct.menu_item_id && selectedOnReceiptMenuItemTimeStamp === mainProduct.timestamp && selectedOnReceiptMenuItemComboItemId === product.selectedComboItemId && selectedOnReceiptMenuItemComboItemIngredientId === ingredient._id && selectedOnReceiptMenuItemComboItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemComboItemIngredient.combo_item_ingredient_timestamp === ingredient.combo_item_ingredient_timestamp && selectedOnReceiptItemType === 'combo_item_ingredient') ? "selected" : ""} `} onClick={() => handleOnClickSelectReceiptComboItemIngredient(mainProduct, product, ingredient)}> Put {ingredient.name} on side </div> <div className="position-absolute combo-item-ingredient-price">${parseFloat(ingredient.price).toFixed(2)} {trashButton}</div> </div>;
                        } else {
                            return <div className="ingredient position-relative" key={index}> <div className={`combo-item-ingredient ${(selectedOnReceiptMenuItemId === mainProduct.menu_item_id && selectedOnReceiptMenuItemTimeStamp === mainProduct.timestamp && selectedOnReceiptMenuItemComboItemId === product.selectedComboItemId && selectedOnReceiptMenuItemComboItemIngredientId === ingredient._id && selectedOnReceiptMenuItemComboItemIngredient.ingredient_category_type_blue === ingredient.ingredient_category_type_blue && selectedOnReceiptMenuItemComboItemIngredient.combo_item_ingredient_timestamp === ingredient.combo_item_ingredient_timestamp && selectedOnReceiptItemType === 'combo_item_ingredient') ? "selected" : ""} `} onClick={() => handleOnClickSelectReceiptComboItemIngredient(mainProduct, product, ingredient)}> Put {ingredient.name} on side </div> <div className="position-absolute combo-item-ingredient-price">{trashButton}</div>  </div>;
                        }
                    }else if(ingredient.is_note){
                        if(ingredient.is_paid_notes){
                            return <div className="ingredient position-relative" key={index}> <div className={`combo-item-ingredient font-weight-bolder ${(selectedOnReceiptMenuItemId === mainProduct.menu_item_id && selectedOnReceiptMenuItemTimeStamp === mainProduct.timestamp && selectedOnReceiptMenuItemComboItemId === product.selectedComboItemId && selectedOnReceiptMenuItemComboItemIngredientId === ingredient.timestamp  && selectedOnReceiptItemType === 'combo_item_ingredient_notes') ? "selected" : ""} `} onClick = {() => handleOnClickComboItemIngredient(mainProduct,product,ingredient,'combo_item_ingredient_notes')}>{ingredient.notes} </div> <div className="position-absolute combo-item-ingredient-price">${parseFloat(ingredient.extra_charges).toFixed(2)} {trashButton}</div>  </div>;
                        }else{
                            return <div className="ingredient position-relative" key={index}> <div className={`combo-item-ingredient font-weight-bolder ${(selectedOnReceiptMenuItemId === mainProduct.menu_item_id && selectedOnReceiptMenuItemTimeStamp === mainProduct.timestamp && selectedOnReceiptMenuItemComboItemId === product.selectedComboItemId && selectedOnReceiptMenuItemComboItemIngredientId === ingredient.timestamp  && selectedOnReceiptItemType === 'combo_item_ingredient_notes') ? "selected" : ""}  ${(notesCommon.includes(ingredient.notes.toLowerCase())) ? "no-bold-notes" : ""}`} onClick = {() => handleOnClickComboItemIngredient(mainProduct,product,ingredient,'combo_item_ingredient_notes')}>{ingredient.notes} </div> <div className="position-absolute combo-item-ingredient-price">{trashButton}</div> </div>;
                        }
                    }
                }
                
                // return <span key={index}> {ingredient.name} , </span>;
            // }
            
        })
        return ret;
        
    } else {
        // return '/images/image_not_availble.png'
        return null;
    }
}

export default RenderComboItemIngredients;