import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import KitchenHead from './components/KitchenHead';
import LoadMorePosKitchenOrderItems from './components/LoadMorePosKitchenOrderItems';
import { fetchKitchenOrders,fetchAllKitchenOrders,clearKitchenOrderHistory,changeKitchenOrderStatus,addNewPosOrderToKitchenDisplay,updtaePosOrderToKitchenDisplay,deleteKitchenOrder  } from '../../../actions/order_actions';
import { fetchPosComboItemColor  } from '../../../actions/settings_actions';
import LoadingSpinner from '../../utils/LoadingSpinner';
import LoadingCircle from '../../common/components/LoadingCircle';
import { socket } from '../../layout/Header';
class Kitchen extends Component {
    _isMounted = false;
    state = {
        limit:8,
        skip:0,
        isloadingPosKitchenOrders: false,
        isShowLoader:false,
        showKitchenOrderMenuOptionsModal:false,
        comboItemColor:'#42e137', 
        selectedOrder:{},
        time: Date.now(),
    }

    componentWillUnmount(){
        this._isMounted = false;
        socket.off("kitchen-channel:orderPlacedFromPos");
        socket.off("kitchen-channel:orderMarkDoneFromPos");
        socket.off("kitchen-channel:orderMarkClearFromPos");
        socket.off("kitchen-channel:orderUpdatedFromPos");
        socket.off("kitchen-channel:orderDeleteFromPos");
        console.log('I am in componentWillUnmount')
        this.props.clearKitchenOrderHistory();
        clearInterval(this.interval);
    }

    componentDidMount() {
        this._isMounted = true;
        console.log('i am in componentDidMount')
        if (this._isMounted) {
            this.setState({isloadingPosKitchenOrders:true});
        }
        this.props.fetchPosComboItemColor().then(response => {
            // console.log('this.props.objPosComboItemColor in resp');
            // console.log(this.props.objPosComboItemColor);
            if (this._isMounted) {
                this.setState({comboItemColor:this.props.objPosComboItemColor.pos_combo_item_color});
            }
            // console.log(this.props.objtax);
        });
        this.props.fetchKitchenOrders(
            'pos',
            this.state.skip,
            this.state.limit,
            this.props.order.posKitchenOrders
        ).then(response => {
            console.log('this.props.order');
            console.log(this.props.order);
            if (this._isMounted) {
                this.setState({isloadingPosKitchenOrders:false});
            }
        });
        this.props.fetchAllKitchenOrders(
            'pos',
            this.state.skip,
            this.state.limit,
            this.props.order.allPosKitchenOrders
        ).then(response => {
        });
        
        /* socket.on('connect', () => {
            console.log(socket.connected); 
        }); */
        socket.on("kitchen-channel:orderPlacedFromPos",  (order) => {
            console.log('order in orderPlacedFromPos');
            console.log(order);
            // alert('order in orderPlacedFromPos')
            
            this.props.addNewPosOrderToKitchenDisplay(
                order,
            ).then(response => {
                console.log('this.props.order');
                console.log(this.props.order);
            });
        });
        socket.on("kitchen-channel:orderMarkDoneFromPos",  (order_id) => {
            console.log('order_id in orderMarkDoneFromPos');
            console.log(order_id);
            // alert('order_id in orderMarkDoneFromPos')
            
            this.props.changeKitchenOrderStatus(
                'done',
                order_id,
            ).then(response => {
                // console.log('this.props.order');
                // console.log(this.props.order);
            });
        });
        socket.on("kitchen-channel:orderMarkClearFromPos",  (order_id) => {
            console.log('order_id in orderMarkClearFromPos');
            console.log(order_id);
            // alert('order_id in orderMarkClearFromPos')
            
            this.props.changeKitchenOrderStatus(
                'clear',
                order_id,
            ).then(response => {
                console.log('this.props.order');
                console.log(this.props.order);
            });
        });
        socket.on("kitchen-channel:orderUpdatedFromPos",  (order) => {
            console.log('order in orderUpdatedFromPos');
            console.log(order);
            // alert('order in orderMarkClearFromPos')
            
            this.props.updtaePosOrderToKitchenDisplay(
                order,
            ).then(response => {
                // console.log('this.props.order');
                // console.log(this.props.order);
            });
        });

        socket.on("kitchen-channel:orderDeleteFromPos",  (order_id) => {
            console.log('order_id in orderDeleteFromPos');
            console.log(order_id);
            // alert('order_id in orderDeleteFromPos')
            
            this.props.deleteKitchenOrder(
                order_id,
            ).then(response => {
                console.log('this.props.order');
                console.log(this.props.order);
            });
        });
        
        /* socket.on("hello_kicten",  (order) => {
            // console.log('order in hello_kicten');
            // console.log(order);
            // alert('order in hello_kicten')
            
        }); */
        // Updating the ui after each 5 seconds
        this.interval = setInterval(() => this.setState({ time: Date.now() }), 5000);
        window.kitchen = this;
    }
    onClickHandleLoadMorePosKitchenOrderItems = () => {
        // console.log('onClickHandleLoadMorePosKitchenOrderItems')
        this.setState({isloadingPosKitchenOrders:true});
        let skip = this.state.skip + this.state.limit;
        this.props.fetchKitchenOrders(
                'pos',
                skip,
                this.state.limit,
                this.props.order.posKitchenOrders
            ).then(response => {
            // console.log('this.props.order');
            // console.log(this.props.order);
            this.setState({skip,isloadingPosKitchenOrders:false});
        });
    }
    onClickHandleKitchenOrder  = (order) => {
        // console.log('onClickHandleKitchenOrder')
        // console.log(order)
        this.setState({selectedOrder:order,showKitchenOrderMenuOptionsModal:true})
    }
    handleOnChangeKitchenOrderMenuOptionsModalToggle = (showKitchenOrderMenuOptionsModal) =>{
        this.setState({showKitchenOrderMenuOptionsModal:showKitchenOrderMenuOptionsModal});
    }
    handleOnClickClearOrder = () =>{
        // console.log('handleOnClickClearOrder')
        // console.log(this.state.selectedOrder);
        this.setState({isShowLoader:true});
        this.props.changeKitchenOrderStatus(
            'clear',
            this.state.selectedOrder._id,
        ).then(response => {
            // console.log('this.props.order');
            console.log(this.props.order);
            socket.emit("KitchenDisplayOrderMarkClear", this.state.selectedOrder._id);
            this.setState({isShowLoader:false,showKitchenOrderMenuOptionsModal:false});
        });
    }
    handleOnClickDoneOrder = () =>{
        // console.log('handleOnClickDoneOrder')
        // console.log('handleOnClickDoneOrder')
        // console.log('handleOnClickDoneOrder')
        // console.log(this.state.selectedOrder);
        this.setState({isShowLoader:true});
        this.props.changeKitchenOrderStatus(
            'done',
            this.state.selectedOrder._id,
        ).then(response => {
            socket.emit("KitchenDisplayOrderMarkDone", this.state.selectedOrder._id);
            // console.log('this.props.order');
            // console.log(this.props.order);
            this.setState({isShowLoader:false,showKitchenOrderMenuOptionsModal:false});
        });
    }
    
    render() {
        const order = this.props.order
        if (Object.keys(order).length === 0 || order.posKitchenOrders === undefined) {
            return (
                <LoadingSpinner subClass={'mt10 mb10 text-center'} />
            )
        }
        /* console.log('order.posKitchenOrders')
        console.log(order.posKitchenOrders)
        console.log('order.allPosKitchenOrders')
        console.log(order.allPosKitchenOrders) */
        // console.log(order.posKitchenOrders.sort((a, b) => a.order_completion_datetime.localeCompare(b.order_completion_datetime)))
        return (
            <div className="bg-dark container">
                <LoadingCircle 
                    isShowLoader={this.state.isShowLoader}
                />
                <KitchenHead />
                <section className="container-fluid p-0 kitchen-item-box">
                    <div id="owl-kitchen" className="owl-theme">
                        <div className="container-fluid">
                            {
                                order.posKitchenOrders !== 'undefined' && order.posKitchenOrders !== undefined ?
                                    <LoadMorePosKitchenOrderItems
                                        limit={this.state.limit}
                                        isloadingPosKitchenOrders={this.state.isloadingPosKitchenOrders}
                                        total_pos_kitchen_orders={order.total_pos_kitchen_orders}
                                        Orders={order.posKitchenOrders}
                                        onClickHandleLoadMorePosKitchenOrderItems={this.onClickHandleLoadMorePosKitchenOrderItems}
                                        onClickHandleKitchenOrder={this.onClickHandleKitchenOrder}
                                        handleOnChangeKitchenOrderMenuOptionsModalToggle={this.handleOnChangeKitchenOrderMenuOptionsModalToggle}
                                        comboItemColor={this.state.comboItemColor}
                                        showKitchenOrderMenuOptionsModal={this.state.showKitchenOrderMenuOptionsModal}
                                        handleOnClickClearOrder={this.handleOnClickClearOrder}
                                        handleOnClickDoneOrder={this.handleOnClickDoneOrder}
                                    />
                                :null
                            }
                            
                        </div>
                    </div>
                </section>
            </div>
            
        
        )
    }
}

function mapStateToProps(state) {
    return {
        order : state.order,
        objPosComboItemColor : state.settings.objPosComboItemColor
    };
}

// export default Home
export default connect(mapStateToProps, {fetchKitchenOrders,fetchAllKitchenOrders,clearKitchenOrderHistory,fetchPosComboItemColor,changeKitchenOrderStatus,addNewPosOrderToKitchenDisplay,updtaePosOrderToKitchenDisplay,deleteKitchenOrder})(withRouter(Kitchen));
