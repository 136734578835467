import React,{Fragment} from 'react';
import PosKitchenCarouselRenderComboItemIngredients from './PosKitchenCarouselRenderComboItemIngredients';
const PoskitchenCarouselCartItemSubBlock = ({
        mainProduct,
        products,
        comboItemColor,
    }) => {
    const comboItemStyle = {
        color: `${comboItemColor}`,
    };
    /* console.log('comboItemStyle')
    console.log('comboItemStyle')
    console.log('comboItemStyle')
    console.log(comboItemStyle) */
    const renderItems = () => (
        products ?
            products.map((product,index)=>{
                if(product.is_note){
                    return <ul className="food-info-sublist" key={index}>
                        <li>
                            <h6 className="position-relative" style={comboItemStyle}>
                                <span className = {`menu-item-combo-item-name `}   > With {product.notes } </span>
                            </h6>
                        </li>
                    </ul>
                }else{
                    return <ul className="food-info-sublist" key={index}>
                        <li>
                            <h6 className="position-relative sss" style={comboItemStyle}>
                                <span className = {`menu-item-combo-item-name `} > With {product.name } </span>
                                {/* <span className="position-absolute">
                                    ${(parseFloat(product.combo_item_price)).toFixed(2) }
                                    
                                </span> */}
                            </h6>
                            {
                                product.ingredients.length > 0 ? PosKitchenCarouselRenderComboItemIngredients(mainProduct,product,product.ingredients) : null
                                // product.ingredients.length > 0 ? PosKitchenCarouselRenderComboItemIngredients(mainProduct,product,product.ingredients,removeIngredientFromCartComboItem) : null
                            }
                            
                        </li>
                    </ul>
                }
            })

        :null
    )


    return (
        <Fragment>
            {renderItems()}
        </Fragment>
    );
};

export default PoskitchenCarouselCartItemSubBlock;